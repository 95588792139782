<template>
    <div class="main" @click="toggle">
        <svg v-if="isLocked" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28' width='28' height='28'><path fill='currentColor' fill-rule='evenodd' d='M14 6a3 3 0 0 0-3 3v3h6V9a3 3 0 0 0-3-3zm4 6V9a4 4 0 0 0-8 0v3H8.5A2.5 2.5 0 0 0 6 14.5v7A2.5 2.5 0 0 0 8.5 24h11a2.5 2.5 0 0 0 2.5-2.5v-7a2.5 2.5 0 0 0-2.5-2.5H18zm-5 5a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2zm-6-2.5c0-.83.67-1.5 1.5-1.5h11c.83 0 1.5.67 1.5 1.5v7c0 .83-.67 1.5-1.5 1.5h-11A1.5 1.5 0 0 1 7 21.5v-7z'></path></svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M14 6a3 3 0 0 0-3 3v3h8.5a2.5 2.5 0 0 1 2.5 2.5v7a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 6 21.5v-7A2.5 2.5 0 0 1 8.5 12H10V9a4 4 0 0 1 8 0h-1a3 3 0 0 0-3-3zm-1 11a1 1 0 1 1 2 0v2a1 1 0 1 1-2 0v-2zm-6-2.5c0-.83.67-1.5 1.5-1.5h11c.83 0 1.5.67 1.5 1.5v7c0 .83-.67 1.5-1.5 1.5h-11A1.5 1.5 0 0 1 7 21.5v-7z"></path></svg>
    </div>
</template>

<script>
import Overlay from '../../mixins/overlay.js'
import Icons from "../../stuff/icons.json";

export default {
    name: 'Lock',
    data() {
        return {
            isLocked: false,
        };
    },
    mounted() {
        console.log("mounted locked");
        const savedState = localStorage.getItem('isLocked');
        this.isLocked = savedState === 'true';
    },
    methods: {
        toggle() {
            this.isLocked = !this.isLocked;
            console.log("tool clicked : is locked : ", this.isLocked);
            localStorage.setItem('isLocked', this.isLocked);
            this.$store.commit("updateIsLocked", this.isLocked);
        }
    }
}
</script>

<style scoped>
.main {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main:hover {
    background-color: #76878330;
}
</style>
