var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getIsInfoOpen
    ? _c(
        "div",
        { staticClass: "infoBar", style: { width: _vm.width - 56 + "px" } },
        [
          _c("div", { staticClass: "watchlist" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "span",
                {
                  staticClass: "title",
                  on: { click: _vm.handleWatchlistPopup },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.selectedWatchlist
                        ? _vm.selectedWatchlist.name
                        : "Watchlist"
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "header-icons" }, [
                _c(
                  "button",
                  {
                    staticClass: "icon",
                    class: _vm.headerBtnClass,
                    on: { click: _vm.AddSymbolsInWatchlist },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "icon", class: _vm.headerBtnClass },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", { attrs: { d: "M0 0h28v28H0z" } }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            stroke: "currentColor",
                            d: "M13.5 23.5a9 9 0 0 0 9-9h-9v-9a9 9 0 0 0 0 18zM22 6l-6.5 6.5m4-8l-4 4m8 0l-4 4",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            stroke: "currentColor",
                            d: "M24.5 12.5a9 9 0 0 0-9-9v9h9z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "icon", class: _vm.headerBtnClass },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm.watchlist.length <= 0
              ? _c("div", { staticClass: "empty" }, [
                  _c(
                    "button",
                    {
                      staticClass: "BtnCSS",
                      attrs: { id: "watchlist" },
                      on: { click: _vm.handleWatchlistPopup },
                    },
                    [
                      _c("p", [_vm._v("Add Watchlist")]),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 14 12",
                            width: "14",
                            height: "12",
                            fill: "currentColor",
                            focusable: "false",
                            preserveAspectRatio: "none",
                          },
                        },
                        [_c("path", { attrs: { d: "M14 12l-4-6 4-6H0v12z" } })]
                      ),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "watchlist-table" }, [
                  _c("table", [
                    _c(
                      "tbody",
                      _vm._l(_vm.watchlist.symbols, function (item, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.selectSymbol(item)
                              },
                            },
                          },
                          [
                            _c("td", { staticClass: "tdSym" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSymbol(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        fill: _vm.selectedWatchlist.color,
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 14 12",
                                        width: "14",
                                        height: "12",
                                        focusable: "false",
                                        preserveAspectRatio: "none",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: { d: "M14 12l-4-6 4-6H0v12z" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.bottomBarClass,
              style: { height: _vm.bottomBarHeight + "px" },
            },
            [
              _c("div", {
                staticClass: "resizeHandleBottom",
                on: { mousedown: _vm.onMouseDownBottomBar },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "bottomBarHeader" }, [
                _c("div", { staticClass: "bottomBarTitle" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.selectedSymbol) + " \n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "bottomBarHeaderRight" }, [
                  _c("button", { class: _vm.headerBtnClass }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            "fill-rule": "evenodd",
                            d: "M7 6h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1ZM5 7c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7Zm12-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm-2 1c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V7Zm-4 9H7a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-4-1a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H7Zm10 1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Zm-2 1c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4Z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("button", { class: _vm.headerBtnClass }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M15 6H7.5C6.67 6 6 6.67 6 7.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16h1v4.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 5 20.5v-13A2.5 2.5 0 0 1 7.5 5H15v1Z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M22.41 5.7a2 2 0 0 0-2.82 0L11 14.3V18h3.7l8.6-8.59a2 2 0 0 0 0-2.82l-.89-.88Zm-2.12.71a1 1 0 0 1 1.42 0l.88.88a1 1 0 0 1 0 1.42l-.59.58L19.7 7l.6-.59Zm1 3.59-7 7H12v-2.3l7-7 2.3 2.3Z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("button", { class: _vm.headerBtnClass }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 28 28",
                          width: "28",
                          height: "28",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.openWatchlistPopup,
                  expression: "openWatchlistPopup",
                },
              ],
              staticClass: "page",
            },
            [_c("div", { staticClass: "WatchlistPopup" })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("thead", [
        _c("tr", { staticClass: "tableHeader" }, [
          _c("th", [_vm._v("Symbol")]),
          _vm._v(" "),
          _c("th", [_vm._v("Last")]),
          _vm._v(" "),
          _c("th", [_vm._v("Chg")]),
          _vm._v(" "),
          _c("th", [_vm._v("Chg%")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }