<template>
  <div :class="pageClass">
    <div class="header">
      <div @click="closePage" class="cross">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <path stroke="currentColor" stroke-width="1.2" d="m1.5 1.5 15 15m0-15-15 15"></path>
        </svg>
        <p>Settings</p>
      </div>
    </div>
    <div class="home">
      <div class="sidebar">
        <h3 class="account">Account</h3>
        <div class="itemsHolder">
          <p :class="getClass(index)" v-for="(page, index) in pages" @click="() => handleSelect(index)">
            {{ page.name }}
          </p>
          <p :class="itemsClass" @click="logoutHandler">Logout</p>
        </div>
      </div>

      <div class="main">
        <!-- when Personal information page is selected -->
        <form v-if="selected === 0" @submit.prevent="handleSummit">
          <h2 :class="h2Class">{{ pages[selected].name }}</h2>

          <!-- profile pic -->
          <div class="profile_pic">
            <h4 :class="h4Class">Profile picture</h4>
            <div class="pic">
              <img v-if="profilePicPreview" :src="profilePicPreview" alt="Profile Picture">
              <img v-else-if="doesUserHaveImage" :src="user.profile_pic" alt="Profile Picture">
              <div v-else class="defaultPic">
                {{ firstName[0]?.toUpperCase() }}{{ lastName[0]?.toUpperCase() }}
              </div>
              <div>
                <label for="profile_pic" class="upload-label">Upload</label>
                <input type="file" id="profile_pic" accept="image/*" class="hidden" @change="handleProfilePicUpload">
                <p class="AcceptedType">JPG, GIF or PNG. Minimum 300x300 pixels. Maximum file size 2 MB.
                </p>
              </div>
            </div>
          </div>

          <!-- name -->
          <div class="nameparent">
            <h4 :class="h4Class">Full name</h4>
            <div class="nameSection">
              <div class="name">
                <p class="nameText">First Name</p>
                <input type="text" :class="nameInputClass" placeholder="John" v-model="firstName">
              </div>
              <div class="name">
                <p class="nameText">Last Name</p>
                <input type="text" :class="nameInputClass" placeholder="Doe" v-model="lastName">
              </div>
            </div>
          </div>

          <!-- email -->
          <div class="emailparent">
            <h4 :class="h4Class">Email address</h4>
            <div class="userInfo">
              <p class="username">Primary email</p>
              <input type="text" readonly disabled :class="emailInputClass" placeholder="Johndoe@gmail.com"
                v-model="email">
            </div>
          </div>

          <!-- password (create) -->
          <div v-if="!doesUserHavePassword" class="passwordParent">
            <div class="password">
              <p class="nameText">Create Password</p>
              <div class="passwordField">
                <input :type="showPassword ? 'text' : 'password'" :class="passwordInputClass"
                  placeholder="Enter Password" v-model="password1" @input="handlePassword1Change">
                <button class="showPasswordBtn" @click="toggleShowPassword">
                  <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path fill="currentColor"
                      d="M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z">
                    </path>
                    <path fill="currentColor"
                      d="M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z">
                    </path>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path fill="currentColor"
                      d="M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z">
                    </path>
                    <path fill="currentColor" fill-rule="evenodd"
                      d="M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z">
                    </path>
                  </svg>
                </button>
              </div>
            </div>

            <div class="password">
              <p class="nameText">Reconfirm Password</p>
              <div class="passwordField">
                <input :type="showPassword2 ? 'text' : 'password'" :class="passwordInputClass"
                  placeholder="Re-enter Password" v-model="password2" @input="handlePassword2Change">
                <button class="showPasswordBtn" @click="toggleShowPassword2">
                  <svg v-if="showPassword2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path fill="currentColor"
                      d="M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z">
                    </path>
                    <path fill="currentColor"
                      d="M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z">
                    </path>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                    <path fill="currentColor"
                      d="M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z">
                    </path>
                    <path fill="currentColor" fill-rule="evenodd"
                      d="M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z">
                    </path>
                  </svg>
                </button>
              </div>

            </div>
          </div>
          <!-- password (change) -->
          <div v-else :class="ChagnePasswordClass" @click="openChangePasswordPage">
            Change Password
          </div>

          <!-- phone number -->
          <div class="phoneParent">
            <div id="recaptcha-container"></div>
            <h4 :class="h4Class">Mobile number</h4>
            <div class="phoneNumber">
              <select name="country" id="country-select">
                <!-- Add options here -->
              </select>
              <input type="text" :class="phoneInputClass" v-model="phone" @input="updatePhone">
              <div :class="confirmClass" @click="openOtpPage">Confirm</div>
            </div>
            <div id="recaptcha"></div>
          </div>

          <button type="submit" :class="saveClass">Save</button>
        </form>

        <!-- when Account and Billing page is selected -->
        <form v-else>
          <h1 class="commingSoon">
            Feature coming soon
          </h1>
        </form>
      </div>
    </div>

    <!-- opt page -->
    <div v-show="otpPage" :class="otppageClass">
      <span class="crossOptPage" @click="handleOptPageCross">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
          <path stroke="currentColor" stroke-width="1.2" d="m1.5 1.5 15 15m0-15-15 15"></path>
        </svg>
      </span>
      <span class="optPageTitle">Confirm Number</span>

      <div>
        <p :class="infoClass">We have sent four digit code on <br> {{ phone }}</p>
        <div class="opt">
          <input type="text" v-for="(value, index) in otpArray" :key="index" v-model="otpArray[index]"
            @input="onInput(index)" @keydown="onKeydown($event, index)" @paste="onPaste($event)" maxlength="1"
            class="otp-input" :ref="`otp${index}`" />
        </div>
        <div :class="saveClass" @click="">Submit</div>
      </div>
    </div>

    <!-- change password page -->
    <div v-show="changePasswordPage" :class="chagnePasswordPageClass">
      <span class="crossOptPage" @click="handlechagnePasswordPageCross">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
          <path stroke="currentColor" stroke-width="1.2" d="m1.5 1.5 15 15m0-15-15 15"></path>
        </svg>
      </span>

      <span class="optPageTitle">Change password</span>

      <div>
        <p :class="infoClass">Enter your current password and new password to change your password </p>

        <div class="old">
          <p class="nameText">Old Password</p>
          <div class="ChangepasswordField">
            <input :type="showPassword3 ? 'text' : 'password'" v-model="oldPassword" :class="passwordChangeClass"
              placeholder="Enter your current password" @input="handleOldPasswordChange" />
            <button class="showPasswordBtn" @click="toggleShowPassword3">
              <svg v-if="showPassword3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path fill="currentColor"
                  d="M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z">
                </path>
                <path fill="currentColor"
                  d="M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z">
                </path>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path fill="currentColor"
                  d="M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z">
                </path>
                <path fill="currentColor" fill-rule="evenodd"
                  d="M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z">
                </path>
              </svg>
            </button>
          </div>
        </div>

        <div class="new">
          <p class="nameText">New Password</p>
          <div class="ChangepasswordField">
            <input :type="showPassword4 ? 'text' : 'password'" v-model="newPassword" :class="passwordChangeClass"
              placeholder="Enter your new password" @input="handleNewPasswordChange" />
            <button class="showPasswordBtn" @click="toggleShowPassword4">
              <svg v-if="showPassword4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path fill="currentColor"
                  d="M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z">
                </path>
                <path fill="currentColor"
                  d="M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z">
                </path>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path fill="currentColor"
                  d="M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z">
                </path>
                <path fill="currentColor" fill-rule="evenodd"
                  d="M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z">
                </path>
              </svg>
            </button>
          </div>
        </div>

        <div :class="ChagnePasswordClass" @click="handleChangePassword">
          Change Password
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { SERVER_URL } from '../constant.js'

export default {
  name: "AccountSettingsPage",
  data() {
    return {
      pages: [
        { name: "Personal information" },
        { name: "Account and Billing" }
      ],

      selected: parseInt(localStorage.getItem('selected')) || 0,
      profilePicPreview: null,

      profile_pic: null,
      firstName: "",
      lastName: "",
      email: "",
      password1: "",
      password2: "",
      oldPassword: "",
      newPassword: "",
      phone: "",

      user: null,

      doesUserHaveImage: false,
      doesUserHavePassword: false,

      otpPage: false,
      otpArray: Array(4).fill(''),

      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
      changePasswordPage: false,
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      showPassword4: false
    };
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("isAccountSettingsPageOpen"))) {
      this.$store.commit('updateIsOtherInputSearchOpen', true)
    }
    if (JSON.parse(localStorage.getItem('isLogin'))) {
      if (localStorage.getItem('email')) {
        const email = localStorage.getItem('email')
        // console.log("email : ", email )
        const response = await axios.post(`${SERVER_URL}/api/getUser`, { email })
        // console.log(response.data.user)
        this.user = response.data.user
        const name = response.data.user.fullName.split(' ')
        this.firstName = name[0]
        this.lastName = name[1]
        this.email = response.data.user.email
        if (this.user.profile_pic) {
          this.doesUserHaveImage = true
        } else {
          this.doesUserHaveImage = false
        }
        if (this.user.password) {
          this.doesUserHavePassword = true
        } else {
          this.doesUserHavePassword = false
        }
      } else {
        console.log("users email is not defined please login again")
      }
    } else {
      console.log("users is not login please login ")
    }
  },
  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    pageClass() {
      return this.isDark ? "page-dark" : "page";
    },
    nameInputClass() {
      return this.isDark ? "nameInput-dark" : "nameInput"
    },
    h2Class() {
      return this.isDark ? "h2-dark" : "h2"
    },
    h4Class() {
      return this.isDark ? "h4-dark" : "h4"
    },
    emailInputClass() {
      return this.isDark ? "emailInput-dark" : "emailInput"
    },
    passwordInputClass() {
      return this.isDark ? "passwordInput-dark" : "passwordInput"
    },
    ChagnePasswordClass() {
      return this.isDark ? "ChagnePassword-dark" : "ChagnePassword"
    },
    phoneInputClass() {
      return this.isDark ? "phoneInput-dark" : "phoneInput"
    },
    confirmClass() {
      return this.isDark ? "confirm-dark" : "confirm"
    },
    otppageClass() {
      return this.isDark ? "otppage-dark" : "otppage"
    },
    saveClass() {
      return this.isDark ? "save-dark" : "save"
    },
    itemsClass() {
      return this.isDark ? "items-dark" : "items"
    },
    infoClass() {
      return this.isDark ? "info-dark" : "info"
    },
    chagnePasswordPageClass() {
      return this.isDark ? "chagnePasswordPage-dark" : "chagnePasswordPage"
    },
    passwordChangeClass() {
      return this.isDark ? "passwordChange-dark" : "passwordChange"
    }
  },
  methods: {
    getClass(index) {
      return {
        'selectedItem-dark': this.isDark && this.selected === index,
        'selectedItem': !this.isDark && this.selected === index,
        'items': !this.isDark,
        'items-dark': this.isDark
      };
    },
    closePage() {
      this.$store.commit('updateIsAccountSettingsOpen', false);
      this.$store.commit('updateIsOtherInputSearchOpen', false);
    },
    handleOptPageCross() {
      this.otpPage = false;
    },
    handlechagnePasswordPageCross() {
      this.changePasswordPage = false;
    },
    handleSelect(index) {
      localStorage.setItem('selected', index);
      this.selected = index;
      // console.log(this.selected)
    },
    updatePhone(e) {
      const input = e.target.value;
      // Allow only numeric characters
      this.phone = input.replace(/\D/g, '');
    },
    async handleSummit(e) {
      e.preventDefault();

      // Check if passwords match
      if (!this.doesUserHavePassword && (this.password1 !== this.password2)) {
        this.$toast('Passwords do not match', {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        });
        return;
      }

      // Update profile picture if he change input
      if (this.profile_pic) {
        const formData = new FormData();
        formData.append('profile_pic', this.profile_pic);
        formData.append('email', this.email);

        try {
          await axios.post(`${SERVER_URL}/api/updateUserProfile`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        } catch (error) {
          console.error('Error updating profile picture:', error);
          this.$toast('Failed to update profile picture', {
              
            toastClassName: "my-custom-toast-class",

            // For the toast body when using strings or a custom component as content
            bodyClassName: ["custom-class-1", "custom-class-2"]
          });
        }
      }

      // create password if user donot have password
      if (!this.doesUserHavePassword) {
        if ((this.password1.trim().length > 0 && this.password1.trim().length < 8) || (this.password2.trim().length > 0 && this.password2.trim().length < 8)) {
          this.$toast('Password must be at least 8 characters long');
          return;
        }
        if (this.password1.trim().length > 8 && this.password2.trim().length > 8) {

          try {
            const data = {
              email: this.email,
              password1: this.password1,
              password2: this.password2,
            };
            await axios.post(`${SERVER_URL}/api/CreateUserPassword`, data, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
            // console.log("Password updated successfully");
          } catch (error) {
            console.error('Error updating password:', error);
            this.$toast('Failed to update password', {
                
              toastClassName: "my-custom-toast-class",
  
              bodyClassName: ["custom-class-1", "custom-class-2"]
            });
          }
        }
      }

      // Update user name
      await axios.post(`${SERVER_URL}/api/updateUserInfo`, {
        email: this.email,
        fullName: this.firstName + " " + this.lastName,
      })

      // TODO: Update user phone


      this.$toast('Information updated successfully', {
          
        toastClassName: "my-custom-toast-class",

        // For the toast body when using strings or a custom component as content
        bodyClassName: ["custom-class-1", "custom-class-2"]
      })
      this.$store.commit('updateIsOtherInputSearchOpen', false)
      this.$store.commit('updateIsAccountSettingsOpen', false)
    },
    async handleChangePassword() {
      // TODO: Update user password
      // console.log(this.oldPassword, this.newPassword)
      if (this.oldPassword.trim().length < 8 || this.newPassword.trim().length < 8) {
        this.$toast('password must be 8 character long', {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        })
        return
      }
      if (this.oldPassword.trim() == this.newPassword.trim()) {
        this.$toast('Old password and new password cannot be same', {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        })
        return
      }

      try {
        const data = {
          email: this.email,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        const res = await axios.post(`${SERVER_URL}/api/updateUserPassword`, data)
        // console.log(res.data)
        this.$toast(res.data.message, {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        })
      } catch (error) {
        this.$toast(error.response.data.message, {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        })
      }
    },
    handleProfilePicUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.profile_pic = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.profilePicPreview = e.target.result; // Set image preview
        };
        reader.readAsDataURL(file);
      }
    },
    handlePassword1Change(event) {
      this.password1 = event.target.value;
    },
    handlePassword2Change(event) {
      this.password2 = event.target.value;
    },
    handleOldPasswordChange(event) {
      this.oldPassword = event.target.value;
    },
    handleNewPasswordChange(event) {
      this.newPassword = event.target.value;
    },
    async openOtpPage() {
      this.otpPage = true
    },
    onInput(index) {
      if (this.otpArray[index].length === 1 && index < 5) {
        this.$refs[`otp${index + 1}`][0].focus(); // Move focus to the next input
      }
    },
    onKeydown(event, index) {
      if (event.key === 'Backspace' && this.otpArray[index] === '' && index > 0) {
        this.$refs[`otp${index - 1}`][0].focus(); // Move focus to the previous input
      }
    },
    onPaste(event) {
      const pasteData = event.clipboardData.getData('text');
      if (pasteData.length != 6) {
        this.$toast('Please paste a 6 digit code', {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        });
      }
      if (pasteData.length === 6 && /^[0-9]+$/.test(pasteData)) {
        this.otpArray = pasteData.split('');
        this.$nextTick(() => {
          this.$refs.otp5[0].focus(); // Move focus to the last input
        });
      }
      event.preventDefault(); // Prevent the default paste action
    },
    async logoutHandler() {
      localStorage.setItem('isLogin', false)
      localStorage.setItem('email', "")
      localStorage.setItem('isAccountSettingsPageOpen', false)
      await axios.get(`${SERVER_URL}/api/logout`)
        .then(() => {
          this.$router.push('/')
          this.$toast('logout successfully', {
              
            toastClassName: "my-custom-toast-class",

            // For the toast body when using strings or a custom component as content
            bodyClassName: ["custom-class-1", "custom-class-2"]
          })
          localStorage.setItem('showRightBar', false)
          this.$store.commit('toggleShowBar', false)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
    },
    openChangePasswordPage() {
      this.changePasswordPage = true
    },
    toggleShowPassword(e) {
      e.preventDefault()
      this.showPassword = !this.showPassword;
    },
    toggleShowPassword2(e) {
      e.preventDefault()
      this.showPassword2 = !this.showPassword2;
    },
    toggleShowPassword3(e) {
      e.preventDefault()
      this.showPassword3 = !this.showPassword3;
    },
    toggleShowPassword4(e) {
      e.preventDefault()
      this.showPassword4 = !this.showPassword4;
    }
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.page {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1005;
  background-color: #fff;
}

.page-dark {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1005;
  background-color: #181a1b;
}

.header {
  width: 100%;
  height: 8%;
  border-bottom: 1px solid #F2F2F2;
}

.home {
  width: 100%;
  height: 92%;
  display: flex;
}

.sidebar {
  padding-top: 50px;
  padding-left: 5%;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 1px solid #F2F2F2;
}

.account {
  color: #979B99;
}

.main {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.cross {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 10%;
  font-weight: 300;
  font-size: 15px;
  color: #979B99;
}

svg {
  padding: 5px;
  cursor: pointer;
  border-radius: 8px;
  height: 25px;
  width: 25px;
}

svg:hover {
  background-color: #76878319;
}

.itemsHolder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.items {
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.items-dark {
  color: #b7b7b7;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.items:hover {
  color: #430393;
}

.selectedItem {
  color: #430393;
  border-right: 3px solid #430393;
}

.selectedItem-dark {
  color: #fff;
  border-right: 3px solid #fff;
}

form {
  margin-left: 10%;
  height: 100%;
  width: 100%;
  margin-bottom: 10%;
  color: black;
  overflow-y: scroll;
}

.profile_pic {
  margin-top: 20px;
}

.pic {
  display: flex;
  gap: 10px;
}

.pic img {
  width: 50px;
  height: 50px;
  background-color: #430393;
  border-radius: 8px;
}

.defaultPic {
  width: 50px;
  height: 50px;
  background-color: #430393;
  border-radius: 8px;
  color: #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AcceptedType {
  color: #979B99;
  margin-top: 15px;
  font-size: 12px;
}

.hidden {
  display: none;
}

.upload-label {
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #F5F5F5;
}

.nameparent {
  margin-top: 20px;
}

.nameSection {
  display: flex;
  justify-content: space-between;
  width: 60%;
  gap: 20px;
}

.name {
  margin-top: -15px;
  width: 100%;
}

.nameText {
  font-size: 12px;
  color: #979B99;
}

.nameInput {
  display: flex;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  background: transparent;
  outline: none;
}

.nameInput-dark {
  display: flex;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #333333;
  background: transparent;
  outline: none;
  color: #FFF;
}

.h4 {
  font-weight: 400;
}

.h4-dark {
  font-weight: 400;
  color: #FFF;
}

.h2 {
  font-weight: 500;
}

.h2-dark {
  font-weight: 500;
  color: #fff;
}

.username {
  font-size: 12px;
  color: #979B99;
}

.emailInput {
  display: flex;
  justify-content: center;
  width: 30%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  background: transparent;
  outline: none;
}

.emailInput-dark {
  display: flex;
  justify-content: center;
  width: 30%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #333333;
  background: transparent;
  outline: none;
  color: #FFF;
}

.emailparent {
  margin-top: 20px;
}

.passwordParent {
  margin-top: 20px;
}

.passwordInput {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: transparent;
  outline: none;
  border: none;
}

.passwordInput-dark {
  color: #FFF;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: transparent;
  outline: none;
  border: none;
}

.phoneParent {
  margin-top: 20px;
}

.phoneNumber {
  display: flex;
  align-items: center;
  gap: 20px;
}

select {
  height: 40px;
}

.phoneInput {
  display: flex;
  justify-content: center;
  width: 30%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  background: transparent;
  outline: none;
}

.phoneInput-dark {
  color: #fff;
  display: flex;
  justify-content: center;
  width: 30%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #333333;
  background: transparent;
  outline: none;
}

.confirm {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid black;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.confirm-dark {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #333333;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  color: white;
}

.save {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: black;
  color: white;
  margin: 40px 0px 100px 0px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
}

.save-dark {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #333333;
  color: white;
  margin: 40px 0px 100px 0px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}

.save-dark:hover {
  background-color: #979B99;
  color: white;
}

.save:hover {
  background-color: #fff;
  color: black;
}

.ChagnePassword {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: transparent;
  width: fit-content;
  margin: 20px 0px 0px 0px;
  border: 1px solid black;
  outline: none;
  cursor: pointer;
  font-size: 14px;
}

.ChagnePassword-dark {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: transparent;
  color: white;
  width: fit-content;
  margin: 20px 0px 0px 0px;
  border: 1px solid #333333;
  outline: none;
  cursor: pointer;
  font-size: 14px;
}

.otppage {
  width: 50%;
  height: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otppage-dark {
  width: 50%;
  height: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(256, 256, 256, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.crossOptPage {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 15px;
  color: #979B99;
  position: absolute;
  top: 20px;
  right: 20px;

}

.optPageTitle {
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  top: 20px;
  color: #333333;
  left: 20px;
}

.info {
  text-align: center;
  margin-bottom: 10px;
}

.info-dark {
  color: #ccc;
  margin-bottom: 10px;
  text-align: center;
}

.opt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input {
  margin-top: 20px;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.5rem;
  margin: 0.2rem;
}

.chagnePasswordPage {
  padding: 10px;
  width: 50%;
  height: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chagnePasswordPage-dark {
  padding: 10px;
  width: 50%;
  height: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(256, 256, 256, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.passwordChange {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  background: transparent;
  outline: none;
}

.passwordChange-dark {
  color: #FFF;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #333333;
  background: transparent;
  outline: none;
}

.passwordField {
  width: 30%;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  outline: 1px solid #DEF1EC;
  overflow: hidden;
}

.ChangepasswordField {
  width: 60%;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  outline: 1px solid #DEF1EC;
  overflow: hidden;
}

.passwordField svg {
  fill: red;
  color: gray;
  height: 40px;
  width: 40px;
}

.ChangepasswordField svg {
  fill: red;
  color: gray;
  height: 40px;
  width: 40px;
}

.showPasswordBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.commingSoon {
  color: #ccc;
}

@media (max-width: 700px) {
  .nameSection {
    width: 90%;
  }

  .emailInput {
    width: 90%;
  }

  .phoneInput {
    width: 90%;
  }

  .passwordField {
    width: 90%;
  }

  .emailInput-dark {
    width: 90%;
  }

  .passwordField-dark {
    width: 90%;
  }

  .passwordInput-dark {
    width: 90%;
  }

  .otppage {
    width: 80%;
    height: 50%;
    background-color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .otppage-dark {
    width: 80%;
    height: 50%;
    background-color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .chagnePasswordPage {
    width: 80%;
    height: 50%;
    background-color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .chagnePasswordPage-dark {
    width: 80%;
    height: 50%;
    background-color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>