<template>
  <div class="page" v-if="getIsSymbolSearchOpen">
    <div :class="mainClass">
      <!-- Close Button -->
      <button class="cross" @click="closeSymbolSearch">X</button>

      <!-- Title -->
      <h1>Symbol Search</h1>
      <hr />

      <!-- Search Bar -->
      <div class="search">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <path
            stroke="currentColor"
            d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
          ></path>
        </svg>
        <input
          ref="myInput"
          type="text"
          :class="inputClass"
          v-model="symbol"
          @input="updateSearch"
          placeholder="Search Symbol"
        />
      </div>
      <hr />
      <!-- Symbol Search Table -->
      <div class="symbolSearch">
        <!-- Filters -->
        <div class="filters">
          <button
            :class="{
              selectedOption: filter.type === option,
              filter: filter.type !== option,
            }"
            v-for="(filter, i) in filters"
            :key="i"
            @click="() => selectOption(i, filter)"
          >
            {{ filter.type }}
          </button>
        </div>

        <!-- Sub Filters -->
        <!-- <div class="subFilters">
                    <button :class="subFilterClass" v-for="(subFilter, i) in subFilters" :key="i"
                        @click="() => selectSubOption(i)">
                        {{ subFilter }}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18">
                            <path fill="currentColor"
                                d="M3.92 7.83L9 12.29l5.08-4.46-1-1.13L9 10.29l-4.09-3.6-.99 1.14Z"></path>
                        </svg>
                    </button>
                </div> -->

        <!-- Items Table -->
        <table
          class="items"
          v-if="filteredInfoComputed.length > 0 && !comingSoonToggle"
        >
          <tbody>
            <tr
              :class="rowClass"
              v-for="item in filteredInfo"
              :key="item.id ? item.id : item.code"
              @click="
                () => {
                  selectSymbol(item.tradingSymbol);
                  updateSelectedSymbol(item);
                }
              "
            >
              <td>
                <div class="tdSym">
                  <div class="colorPattel" v-show="showColor">
                    <span v-for="(color, index) in colors" :key="index">
                      <span
                        class="colorCircle"
                        :style="{ backgroundColor: color }"
                        @click.stop="
                          colorSelected(
                            color,
                            item.tradingSymbol ? item.tradingSymbol : item.code
                          )
                        "
                      ></span>
                    </span>
                  </div>
                  <span class="addWatchlist" @click.stop="showColors">
                    <svg
                      fill="gray"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 14 12"
                      width="14"
                      height="12"
                      focusable="false"
                      preserveAspectRatio="none"
                    >
                      <path d="M14 12l-4-6 4-6H0v12z"></path>
                    </svg>
                  </span>

                  <div class="tdImg">
                    {{ item.tradingSymbol ? item.tradingSymbol[0] : item.code }}
                  </div>
                  <p>
                    {{
                      formatTradingSymbol(
                        item.tradingSymbol ? item.tradingSymbol : item.code
                      ) || item.tradingSymbol
                        ? item.tradingSymbol
                        : item.code
                    }}
                  </p>
                </div>
              </td>
              <td>
                <p>{{ item.name }}</p>
              </td>
              <td>
                <div
                  v-for="color in item.color"
                  :key="color"
                  class="color-circle"
                  :style="{ backgroundColor: color }"
                ></div>
              </td>
              <td class="tdCountry">
                <p>{{ item.instrument }}</p>
                <p>{{ item.exchange }}</p>
                <p>{{ item.assetType }}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <ComingSoon v-else-if="comingSoonToggle" />
        <div v-else>
          <p class="noResults">No results found</p>
        </div>
      </div>
      <p v-if="currentSearchQuery && !comingSoonToggle" :class="alertInfoClass">
        Please start searching by typing instead of scrolling.
      </p>
    </div>
  </div>
</template>

<script>
import data from "../../data/data.json";
import wazirx from "../../data/wazirx.json";
import ComingSoon from "./ComingSoon.vue";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { SERVER_URL } from "../constant.js";
// import cryptoJsonData from "../../data/binance_extract.json";
import cryptoJsonData from "../../data/binance_extract.json";

export default {
  name: "SymbolSearch",
  components: {
    ComingSoon,
  },
  data() {
    return {
      symbol: "",
      filters: data.filtersForSymbolSearch,
      option: "All", // Tracks the selected filter option
      subFilters: data.filtersForSymbolSearch[0]?.subFilters || [], // Initialize with the subFilters of the first option
      subOption: 0,
      selectedSymbol: "",
      page: "symbolSearch",
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
      comingSoonToggle: false,
      colors: [
        "red",
        "blue",
        "green",
        "yellow",
        "purple",
        "Maroon",
        "Olive",
        "DarkGreen",
        "white",
        "Gray",
        "Navy",
        "orange",
        "Brown",
        "Chartreuse",
        "Chocolate",
        "Crimson",
        "DarkBlue",
        "DarkCyan",
        "DarkGoldenRod",
        "black",
      ],
      showColor: false,
      info: [], // holds the raw data
      filteredInfo: [], // Holds the filtered data
      // symbols user have in watchlist
      userSymbols: [],
      allSymbols: [], // Holds all symbols including colors
      socket: {},
    };
  },
  computed: {
    filteredInfoComputed() {
      // console.log("data is ", this.filteredInfo);
      return this.filteredInfo;
    },

    getSelectedSymbol() {
      return this.$store.getters.getSelectedSymbol;
    },
    getIsSymbolSearchOpen() {
      return this.$store.getters.getIsSymbolSearchOpen;
    },
    getSymbolSearch() {
      return this.$store.getters.getSymbolSearch;
    },
    getFocusInput() {
      return this.$store.getters.getFocusInput;
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
    mainClass() {
      return this.isDark ? "main-dark" : "main";
    },
    alertInfoClass() {
      return this.isDark ? "alertInfo-dark" : "alertInfo";
    },
    inputClass() {
      return this.isDark ? "input-dark" : "input";
    },
    subFilterClass() {
      return this.isDark ? "subFilter-dark" : "subFilter";
    },
    rowClass() {
      return this.isDark ? "row-dark" : "row";
    },
    // get socket from store
    getSocketConnection() {
      return this.$store.getters.getSocketConnection;
    },
  },
  async mounted() {
    this.$watch(
      () => this.getFocusInput,
      (newVal) => {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.myInput.focus();
            this.$store.commit("setFocusInput", false); // Reset focus state
          });
        }
      }
    );

    this.loadJSON();

    const url = `${SERVER_URL}/api/watchlist/getAllSymbolsOfUserInWatchlist`;
    const data = {
      email: localStorage.getItem("email"),
    };
    await axios
      .post(url, data)
      .then((res) => {
        this.userSymbols = res.data.symbols;
      })
      .catch((err) => console.log(err));

    const ans = this.addColorsToSearchSymbol(this.info, this.userSymbols);
    this.allSymbols = ans;
    this.applyFilters();
    // if this.info contain this.userSymbols that add color property to that symbol in this.info

    // socket code
    this.socket = this.getSocketConnection;
    // getting user symbols from backend
    this.socket.on("userSymbols", (data) => {
      this.userSymbols = data;
      // console.log("usersymbols :", this.userSymbols)
      const ans = this.addColorsToSearchSymbol(this.info, this.userSymbols);
      this.allSymbols = ans;
      this.applyFilters();
    });
  },
  methods: {
    currentSearchQuery() {
      return this.symbol === "" ? true : false;
    },
    addColorsToSearchSymbol(arr1, arr2) {
      // Create a color map where each symbol maps to an array of colors
      const colorMap = arr2.reduce((acc, item) => {
        if (acc[item.symbol]) {
          item.color.forEach((color) => {
            if (!acc[item.symbol].includes(color)) {
              acc[item.symbol].push(color);
            }
          });
        } else {
          acc[item.symbol] = [...item.color]; // Ensure it's a new array
        }
        return acc;
      }, {});

      // Map over arr1 and add the color array from the colorMap
      return arr1.map((item) => {
        if (colorMap[item.tradingSymbol]) {
          return { ...item, color: colorMap[item.tradingSymbol] };
        }
        return { ...item, color: [] }; // Ensure color is an array even if no match is found
      });
    },
    async colorSelected(color, symbol) {
      const data = {
        email: localStorage.getItem("email"),
        symbol: symbol,
        color: color,
        name: color,
      };

      // socket code
      if (this.socket) {
        this.socket.emit(`addColorToSymbol`, data);
      }
      // this.showColors()
      // api code
      // console.log(data)
      // const url = `${SERVER_URL}/api/watchlist/appendWatchlist`
      // await axios.post(url, data)
      //     .then((response) => {
      //         // console.log(response)
      //     })
      //     .catch((error) => {
      //         console.log(error)
      //     })
    },
    showColors() {
      this.showColor = !this.showColor;
    },
    closeSymbolSearch() {
      this.$store.commit("updateIsSymbolSearchOpen", false);
      this.symbol = "";
    },

    updateSearch() {
      this.$store.commit("updateSymbolSearch", this.symbol);
      this.applyFilters();
      if (!this.symbol) {
        // this.filteredInfo = this.info.slice(0, 200); // Reset filteredInfo to all data when search input is empty
      }
    },

    // user's selected symbol
    updateSelectedSymbol(selectedItem) {
      console.log(selectedItem);
      this.$store.commit(
        "updateSelectedSymbol",
        selectedItem.tradingSymbol
          ? selectedItem.tradingSymbol
          : selectedItem.code
      );
      this.$store.commit("updateSelectedSymbolName", selectedItem.name);
      this.$store.commit("updateSelectedSymbolExchange", selectedItem.exchange);
      this.$store.commit("updateAssetType", selectedItem.assetType);
      let symbolRegistry = {
        tradingSymbol: selectedItem.tradingSymbol
          ? selectedItem.tradingSymbol
          : selectedItem.code,
        name: selectedItem.name,
        exchange: selectedItem.exchange,
      };
      localStorage.setItem("symbolVault", JSON.stringify(symbolRegistry));
      this.closeSymbolSearch();
    },

    // filters
    selectOption(index, filter) {
      this.option = filter.type;
      this.subFilters = this.filters[index]?.subFilters || []; // Update the subFilters based on the selected option
      this.subOption = 0; // Reset subOption when main option changes

      if (this.filters[index]?.existence === false) {
        this.comingSoonToggle = true;
        return;
      } else {
        this.comingSoonToggle = false;
        if (index === 0) {
          this.applyFilters();
        } else {
          console.log("hie");
          this.applySpecificFilter(
            this.filters[index]?.keyWord,
            this.filters[index]?.assetType
          );
        }
      }
    },

    selectSubOption(index) {
      this.subOption = index;
      this.applyFilters();
    },

    isPriorityMatch(item) {
      const priorityItems = ["NIFTY", "BANKNIFTY"];
      return priorityItems.some(
        (priority) => item.name.toUpperCase() === priority
      );
    },

    applyFilters() {
      let filtered = this.allSymbols;
      // console.log("sym is ", this.symbol);
      if (this.symbol) {
        const lowerCaseSearch = this.symbol.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            item.tradingSymbol.toLowerCase().includes(lowerCaseSearch) ||
            item.name?.toLowerCase().includes(lowerCaseSearch) ||
            item.exchange?.toLowerCase().includes(lowerCaseSearch) ||
            item.instrument?.toLowerCase().includes(lowerCaseSearch)
        );
      } else {
        filtered = filtered.filter((item) => item.assetType != "crypto");
      }
      // console.log(filtered);

      const priorityMatches = filtered.filter((item) =>
        this.isPriorityMatch(item)
      );
      const otherMatches = filtered.filter(
        (item) => !this.isPriorityMatch(item)
      );
      // console.log(priorityMatches);
      // console.log(otherMatches);

      priorityMatches.sort((a, b) => {
        if (a.name.toUpperCase() === "NIFTY") return -1;
        if (b.name.toUpperCase() === "NIFTY") return 1;
        if (a.name.toUpperCase() === "BANKNIFTY") return -1;
        if (b.name.toUpperCase() === "BANKNIFTY") return 1;
        return 0;
      });

      this.filteredInfo = [...priorityMatches, ...otherMatches];

      // if (this.filteredInfo.length > 200 && this.symbol === "") {
      //   this.filteredInfo = this.filteredInfo.slice(0, 200);
      // }
      // console.log(this.filteredInfo);
    },

    // this filters the all list accordingly as per stock | indices | forex | crypto , but make sure to include the `keyWord` array in data.json file under -> filtersForSymbolSearch
    applySpecificFilter(keyWord, chosenAssetType) {
      let filtered = this.allSymbols;
      console.log(chosenAssetType);
      if (chosenAssetType === "crypto") {
        console.log(chosenAssetType);
        filtered = this.info.filter((item) => {
          return item.assetType === "crypto";
        });
        this.filteredInfo = [...filtered];
        return;
      } else {
        filtered = filtered.filter((item) =>
          keyWord.some((priority) => item.name.toUpperCase().includes(priority))
        );
      }
      const priorityMatches = filtered.filter((item) =>
        this.isPriorityMatch(item)
      );
      const otherMatches = filtered.filter(
        (item) => !this.isPriorityMatch(item)
      );
      console.log(priorityMatches);
      priorityMatches.sort((a, b) => {
        if (a.name.toUpperCase() === "NIFTY") return -1;
        if (b.name.toUpperCase() === "NIFTY") return 1;
        if (a.name.toUpperCase() === "BANKNIFTY") return -1;
        if (b.name.toUpperCase() === "BANKNIFTY") return 1;
        return 0;
      });

      console.log("hey");
      this.filteredInfo = [...priorityMatches, ...otherMatches];
      console.log(this.filteredInfo);
    },

    selectSymbol(symbol) {
      this.selectedSymbol = symbol;
    },
    // This will get the data from json and retrives as per our needs.
    loadJSON() {
      // Fetches NSE_CM and BSE_CM data
      Promise.all([
        fetch("https://public.fyers.in/sym_details/NSE_CM_sym_master.json"),
        fetch("https://public.fyers.in/sym_details/BSE_CM_sym_master.json"),
      ])
        .then((responses) => {
          return Promise.all(
            responses.map((response) => {
              if (!response.ok) throw new Error("Network response was not ok");
              return response.json();
            })
          );
        })
        .then(([nseData, bseData]) => {
          const nseKeys = Object.keys(nseData);
          const bseKeys = Object.keys(bseData);

          // Process NSE_CM data
          this.info = nseKeys
            .filter(
              (item) =>
                nseData[item].exSeries === "EQ" ||
                nseData[item].exSymName.toLowerCase().includes("nifty")
            )
            .map((key) => ({
              id: uuidv4(),
              tradingSymbol: key,
              exchange: nseData[key].exchangeName,
              name: nseData[key].exSymName,
              instrument: nseData[key].exSeries,
              assetType: "stock",
            }));

          // Process BSE_CM data
          const BSE_Info = bseKeys
            .filter(
              (item) =>
                bseData[item].exSymName.toLowerCase().includes("sensex") ||
                bseData[item].exSymName.toLowerCase().includes("nifty") ||
                bseData[item].exSymName.toLowerCase().includes("s&p")
            )
            .map((key) => ({
              id: uuidv4(),
              tradingSymbol: key,
              exchange: bseData[key].exchangeName,
              name: bseData[key].exSymName,
              instrument: bseData[key].exSeries,
              assetType: "stock",
            }));

          this.info = [...this.info, ...BSE_Info];
        })
        .then(this.loadCryptoData)
        .catch((error) => {
          console.error("Error fetching json data:", error);
        });
    },

    loadCryptoData() {
      // Process crypto data
      const regularMarkets = cryptoJsonData.data
        .filter((crapper) => crapper.baseMarket !== "wrx")
        .map((item) => ({
          id: uuidv4(),
          tradingSymbol: item.tradingSymbol,
          exchange: item.quoteMarket,
          name: item.baseMarket,
          instrument: item.type,
          assetType: "crypto",
        }));
      /*const p2pMarkets = wazirx.p2pMarkets
        .filter((crapper) => crapper.baseMarket !== "wrx")
        .map((item) => ({
          id: uuidv4(),
          tradingSymbol: item.tradingSymbol,
          exchange: item.quoteMarket,
          name: item.baseMarket,
          instrument: item.type,
          assetType: "crypto",
        }));
      const stMarkets = wazirx.stMarkets
        .filter((crapper) => crapper.baseMarket !== "wrx")
        .map((item) => ({
          id: uuidv4(),
          tradingSymbol: item.type,
          exchange: item.quoteMarket,
          name: item.baseMarket,
          instrument: item.type,
          assetType: "crypto",
        }));*/

      const crypto_data = cryptoJsonData;
      // console.log("data is ", crypto_data);
      // this.info = [...this.info, ...crypto_data];
      this.info = [
        ...this.info,
        ...regularMarkets,
        // ...p2pMarkets,
        // ...stMarkets,
      ];
      // console.log(this.info);
      // this.applyFilters(); // Ensure filters are applied after all data is loaded
      this.applySpecificFilter(undefined, "crypto");
    },

    formatTradingSymbol(symbol) {
      if (!symbol) {
        return "";
      }

      if (symbol.includes(":")) {
        return symbol.split(":")[1].split("-")[0];
      } else if (!symbol.includes(":")) {
        return symbol;
      }
    },
  },
  watch: {
    getSymbolSearch(newSymbol) {
      this.symbol = newSymbol;
      this.applyFilters();
    },
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
};
</script>

<style scoped>
.color-circle {
  width: 10px;
  /* Adjust the size as needed */
  height: 10px;
  border-radius: 50%;
  /* Makes the div a circle */
  display: inline-block;
  /* Ensures the div behaves like an inline element */
  margin-right: 5px;
  /* Adds space between circles */
}

.tdSym:hover .colorPattel {
  visibility: visible;
}

.colorPattel {
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  position: absolute;
  background-color: #4e4e4e;
  left: 30px;
  visibility: hidden;
  width: 300px;
  overflow-x: scroll;
}

.colorCircle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.colorCircle:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.page {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(50, 59, 40, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}

.alertInfo {
  width: 100%;
  position: sticky;
  bottom: -10px;
  background-color: #d3d3d3;
  padding: 6px 0px 6px 0px;
  margin: 0 auto 0 auto;
  border-radius: 5px;
  text-align: center;
  font-family: monospace;
}

.alertInfo-dark {
  width: 100%;
  position: sticky;
  bottom: -10px;
  background-color: #2b2b2b;
  padding: 6px 0px 6px 0px;
  margin: 0 auto 0 auto;
  border-radius: 5px;
  text-align: center;
  font-family: monospace;
  color: white;
}

.main {
  width: 60%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  overflow-y: scroll;
}

.main-dark {
  width: 60%;
  height: 80%;
  background-color: #1e1e1e;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  overflow-y: scroll;
}

hr {
  border: none;
  border-top: 1px solid #e0e3eb;
  height: 1px;
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  color: #aeb0b3;
}

.cross:hover {
  background-color: #76878319;
}

.search {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input {
  width: 70%;
  height: 20px;
  border: none;
  outline: none;
  text-transform: uppercase;
  border-right: 1px solid #e0e3eb;
  padding-right: 10px;
  background-color: transparent;
}

.input-dark {
  width: 70%;
  height: 20px;
  border: none;
  outline: none;
  text-transform: uppercase;
  border-right: 1px solid #e0e3eb;
  padding-right: 10px;
  background-color: transparent;
  color: #fff;
}

.tools {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.tool {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tool-dark {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.tool:hover {
  background-color: #76878319;
}

.tool-dark:hover {
  background-color: #76878319;
}

.filters {
  display: flex;
  align-items: center;
  gap: 1px;
}

.filter {
  padding: 4px 15px;
  border-radius: 14px;
  font-weight: 400;
  border: none;
  background-color: #f0f3fa;
  cursor: pointer;
}

.filter:hover {
  background-color: #e9ecf0;
}

.selectedOption {
  background-color: black;
  color: white;
  padding: 4px 15px;
  border-radius: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.subFilters {
  display: flex;
  align-items: center;
  gap: 5px;
}

.subFilter {
  margin-top: 10px;
  padding: 4px 15px;
  border-radius: 5px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.subFilter-dark {
  margin-top: 10px;
  padding: 4px 15px;
  border-radius: 5px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #ccc;
}

.subFilter:hover {
  background-color: #e9ecf0;
}

.subFilter-dark:hover {
  background-color: #602d2d;
}

.data {
  margin-top: 20px;
}

.items {
  width: 100%;
  margin-top: 20px;
}

table,
tbody {
  width: 100%;
}

.row:hover {
  background-color: #eee;
  border-radius: 10px;
  cursor: pointer;
}

.row-dark:hover {
  background-color: #2a2e39;
  border-radius: 10px;
  cursor: pointer;
}

.tdSym {
  display: flex;
  align-items: center;
  gap: 2px;
}

.addWatchlist {
  visibility: hidden;
}

.tdSym:hover .addWatchlist {
  visibility: visible;
}

.tdCountry {
  display: flex;
  gap: 5px;
  align-items: center;
}

.roudedImg {
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.tdImg {
  background-color: #e0e3eb;
  border-radius: 100%;
  padding: 5px;
  font-weight: bold;
  color: white;
}

@media (max-width: 900px) {
  .main {
    width: 90%;
  }

  .main-dark {
    width: 90%;
  }

  .input {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .main {
    width: 100%;
  }

  .main-dark {
    width: 90%;
  }

  .input {
    width: 60%;
  }
}
</style>
