<script>
// The ZoomInTool for zooming in on a chart

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Data from "../../../data/data.json";

export default {
  name: "ZoomInTool", // Name of the component
  mixins: [Overlay, Tool], // Mixin dependencies
  props: ["change_range", "MIN_ZOOM", "MAX_ZOOM"],
  // Define internal settings and constants
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    line_color() {
      return this.sett.lineColor || "#000000"; // Default line color
    },
    backgroundColors() {
      return this.sett.backgroundColors || ["#F5051D80", "#F5051D80"];
    },
    color() {
      return this.sett.color || this.$props.colors.cross;
    },
    back_color() {
      return this.sett.backColor || "#9b9ba316";
    },
    value_back() {
      return this.sett.valueBack || "#9b9ba316";
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text;
    },
    prec() {
      return this.sett.precision || 2;
    },
    new_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    price() {
      return "price" in this.sett ? this.sett.price : true;
    },
    time() {
      return "time" in this.sett ? this.sett.time : false;
    },
    shift() {
      return this.sett.shiftMode;
    },
  },
  methods: {
    // Meta information about the tool
    meta_info() {
      return { author: "dhruv-atiuttam", version: "1.0.0" };
    },
    // Tool descriptor
    tool() {
      return {
        // Icon representing the tool
        icon: Icons["zoomin.svg"],
        // Type of the tool
        type: "ZoomIn",
        // Hint for the tool
        hint: "Zoom in",
        // Default data for the tool
        data: [],
        // Default settings for the tool
        settings: {
          lineColor: "#000000",
          backgroundColors: ["#0000FF40", "#0000FF40"],
        },
        // Modifications for different modes of the tool
        mods: {
          ShiftMode: {
            settings: {
              price: false,
              time: true,
              shiftMode: true,
            },
            hidden: true,
          },
        },
      };
    },
    // Called after the overlay is mounted
    init() {
      this.init_tool(); // Initialize the tool with mixin functionalities

      // First pin is placed at the mouse position
      this.pins.push(
        new Pin(this, "p1", {
          hidden: this.shift,
        })
      );
      // Second pin follows the mouse until it clicks
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
          hidden: this.shift,
        })
      );

      this.pins[1].on("settled", () => {
        // Finish drawing the tool
        this.set_state("finished");
        this.$emit("drawing-mode-off");

        // Deselect the tool in shiftMode
        if (this.shift)
          this._$emit("custom-event", {
            event: "object-selected",
            args: [],
          });

        // Apply the zoom
        this.apply_zoom();
      });
    },
    // Apply the zoom functionality
    apply_zoom() {
      if (!this.p1 || !this.p2) return;

      const x1 = Math.min(this.p1[0], this.p2[0]);
      const x2 = Math.max(this.p1[0], this.p2[0]);
      const timeStamps = Data.ohlcv;
      let pin1_0 = timeStamps.findIndex((timeStamp) => {
        return timeStamp[0] === x1;
      });

      let pin1_1 = timeStamps.findIndex((timeStamp) => {
        return timeStamp[0] === x2;
      });
      if (pin1_0 == -1) pin1_0 = 0;
      if (pin1_1 == -1) pin1_1 = timeStamps.length - 1;
      let bars = pin1_1 - pin1_0;

      if (bars >= this.MIN_ZOOM) {
        this.change_range([x1, x2]);
      }

      // Remove the drawing
      this.remove_tool();
    },
    // Draw method for rendering the zoom rectangle
    draw(ctx) {
      if (!this.p1 || !this.p2) return;

      const layout = this.$props.layout;
      const x1 = layout.t2screen(this.p1[0]);
      const y1 = layout.$2screen(this.p1[1]);
      const x2 = layout.t2screen(this.p2[0]);
      const y2 = layout.$2screen(this.p2[1]);

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;
      ctx.fillStyle = this.backgroundColors[0];

      // Draw the zoom rectangle
      ctx.beginPath();
      ctx.rect(
        Math.min(x1, x2),
        Math.min(y1, y2),
        Math.abs(x2 - x1),
        Math.abs(y2 - y1)
      );
      ctx.fill();
      ctx.stroke();
    },
    // Define which tools this component should be used for
    use_for() {
      return ["ZoomInTool"];
    },
    // Define the colors for the data
    data_colors() {
      return [this.color];
    },
  },
};
</script>
