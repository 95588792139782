<template>
  <div class="modal-overlay" @click="closeModal">
    <div :class="contentClass" @click.stop>
      <div class="content">
        <h2>Chart Settings</h2>
        <button class="close-button" @click="closeModal">×</button>
      </div>
      <div class="main-div">
        <div class="sidebar">
          <div @click="showMainContent = 'symbol'" class="symbol">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="currentColor"
                d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"
              ></path>
            </svg>
            <p>Symbol</p>
          </div>
        </div>
        <div class="main-content">
          <!-- Use showMainContent to conditionally render CandlesSetting -->
          <CandlesSetting
            v-if="showMainContent === 'symbol'"
            :closeModal="closeModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CandlesSetting from "./SettingsPage/CandleSettings.vue";
export default {
  name: "ChartSetting",
  components: {
    CandlesSetting,
  },
  data() {
    return {
      showMainContent: "",
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },
  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    contentClass() {
      return this.isDark ? "modal-content-dark" : "modal-content";
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  position: relative;
}
.modal-content-dark {
  background-color: #1e1e1e;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.close-button {
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.content {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.content h2 {
  font-size: 20px;
}
.main-div {
  display: flex;
}
.sidebar {
  width: 30%;
  height: 400px;
  border-right: 1px solid #ccc;
}
.symbol {
  cursor: pointer;
  margin: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.symbol svg {
  margin-top: 5px;
}
.symbol p {
  font-size: 16px;
  margin-left: 10px;
}
.main-content {
  width: 70%;
}
</style>
