import Math2 from "../../stuff/math.js";
import Utils from "../../stuff/utils.js";

export default class CrossLine {
  // Overlay ref, canvas ctx
  constructor(overlay, ctx) {
    this.ctx = ctx;
    this.comp = overlay;
    this.T = overlay.$props.config.TOOL_COLL;
    if (Utils.is_mobile) this.T *= 2;
  }

  // p1[t, $] (time-price coordinates)
  draw(p1) {
    const layout = this.comp.$props.layout;

    let x1 = layout.t2screen(p1[0]);
    let y1 = layout.$2screen(p1[1]);

    // Extend the horizontal line to the full width of the canvas
    let w = layout.width;
    let dx = w * 2; // Extend far beyond the canvas width

    this.ctx.beginPath();
    this.ctx.moveTo(-dx, y1); // Start from far left
    this.ctx.lineTo(w + dx, y1); // End far right
    this.ctx.stroke();

    this.comp.collisions.push(this.make([-dx, y1], [w + dx, y1]));

    // Extend the vertical line to the full height of the canvas
    let h = layout.height;
    let dy = h * 2; // Extend far beyond the canvas height

    this.ctx.beginPath();
    this.ctx.moveTo(x1, -dy); // Start from far top
    this.ctx.lineTo(x1, h + dy); // End far bottom
    this.ctx.stroke();

    this.comp.collisions.push(this.make([x1, -dy], [x1, h + dy]));
  }

  // Collision function. x, y - mouse coord.
  make(p1, p2) {
    return (x, y) => {
      return Math2.point2line([x, y], p1, p2) < this.T;
    };
  }
}
