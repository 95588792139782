export async function getAccessToken() {
    try {
        const response = await fetch(`${process.env.VUE_APP_WORKER_URL}token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        } 
        let outcome = await response.json();
        return outcome;
    } catch (error) {
        console.error('Failed to fetch access token:', error);
    }
};
