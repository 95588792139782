var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.tool_count,
      staticClass: "tradilent-vue-toolbar",
      style: _vm.styles,
    },
    [
      _vm._l(_vm.groups, function (tool, i) {
        return tool.icon && !tool.hidden && !_vm.isZoomOut(tool)
          ? _c("toolbar-item", {
              key: i,
              attrs: {
                replay: _vm.$props.replay,
                data: tool,
                subs: _vm.sub_map,
                dc: _vm.data,
                config: _vm.config,
                colors: _vm.colors,
                selected: _vm.is_selected(tool),
              },
              on: { "item-selected": _vm.selected },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _c("Lock"),
      _vm._v(" "),
      _c("Profile"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }