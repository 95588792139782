var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "button",
      {
        attrs: { disabled: _vm.isRecording },
        on: { click: _vm.startRecording },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 45 45",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M22.5 41.25C12.1447 41.25 3.75 32.8552 3.75 22.5C3.75 12.1447 12.1447 3.75 22.5 3.75C32.8552 3.75 41.25 12.1447 41.25 22.5C41.25 32.8552 32.8552 41.25 22.5 41.25ZM22.5 37.5C30.7843 37.5 37.5 30.7843 37.5 22.5C37.5 14.2157 30.7843 7.5 22.5 7.5C14.2157 7.5 7.5 14.2157 7.5 22.5C7.5 30.7843 14.2157 37.5 22.5 37.5ZM22.5 28.125C19.3933 28.125 16.875 25.6067 16.875 22.5C16.875 19.3933 19.3933 16.875 22.5 16.875C25.6067 16.875 28.125 19.3933 28.125 22.5C28.125 25.6067 25.6067 28.125 22.5 28.125Z",
                fill: "#CCCCCC",
              },
            }),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        attrs: { disabled: !_vm.isRecording },
        on: { click: _vm.stopRecording },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 39 38",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M19.5 37.5C9.14466 37.5 0.75 29.1052 0.75 18.75C0.75 8.39466 9.14466 0 19.5 0C29.8552 0 38.25 8.39466 38.25 18.75C38.25 29.1052 29.8552 37.5 19.5 37.5ZM13.875 13.125V24.375H25.125V13.125H13.875Z",
                fill: "#CCCCCC",
              },
            }),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("button", { on: { click: _vm.openUserVideos } }, [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "20",
            height: "20",
            viewBox: "0 0 45 46",
            fill: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M5.60963 39.875C4.58259 39.875 3.75 39.0408 3.75 38.0124V7.98763C3.75 6.95893 4.60371 6.125 5.60963 6.125H39.3904C40.4175 6.125 41.25 6.95928 41.25 7.98763V38.0124C41.25 39.041 40.3963 39.875 39.3904 39.875H5.60963ZM37.5 28.625V9.875H7.5V36.125L26.25 17.375L37.5 28.625ZM37.5 33.9282L26.25 22.6782L12.8033 36.125H37.5V33.9282ZM15 21.125C12.9289 21.125 11.25 19.4461 11.25 17.375C11.25 15.3039 12.9289 13.625 15 13.625C17.0711 13.625 18.75 15.3039 18.75 17.375C18.75 19.4461 17.0711 21.125 15 21.125Z",
              fill: "#CCCCCC",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }