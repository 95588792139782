var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", on: { click: _vm.handlePageClick } },
    [
      _c("div", { ref: "mainContent", class: _vm.mainClass }, [
        _c("button", { staticClass: "cross", on: { click: _vm.closePage } }, [
          _vm._v("X"),
        ]),
        _vm._v(" "),
        _c("h1", { class: _vm.titleClass }, [_vm._v("Watchlist")]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "search" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "18",
                height: "18",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  stroke: "currentColor",
                  d: "M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            ref: "myInput",
            class: _vm.inputClass,
            attrs: { type: "text", placeholder: "Search List" },
            domProps: { value: _vm.search },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.search = $event.target.value
                },
                _vm.updateSearch,
              ],
            },
          }),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "sidebar" }, [
            _c("div", { staticClass: "sidebarObject" }, [
              _c("span", { staticClass: "showAlways" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 28 28",
                      width: "28",
                      height: "28",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        fill: "currentColor",
                        d: "M9 8.5h10V7H9v1.5ZM9 12.5h10V11H9v1.5ZM19 16.5H9V15h10v1.5Z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        fill: "currentColor",
                        "fill-rule": "evenodd",
                        d: "m14 21-9 4V3h18v22l-9-4Zm-7.5 1.7V4.5h15v18.2L14 19.35l-7.5 3.33Z",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "HiddenForSmallScreen" }, [
                _vm._v("My Watchlists"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sidebarObject newList",
                on: { click: _vm.ToggleCreateNewListPage },
              },
              [
                _c("span", { staticClass: "showAlways" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 28 28",
                        width: "28",
                        height: "28",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M16.59 22.21 14 21.1 5 25V3h18v10h-1V4H6v19.48L14 20l3 1.3-.41.91Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M22 19v-4h1v4h4v1h-4v4h-1v-4h-4v-1h4ZM9 8h10V7H9v1ZM9 12h10v-1H9v1ZM17 16H9v-1h8v1Z",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "HiddenForSmallScreen" }, [
                  _vm._v("Create new list"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "Body" }, [
            _c("table", { staticClass: "listTable bodyHeader" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.filteredWatchlist, function (item, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      staticClass: "trClass",
                      on: {
                        click: function ($event) {
                          return _vm.selectWatchlist(item)
                        },
                      },
                    },
                    [
                      _c("td", { staticClass: "watchlistName" }, [
                        _c(
                          "svg",
                          {
                            style: { fill: item.color },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 14 12",
                              width: "14",
                              height: "12",
                              focusable: "false",
                              preserveAspectRatio: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: { d: "M14 12l-4-6 4-6H0v12z" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", { class: _vm.themeTextChange }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          attrs: { type: "text", value: "sankalp" },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "edit",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleEdit(item)
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 28 28",
                                  width: "28",
                                  height: "28",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    fill: "currentColor",
                                    d: "M15 6H7.5C6.67 6 6 6.67 6 7.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16h1v4.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 5 20.5v-13A2.5 2.5 0 0 1 7.5 5H15v1Z",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    fill: "currentColor",
                                    d: "M22.41 5.7a2 2 0 0 0-2.82 0L11 14.3V18h3.7l8.6-8.59a2 2 0 0 0 0-2.82l-.89-.88Zm-2.12.71a1 1 0 0 1 1.42 0l.88.88a1 1 0 0 1 0 1.42l-.59.58L19.7 7l.6-.59Zm1 3.59-7 7H12v-2.3l7-7 2.3 2.3Z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { class: _vm.themeTextChange }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.symbols.length) +
                            "\n              "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.createNewListPage,
                expression: "createNewListPage",
              },
            ],
            class: _vm.createNewListPageClass,
          },
          [
            _c(
              "span",
              {
                staticClass: "cross",
                on: { click: _vm.ToggleCreateNewListPage },
              },
              [_vm._v("X")]
            ),
            _vm._v(" "),
            _c("div", { class: _vm.mainComponentClass }, [
              _c("h2", { class: _vm.titleTextClass }, [_vm._v("Create list")]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "inputContainer" }, [
                  _c("p", [_vm._v("Create New Watchlist")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newListName,
                        expression: "newListName",
                      },
                    ],
                    class: _vm.ListInputClass,
                    attrs: { type: "text", placeholder: "Enter List name" },
                    domProps: { value: _vm.newListName },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.newListName = $event.target.value
                        },
                        _vm.updateListName,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "ColorText" }, [
                    _vm._v("Choose a Color for your Watchlist"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      attrs: { name: "selectColor", id: "selectColor" },
                      on: { change: _vm.selectColor },
                    },
                    _vm._l(_vm.colors, function (color) {
                      return _c(
                        "option",
                        { key: color, domProps: { value: color } },
                        [
                          _c("span", {
                            staticClass: "colorBlock",
                            style: { backgroundColor: color },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(color))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "save", on: { click: _vm.createList } },
                  [_vm._v("Create")]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("FLAGGED LISTS")]),
        _vm._v(" "),
        _c("th", [_vm._v("SYMBOLS")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }