let URL;
import axios from "axios";

export async function fetchHistoricalDataTrademade(
  symbol,
  interval,
  startTime,
  endTime,
  period
) {
  console.log("sym b is ", symbol);
  URL = `https://marketdata.tradermade.com/api/v1/timeseries?currency=${symbol}USD&api_key=_wr39df8pV7dFaxnyYOJ&start_date=${startTime}&end_date=${endTime}&format=records&interval=${interval}&period=${period}`;

  const response = await axios.get(URL);

  return response.data;
}
