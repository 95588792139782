<template>
    <div class="page">
        <div class="popup">
            <div class="popup_header">
                <p class="title">Do more <br><span class="titleColored">with Signup.</span></p>
            </div>
            <div class="popup_content">
                <img src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722335596/pqtnvtamu9vwlnjkslnd.png" alt="" class="img">
                <img src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722335651/klr4fayx6fsq1cytrrdf.png" alt="" class="imgAb">
                <div class="signup">
                    <button class="signinBtn" @click="handleSignUpPopup">
                        <p class="signupText">Signup</p>
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Icons from '../stuff/icons.json'
    export default {
        methods: {
            handleSignUpPopup() {
                this.$router.push(`/signup`)
            }
        },
        data(){
            return{
                img1: null
            }
        },
        mounted(){
            this.img1 = Icons["popup.png"]
        }
    }

</script>

<style scoped>
.title{
    font-size: 40px;
    text-align: center;
    color: white;
}
.titleColored{
    background-image: linear-gradient(180deg, #fff 14.17%, #b372cf);
    -webkit-background-clip: text;
    /* For WebKit browsers */
    background-clip: text;
    /* For other browsers */
    color: transparent;
}
.page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 59, 40, 0.6);
    z-index: 1005;
}
.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    width: fit-content;
    height: fit-content;
    padding: 0px 100px 40px 100px;
}
.popup_content{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: relative;
}
.imgAb{
    position: absolute;
}
.signup{
    position: absolute;
    bottom: 10px;

}
.signinBtn{
    outline: none;
    border-radius: 8px; 
    background-clip: border-box;
    position: relative;
    background:rgba(140, 69, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 3px 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 0 15px rgba(140, 69, 255); /* Inner shadow */
    cursor: pointer;
}
.signupText{
    color: white;
}
</style>