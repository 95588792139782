// Draws a line, adds corresponding collision f-n

import Math2 from "../../stuff/math.js";
import Utils from "../../stuff/utils.js";

export default class LongPosition {
  constructor(overlay, ctx) {
    this.ctx = ctx;
    this.comp = overlay;
    this.T = overlay.$props.config.TOOL_COLL;
    if (Utils.is_mobile) this.T *= 2;
  }

  draw(p1, p2, p3, p4) {
    const layout = this.comp.$props.layout;

    let x1 = layout.t2screen(p1[0]);
    let y1 = layout.$2screen(p1[1]);
    let x2 = layout.t2screen(p2[0]);
    let y2 = layout.$2screen(p2[1]);
    let x3 = layout.t2screen(p3[0]);
    let y3 = layout.$2screen(p3[1]);
    let x4 = layout.t2screen(p4[0]);
    let y4 = layout.$2screen(p4[1]);

    // First fill path
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.lineTo(x2, y3);
    this.ctx.lineTo(x3, y3);
    this.ctx.lineTo(x1, y1);
    this.ctx.fillStyle = "#00ff0026";
    this.ctx.fill();

    // Second fill path
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x4, y4);
    this.ctx.lineTo(x2, y4);
    this.ctx.lineTo(x2, y2);
    this.ctx.lineTo(x1, y1);
    this.ctx.fillStyle = "#ff000026";
    this.ctx.fill();

    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke();

    // this.render_pins(this.ctx);

    this.comp.collisions.push((x, y) => {
      return x >= x1 && x <= x2 && y >= y3 && y <= y4;
    });
  }
}
