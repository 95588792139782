var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageClass }, [
    _c("div", { staticClass: "mainComponent" }, [
      _c("p", { class: _vm.companyNameClass }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: "/public/Tradilent_logo.png", alt: "logo" },
        }),
        _vm._v("\n            Tradilent\n        "),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "register" },
        [
          _vm._v("Have an account ? "),
          _c(
            "router-link",
            { class: _vm.signinClass, attrs: { to: "/signin" } },
            [_vm._v("Sign in")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { class: _vm.formFrameClass }, [
        _c("div", { staticClass: "form" }, [
          _c("h3", { class: _vm.headingClass }, [
            _vm._v("Let's get you set up"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "para" }, [
            _vm._v("Join Tradilent by entering your email"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "emailSection" }, [
            _c("p", [_vm._v("Email")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              attrs: {
                type: "email",
                id: "email",
                placeholder: "example@gmail.com",
              },
              domProps: { value: _vm.email },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                  _vm.updateEmail,
                ],
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isChecked,
                    expression: "isChecked",
                  },
                ],
                attrs: { type: "checkbox", id: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.isChecked)
                    ? _vm._i(_vm.isChecked, null) > -1
                    : _vm.isChecked,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.isChecked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isChecked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isChecked = $$c
                      }
                    },
                    _vm.setCanLogin,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "notify", attrs: { for: "checkbox" } },
                [
                  _vm._v(
                    "\n                            Creating an account means you’re okay with our Terms of Service, Privacy Policy, and\n                            default Notification Settings\n                        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "signupEmail",
              attrs: { disabled: !_vm.CanLogin },
              on: { click: _vm.signup },
            },
            [_vm._v("Continue with email\n                ")]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "authProviders" }, [
            _c(
              "button",
              { staticClass: "signupGoogle", on: { click: _vm.handleGoogle } },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "21",
                      height: "20",
                      viewBox: "0 0 21 20",
                      fill: "none",
                    },
                  },
                  [
                    _c("g", { attrs: { "clip-path": "url(#clip0_5_68)" } }, [
                      _c("path", {
                        attrs: {
                          d: "M20.1028 10.1313C20.1028 9.36907 20.0395 8.81285 19.9025 8.23603H10.9956V11.6763H16.2238C16.1184 12.5313 15.5492 13.8188 14.2843 14.684L14.2666 14.7992L17.0828 16.9311L17.2779 16.9502C19.0698 15.333 20.1028 12.9536 20.1028 10.1313Z",
                          fill: "#4285F4",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M10.9958 19.1954C13.5571 19.1954 15.7074 18.3713 17.278 16.9499L14.2845 14.6837C13.4834 15.2297 12.4082 15.6108 10.9958 15.6108C8.48709 15.6108 6.35788 13.9937 5.59888 11.7585L5.48762 11.7677L2.55929 13.9823L2.521 14.0863C4.08101 17.1146 7.2854 19.1954 10.9958 19.1954Z",
                          fill: "#34A853",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M5.59856 11.7588C5.39829 11.1819 5.28238 10.5639 5.28238 9.92527C5.28238 9.28661 5.39829 8.6686 5.58802 8.09179L5.58271 7.96894L2.61769 5.71877L2.52068 5.76386C1.87772 7.02053 1.50879 8.43171 1.50879 9.92527C1.50879 11.4188 1.87772 12.8299 2.52068 14.0866L5.59856 11.7588Z",
                          fill: "#FBBC05",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M10.9958 4.23925C12.7771 4.23925 13.9788 4.99118 14.6639 5.61955L17.3413 3.06503C15.697 1.57147 13.5571 0.654732 10.9958 0.654732C7.2854 0.654732 4.08101 2.7354 2.521 5.7637L5.58834 8.09162C6.35788 5.85644 8.48709 4.23925 10.9958 4.23925Z",
                          fill: "#EB4335",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("defs", [
                      _c("clipPath", { attrs: { id: "clip0_5_68" } }, [
                        _c("rect", {
                          attrs: {
                            width: "20",
                            height: "20",
                            fill: "white",
                            transform: "translate(0.810547)",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(
                  "\n                        Sign up with google\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contant" }, [
      _c("p", [_vm._v("Contact us")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator" }, [
      _c("span", { staticClass: "separatorMsg" }, [_vm._v("or sign up with")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }