var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "box" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("button", { staticClass: "visitBtn" }, [
        _vm._v("Click here to visit."),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "disclaimer" }, [
        _vm._v(
          "Disclaimer : Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint aliquam\n            repudiandae quidem. Et\n            suscipit corrupti dicta rem ipsa aliquid accusamus, deleniti est tempore nisi aspernatur aperiam.\n            Voluptatibus itaque corrupti consequuntur."
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "AD_txt" }, [_vm._v("AD")]),
      _vm._v(" "),
      _c("p", { staticClass: "close", on: { click: _vm.closeAdvertisement } }, [
        _vm._v("X"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "section1" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "/public/your_logo_here.jpg", alt: "brand logo" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c("p", { staticClass: "hookLine" }, [
          _vm._v("Lorem ipsum dolor sit amet consectetur adipisicing elit."),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "specifiedHookLine" }, [
          _vm._v(
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi sequi\n                    nihil\n                    commodi, cumque ex voluptatem officia quos provident."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }