var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "profile", on: { click: _vm.handlePopup } }, [
      _vm.profile_pic
        ? _c("img", {
            staticClass: "logo",
            attrs: { src: _vm.profile_pic, alt: "avatar" },
          })
        : _c("img", {
            staticClass: "logo",
            attrs: { src: "/public/Tradilent_logo.png", alt: "" },
          }),
    ]),
    _vm._v(" "),
    _vm.popupSignup
      ? _c(
          "div",
          { class: _vm.popupSignupClass },
          [
            _c(
              "span",
              { staticClass: "cross", on: { click: _vm.closePopup } },
              [_vm._v("X")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form" }, [
              _c("div", { staticClass: "nameSection" }, [
                _c("div", { staticClass: "logoDiv" }, [
                  _vm.profile_pic
                    ? _c("img", {
                        staticClass: "logo",
                        attrs: { src: _vm.profile_pic, alt: "avatar" },
                      })
                    : _c("img", {
                        staticClass: "logo",
                        attrs: { src: "/public/Tradilent_logo.png", alt: "" },
                      }),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userName,
                      expression: "userName",
                    },
                  ],
                  class: _vm.inputClass,
                  attrs: {
                    type: "text",
                    readonly: "",
                    placeholder: "John doe",
                  },
                  domProps: { value: _vm.userName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.userName = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.itemsClass,
                on: { click: () => _vm.accountSettingsOpen(0) },
              },
              [_vm._v("\n      Personal information\n    ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.itemsClass,
                on: { click: () => _vm.accountSettingsOpen(1) },
              },
              [_vm._v("\n      Account and Billing\n    ")]
            ),
            _vm._v(" "),
            !_vm.user
              ? _c(
                  "router-link",
                  { staticClass: "sign-up-box", attrs: { to: "/signup" } },
                  [_vm._v("\n      SignUp\n    ")]
                )
              : _c(
                  "div",
                  {
                    staticClass: "sign-up-box",
                    on: { click: _vm.logoutHandler },
                  },
                  [_vm._v("Logout")]
                ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }