var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tfSelector" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected",
          },
        ],
        class: _vm.selectorClass,
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onSelectChange,
          ],
        },
      },
      _vm._l(_vm.displayedTimeframes, function (tf, i) {
        return _c("option", { key: i, domProps: { value: i } }, [
          _vm._v("\n      " + _vm._s(tf.text) + "\n    "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }