var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageClass }, [
    _c("div", { staticClass: "mainComponent" }, [
      _c("p", { class: _vm.companyNameClass, on: { click: _vm.demo } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: "/public/Tradilent_logo.png", alt: "logo" },
        }),
        _vm._v("\n      Tradilent\n    "),
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "register" },
        [
          _vm._v("New Here ? "),
          _c(
            "router-link",
            { class: _vm.signinClass, attrs: { to: "/signup" } },
            [_vm._v("Sign Up")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { class: _vm.formFrameClass }, [
        _c("div", { staticClass: "loginWithPassword" }, [
          _c("div", { staticClass: "form" }, [
            _c("h3", { class: _vm.headingClass }, [_vm._v("Welcome Back!")]),
            _vm._v(" "),
            _c("p", { staticClass: "para" }, [
              _vm._v("Join Tradilent by entering your email"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "nameText" }, [
              _vm._v("You can sign in with Passsword"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "emailSection" }, [
              _c("p", { staticClass: "nameText" }, [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                attrs: {
                  type: "email",
                  id: "email",
                  placeholder: "example@gmail.com",
                },
                domProps: { value: _vm.email },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                    _vm.updateEmail,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "PasswordSection" }, [
              _c("p", { staticClass: "nameText" }, [_vm._v("Password")]),
              _vm._v(" "),
              _c("div", { staticClass: "passwordField" }, [
                (_vm.showPassword ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: { id: "password", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        input: _vm.updatePassword,
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : (_vm.showPassword ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: { id: "password", type: "radio" },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        input: _vm.updatePassword,
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        id: "password",
                        type: _vm.showPassword ? "text" : "password",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                          _vm.updatePassword,
                        ],
                      },
                    }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "showPasswordBtn",
                    on: { click: _vm.toggleShowPassword },
                  },
                  [
                    _vm.showPassword
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 28 28",
                              width: "28",
                              height: "28",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d: "M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d: "M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z",
                              },
                            }),
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 28 28",
                              width: "28",
                              height: "28",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d: "M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                "fill-rule": "evenodd",
                                d: "M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z",
                              },
                            }),
                          ]
                        ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "signupEmail",
                attrs: { disabled: !_vm.CanLogin },
                on: { click: _vm.signinUsingPassword },
              },
              [_vm._v("Login")]
            ),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "toAppearOnSmallWidthDevice" }, [
              _c(
                "button",
                { staticClass: "signupEmail", on: { click: _vm.handleOTP } },
                [_vm._v("Request OTP")]
              ),
              _vm._v(" "),
              _vm._m(2),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "authProviders" }, [
              _c(
                "button",
                {
                  staticClass: "signupGoogle",
                  on: { click: _vm.handleGoogle },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "21",
                        height: "20",
                        viewBox: "0 0 21 20",
                        fill: "none",
                      },
                    },
                    [
                      _c("g", { attrs: { "clip-path": "url(#clip0_5_68)" } }, [
                        _c("path", {
                          attrs: {
                            d: "M20.1028 10.1313C20.1028 9.36907 20.0395 8.81285 19.9025 8.23603H10.9956V11.6763H16.2238C16.1184 12.5313 15.5492 13.8188 14.2843 14.684L14.2666 14.7992L17.0828 16.9311L17.2779 16.9502C19.0698 15.333 20.1028 12.9536 20.1028 10.1313Z",
                            fill: "#4285F4",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M10.9958 19.1954C13.5571 19.1954 15.7074 18.3713 17.278 16.9499L14.2845 14.6837C13.4834 15.2297 12.4082 15.6108 10.9958 15.6108C8.48709 15.6108 6.35788 13.9937 5.59888 11.7585L5.48762 11.7677L2.55929 13.9823L2.521 14.0863C4.08101 17.1146 7.2854 19.1954 10.9958 19.1954Z",
                            fill: "#34A853",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M5.59856 11.7588C5.39829 11.1819 5.28238 10.5639 5.28238 9.92527C5.28238 9.28661 5.39829 8.6686 5.58802 8.09179L5.58271 7.96894L2.61769 5.71877L2.52068 5.76386C1.87772 7.02053 1.50879 8.43171 1.50879 9.92527C1.50879 11.4188 1.87772 12.8299 2.52068 14.0866L5.59856 11.7588Z",
                            fill: "#FBBC05",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M10.9958 4.23925C12.7771 4.23925 13.9788 4.99118 14.6639 5.61955L17.3413 3.06503C15.697 1.57147 13.5571 0.654732 10.9958 0.654732C7.2854 0.654732 4.08101 2.7354 2.521 5.7637L5.58834 8.09162C6.35788 5.85644 8.48709 4.23925 10.9958 4.23925Z",
                            fill: "#EB4335",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_5_68" } }, [
                          _c("rect", {
                            attrs: {
                              width: "20",
                              height: "20",
                              fill: "white",
                              transform: "translate(0.810547)",
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v("\n              Sign up with google\n            "),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "loginWithOTP" }, [
          _c("div", { staticClass: "form" }, [
            _c("h3", { class: _vm.headingClass }, [_vm._v("Welcome Back!")]),
            _vm._v(" "),
            _c("p", { staticClass: "para" }, [
              _vm._v("Join Tradilent by entering your email"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "nameText" }, [
              _vm._v("Request OTP To login"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "emailSection" }, [
              _c("p", { staticClass: "nameText" }, [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                attrs: {
                  type: "email",
                  id: "email",
                  placeholder: "example@gmail.com",
                },
                domProps: { value: _vm.email },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                    _vm.updateEmail,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "signupEmail", on: { click: _vm.handleOTP } },
              [_vm._v("Request OTP")]
            ),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "authProviders" }, [
              _c(
                "button",
                {
                  staticClass: "signupGoogle",
                  on: { click: _vm.handleGoogle },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "21",
                        height: "20",
                        viewBox: "0 0 21 20",
                        fill: "none",
                      },
                    },
                    [
                      _c("g", { attrs: { "clip-path": "url(#clip0_5_68)" } }, [
                        _c("path", {
                          attrs: {
                            d: "M20.1028 10.1313C20.1028 9.36907 20.0395 8.81285 19.9025 8.23603H10.9956V11.6763H16.2238C16.1184 12.5313 15.5492 13.8188 14.2843 14.684L14.2666 14.7992L17.0828 16.9311L17.2779 16.9502C19.0698 15.333 20.1028 12.9536 20.1028 10.1313Z",
                            fill: "#4285F4",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M10.9958 19.1954C13.5571 19.1954 15.7074 18.3713 17.278 16.9499L14.2845 14.6837C13.4834 15.2297 12.4082 15.6108 10.9958 15.6108C8.48709 15.6108 6.35788 13.9937 5.59888 11.7585L5.48762 11.7677L2.55929 13.9823L2.521 14.0863C4.08101 17.1146 7.2854 19.1954 10.9958 19.1954Z",
                            fill: "#34A853",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M5.59856 11.7588C5.39829 11.1819 5.28238 10.5639 5.28238 9.92527C5.28238 9.28661 5.39829 8.6686 5.58802 8.09179L5.58271 7.96894L2.61769 5.71877L2.52068 5.76386C1.87772 7.02053 1.50879 8.43171 1.50879 9.92527C1.50879 11.4188 1.87772 12.8299 2.52068 14.0866L5.59856 11.7588Z",
                            fill: "#FBBC05",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M10.9958 4.23925C12.7771 4.23925 13.9788 4.99118 14.6639 5.61955L17.3413 3.06503C15.697 1.57147 13.5571 0.654732 10.9958 0.654732C7.2854 0.654732 4.08101 2.7354 2.521 5.7637L5.58834 8.09162C6.35788 5.85644 8.48709 4.23925 10.9958 4.23925Z",
                            fill: "#EB4335",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_5_68" } }, [
                          _c("rect", {
                            attrs: {
                              width: "20",
                              height: "20",
                              fill: "white",
                              transform: "translate(0.810547)",
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v("\n              Sign up with google\n            "),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contant" }, [
      _c("p", [_vm._v("Contact us")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator" }, [
      _c("span", { staticClass: "separatorMsg" }, [_vm._v("or sign up with")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator" }, [
      _c("span", { staticClass: "separatorMsg" }, [_vm._v("or sign up with")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "separator" }, [
      _c("span", { staticClass: "separatorMsg" }, [_vm._v("or sign up with")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }