var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getIsSymbolSearchOpen
    ? _c("div", { staticClass: "page" }, [
        _c("div", { class: _vm.mainClass }, [
          _c(
            "button",
            { staticClass: "cross", on: { click: _vm.closeSymbolSearch } },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _c("h1", [_vm._v("Symbol Search")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "search" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "18",
                  height: "18",
                  fill: "none",
                },
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    d: "M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.symbol,
                  expression: "symbol",
                },
              ],
              ref: "myInput",
              class: _vm.inputClass,
              attrs: { type: "text", placeholder: "Search Symbol" },
              domProps: { value: _vm.symbol },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.symbol = $event.target.value
                  },
                  _vm.updateSearch,
                ],
              },
            }),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "symbolSearch" },
            [
              _c(
                "div",
                { staticClass: "filters" },
                _vm._l(_vm.filters, function (filter, i) {
                  return _c(
                    "button",
                    {
                      key: i,
                      class: {
                        selectedOption: filter.type === _vm.option,
                        filter: filter.type !== _vm.option,
                      },
                      on: { click: () => _vm.selectOption(i, filter) },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(filter.type) + "\n        "
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.filteredInfoComputed.length > 0 && !_vm.comingSoonToggle
                ? _c("table", { staticClass: "items" }, [
                    _c(
                      "tbody",
                      _vm._l(_vm.filteredInfo, function (item) {
                        return _c(
                          "tr",
                          {
                            key: item.id ? item.id : item.code,
                            class: _vm.rowClass,
                            on: {
                              click: () => {
                                _vm.selectSymbol(item.tradingSymbol)
                                _vm.updateSelectedSymbol(item)
                              },
                            },
                          },
                          [
                            _c("td", [
                              _c("div", { staticClass: "tdSym" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showColor,
                                        expression: "showColor",
                                      },
                                    ],
                                    staticClass: "colorPattel",
                                  },
                                  _vm._l(_vm.colors, function (color, index) {
                                    return _c("span", { key: index }, [
                                      _c("span", {
                                        staticClass: "colorCircle",
                                        style: { backgroundColor: color },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.colorSelected(
                                              color,
                                              item.tradingSymbol
                                                ? item.tradingSymbol
                                                : item.code
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "addWatchlist",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.showColors.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          fill: "gray",
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 14 12",
                                          width: "14",
                                          height: "12",
                                          focusable: "false",
                                          preserveAspectRatio: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: { d: "M14 12l-4-6 4-6H0v12z" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "tdImg" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        item.tradingSymbol
                                          ? item.tradingSymbol[0]
                                          : item.code
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.formatTradingSymbol(
                                          item.tradingSymbol
                                            ? item.tradingSymbol
                                            : item.code
                                        ) || item.tradingSymbol
                                          ? item.tradingSymbol
                                          : item.code
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [_c("p", [_vm._v(_vm._s(item.name))])]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(item.color, function (color) {
                                return _c("div", {
                                  key: color,
                                  staticClass: "color-circle",
                                  style: { backgroundColor: color },
                                })
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "tdCountry" }, [
                              _c("p", [_vm._v(_vm._s(item.instrument))]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(item.exchange))]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(item.assetType))]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm.comingSoonToggle
                ? _c("ComingSoon")
                : _c("div", [
                    _c("p", { staticClass: "noResults" }, [
                      _vm._v("No results found"),
                    ]),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.currentSearchQuery && !_vm.comingSoonToggle
            ? _c("p", { class: _vm.alertInfoClass }, [
                _vm._v(
                  "\n      Please start searching by typing instead of scrolling.\n    "
                ),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }