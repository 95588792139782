var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageClass }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "cross", on: { click: _vm.closePage } }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 18 18",
            },
          },
          [
            _c("path", {
              attrs: {
                stroke: "currentColor",
                "stroke-width": "1.2",
                d: "m1.5 1.5 15 15m0-15-15 15",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("p", [_vm._v("Settings")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "home" }, [
      _c("div", { staticClass: "sidebar" }, [
        _c("h3", { staticClass: "account" }, [_vm._v("Account")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "itemsHolder" },
          [
            _vm._l(_vm.pages, function (page, index) {
              return _c(
                "p",
                {
                  class: _vm.getClass(index),
                  on: { click: () => _vm.handleSelect(index) },
                },
                [_vm._v("\n          " + _vm._s(page.name) + "\n        ")]
              )
            }),
            _vm._v(" "),
            _c(
              "p",
              { class: _vm.itemsClass, on: { click: _vm.logoutHandler } },
              [_vm._v("Logout")]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main" }, [
        _vm.selected === 0
          ? _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSummit.apply(null, arguments)
                  },
                },
              },
              [
                _c("h2", { class: _vm.h2Class }, [
                  _vm._v(_vm._s(_vm.pages[_vm.selected].name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "profile_pic" }, [
                  _c("h4", { class: _vm.h4Class }, [_vm._v("Profile picture")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pic" }, [
                    _vm.profilePicPreview
                      ? _c("img", {
                          attrs: {
                            src: _vm.profilePicPreview,
                            alt: "Profile Picture",
                          },
                        })
                      : _vm.doesUserHaveImage
                      ? _c("img", {
                          attrs: {
                            src: _vm.user.profile_pic,
                            alt: "Profile Picture",
                          },
                        })
                      : _c("div", { staticClass: "defaultPic" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.firstName[0]?.toUpperCase()) +
                              _vm._s(_vm.lastName[0]?.toUpperCase()) +
                              "\n            "
                          ),
                        ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "label",
                        {
                          staticClass: "upload-label",
                          attrs: { for: "profile_pic" },
                        },
                        [_vm._v("Upload")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "hidden",
                        attrs: {
                          type: "file",
                          id: "profile_pic",
                          accept: "image/*",
                        },
                        on: { change: _vm.handleProfilePicUpload },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "AcceptedType" }, [
                        _vm._v(
                          "JPG, GIF or PNG. Minimum 300x300 pixels. Maximum file size 2 MB.\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nameparent" }, [
                  _c("h4", { class: _vm.h4Class }, [_vm._v("Full name")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "nameSection" }, [
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "nameText" }, [
                        _vm._v("First Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.firstName,
                            expression: "firstName",
                          },
                        ],
                        class: _vm.nameInputClass,
                        attrs: { type: "text", placeholder: "John" },
                        domProps: { value: _vm.firstName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.firstName = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _c("p", { staticClass: "nameText" }, [
                        _vm._v("Last Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lastName,
                            expression: "lastName",
                          },
                        ],
                        class: _vm.nameInputClass,
                        attrs: { type: "text", placeholder: "Doe" },
                        domProps: { value: _vm.lastName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.lastName = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "emailparent" }, [
                  _c("h4", { class: _vm.h4Class }, [_vm._v("Email address")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "userInfo" }, [
                    _c("p", { staticClass: "username" }, [
                      _vm._v("Primary email"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      class: _vm.emailInputClass,
                      attrs: {
                        type: "text",
                        readonly: "",
                        disabled: "",
                        placeholder: "Johndoe@gmail.com",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                !_vm.doesUserHavePassword
                  ? _c("div", { staticClass: "passwordParent" }, [
                      _c("div", { staticClass: "password" }, [
                        _c("p", { staticClass: "nameText" }, [
                          _vm._v("Create Password"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "passwordField" }, [
                          (_vm.showPassword ? "text" : "password") ===
                          "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password1,
                                    expression: "password1",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Enter Password",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.password1)
                                    ? _vm._i(_vm.password1, null) > -1
                                    : _vm.password1,
                                },
                                on: {
                                  input: _vm.handlePassword1Change,
                                  change: function ($event) {
                                    var $$a = _vm.password1,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.password1 = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.password1 = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.password1 = $$c
                                    }
                                  },
                                },
                              })
                            : (_vm.showPassword ? "text" : "password") ===
                              "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password1,
                                    expression: "password1",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Enter Password",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.password1, null),
                                },
                                on: {
                                  input: _vm.handlePassword1Change,
                                  change: function ($event) {
                                    _vm.password1 = null
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password1,
                                    expression: "password1",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Enter Password",
                                  type: _vm.showPassword ? "text" : "password",
                                },
                                domProps: { value: _vm.password1 },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.password1 = $event.target.value
                                    },
                                    _vm.handlePassword1Change,
                                  ],
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "showPasswordBtn",
                              on: { click: _vm.toggleShowPassword },
                            },
                            [
                              _vm.showPassword
                                ? _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 28 28",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 28 28",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          "fill-rule": "evenodd",
                                          d: "M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z",
                                        },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "password" }, [
                        _c("p", { staticClass: "nameText" }, [
                          _vm._v("Reconfirm Password"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "passwordField" }, [
                          (_vm.showPassword2 ? "text" : "password") ===
                          "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password2,
                                    expression: "password2",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Re-enter Password",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.password2)
                                    ? _vm._i(_vm.password2, null) > -1
                                    : _vm.password2,
                                },
                                on: {
                                  input: _vm.handlePassword2Change,
                                  change: function ($event) {
                                    var $$a = _vm.password2,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.password2 = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.password2 = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.password2 = $$c
                                    }
                                  },
                                },
                              })
                            : (_vm.showPassword2 ? "text" : "password") ===
                              "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password2,
                                    expression: "password2",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Re-enter Password",
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.password2, null),
                                },
                                on: {
                                  input: _vm.handlePassword2Change,
                                  change: function ($event) {
                                    _vm.password2 = null
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password2,
                                    expression: "password2",
                                  },
                                ],
                                class: _vm.passwordInputClass,
                                attrs: {
                                  placeholder: "Re-enter Password",
                                  type: _vm.showPassword2 ? "text" : "password",
                                },
                                domProps: { value: _vm.password2 },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.password2 = $event.target.value
                                    },
                                    _vm.handlePassword2Change,
                                  ],
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "showPasswordBtn",
                              on: { click: _vm.toggleShowPassword2 },
                            },
                            [
                              _vm.showPassword2
                                ? _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 28 28",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "svg",
                                    {
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 28 28",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          "fill-rule": "evenodd",
                                          d: "M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z",
                                        },
                                      }),
                                    ]
                                  ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _c(
                      "div",
                      {
                        class: _vm.ChagnePasswordClass,
                        on: { click: _vm.openChangePasswordPage },
                      },
                      [_vm._v("\n          Change Password\n        ")]
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "phoneParent" }, [
                  _c("div", { attrs: { id: "recaptcha-container" } }),
                  _vm._v(" "),
                  _c("h4", { class: _vm.h4Class }, [_vm._v("Mobile number")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "phoneNumber" }, [
                    _c("select", {
                      attrs: { name: "country", id: "country-select" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone",
                        },
                      ],
                      class: _vm.phoneInputClass,
                      attrs: { type: "text" },
                      domProps: { value: _vm.phone },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.phone = $event.target.value
                          },
                          _vm.updatePhone,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: _vm.confirmClass,
                        on: { click: _vm.openOtpPage },
                      },
                      [_vm._v("Confirm")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "recaptcha" } }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { class: _vm.saveClass, attrs: { type: "submit" } },
                  [_vm._v("Save")]
                ),
              ]
            )
          : _c("form", [
              _c("h1", { staticClass: "commingSoon" }, [
                _vm._v("\n          Feature coming soon\n        "),
              ]),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.otpPage,
            expression: "otpPage",
          },
        ],
        class: _vm.otppageClass,
      },
      [
        _c(
          "span",
          {
            staticClass: "crossOptPage",
            on: { click: _vm.handleOptPageCross },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 18 18",
                  width: "18",
                  height: "18",
                },
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    "stroke-width": "1.2",
                    d: "m1.5 1.5 15 15m0-15-15 15",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "optPageTitle" }, [_vm._v("Confirm Number")]),
        _vm._v(" "),
        _c("div", [
          _c("p", { class: _vm.infoClass }, [
            _vm._v("We have sent four digit code on "),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.phone)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "opt" },
            _vm._l(_vm.otpArray, function (value, index) {
              return _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.otpArray[index],
                    expression: "otpArray[index]",
                  },
                ],
                key: index,
                ref: `otp${index}`,
                refInFor: true,
                staticClass: "otp-input",
                attrs: { type: "text", maxlength: "1" },
                domProps: { value: _vm.otpArray[index] },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.otpArray, index, $event.target.value)
                    },
                    function ($event) {
                      return _vm.onInput(index)
                    },
                  ],
                  keydown: function ($event) {
                    return _vm.onKeydown($event, index)
                  },
                  paste: function ($event) {
                    return _vm.onPaste($event)
                  },
                },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.saveClass, on: { click: function ($event) {} } },
            [_vm._v("Submit")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.changePasswordPage,
            expression: "changePasswordPage",
          },
        ],
        class: _vm.chagnePasswordPageClass,
      },
      [
        _c(
          "span",
          {
            staticClass: "crossOptPage",
            on: { click: _vm.handlechagnePasswordPageCross },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 18 18",
                  width: "18",
                  height: "18",
                },
              },
              [
                _c("path", {
                  attrs: {
                    stroke: "currentColor",
                    "stroke-width": "1.2",
                    d: "m1.5 1.5 15 15m0-15-15 15",
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "optPageTitle" }, [
          _vm._v("Change password"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("p", { class: _vm.infoClass }, [
            _vm._v(
              "Enter your current password and new password to change your password "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "old" }, [
            _c("p", { staticClass: "nameText" }, [_vm._v("Old Password")]),
            _vm._v(" "),
            _c("div", { staticClass: "ChangepasswordField" }, [
              (_vm.showPassword3 ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oldPassword,
                        expression: "oldPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your current password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.oldPassword)
                        ? _vm._i(_vm.oldPassword, null) > -1
                        : _vm.oldPassword,
                    },
                    on: {
                      input: _vm.handleOldPasswordChange,
                      change: function ($event) {
                        var $$a = _vm.oldPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.oldPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.oldPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.oldPassword = $$c
                        }
                      },
                    },
                  })
                : (_vm.showPassword3 ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oldPassword,
                        expression: "oldPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your current password",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.oldPassword, null) },
                    on: {
                      input: _vm.handleOldPasswordChange,
                      change: function ($event) {
                        _vm.oldPassword = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.oldPassword,
                        expression: "oldPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your current password",
                      type: _vm.showPassword3 ? "text" : "password",
                    },
                    domProps: { value: _vm.oldPassword },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.oldPassword = $event.target.value
                        },
                        _vm.handleOldPasswordChange,
                      ],
                    },
                  }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "showPasswordBtn",
                  on: { click: _vm.toggleShowPassword3 },
                },
                [
                  _vm.showPassword3
                    ? _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 28 28",
                            width: "28",
                            height: "28",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 28 28",
                            width: "28",
                            height: "28",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              "fill-rule": "evenodd",
                              d: "M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z",
                            },
                          }),
                        ]
                      ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "new" }, [
            _c("p", { staticClass: "nameText" }, [_vm._v("New Password")]),
            _vm._v(" "),
            _c("div", { staticClass: "ChangepasswordField" }, [
              (_vm.showPassword4 ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your new password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.newPassword)
                        ? _vm._i(_vm.newPassword, null) > -1
                        : _vm.newPassword,
                    },
                    on: {
                      input: _vm.handleNewPasswordChange,
                      change: function ($event) {
                        var $$a = _vm.newPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.newPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.newPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.newPassword = $$c
                        }
                      },
                    },
                  })
                : (_vm.showPassword4 ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your new password",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.newPassword, null) },
                    on: {
                      input: _vm.handleNewPasswordChange,
                      change: function ($event) {
                        _vm.newPassword = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword",
                      },
                    ],
                    class: _vm.passwordChangeClass,
                    attrs: {
                      placeholder: "Enter your new password",
                      type: _vm.showPassword4 ? "text" : "password",
                    },
                    domProps: { value: _vm.newPassword },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.newPassword = $event.target.value
                        },
                        _vm.handleNewPasswordChange,
                      ],
                    },
                  }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "showPasswordBtn",
                  on: { click: _vm.toggleShowPassword4 },
                },
                [
                  _vm.showPassword4
                    ? _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 28 28",
                            width: "28",
                            height: "28",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 28 28",
                            width: "28",
                            height: "28",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              "fill-rule": "evenodd",
                              d: "M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z",
                            },
                          }),
                        ]
                      ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.ChagnePasswordClass,
              on: { click: _vm.handleChangePassword },
            },
            [_vm._v("\n        Change Password\n      ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }