var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "button",
      { staticClass: "faq-button", on: { click: _vm.handleShowPopup } },
      [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 320 512",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "tooltip" }, [_vm._v("FAQ")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPopup,
            expression: "showPopup",
          },
        ],
        staticClass: "popup",
      },
      [
        _c("div", { ref: "popup", staticClass: "popup-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "group group2" }, [
              _c("div", { staticClass: "left" }, [
                _c("p", { staticClass: "text1" }, [
                  _vm._v("Have a recommendation call"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text3" }, [
                  _vm._v(
                    "\n              We’d be happy to implement your recommendation, and add features\n              you need. Help us improve with your recommendations by jumping\n              on a call with us.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn", on: { click: _vm.handleCall } },
                  [
                    _vm._v(
                      "\n              Setup Recommendation call\n            "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "group group3", on: { click: _vm.OpenTallyForm } },
              [_vm._m(3), _vm._v(" "), _vm._m(4)]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "titleText" }, [
      _vm._v("\n        Hi there "),
      _c("br"),
      _vm._v("\n        How can we help?\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "group group1" }, [
      _c("div", { staticClass: "left" }, [
        _c("p", { staticClass: "text1" }, [_vm._v("Send us a message")]),
        _vm._v(" "),
        _c("p", { staticClass: "text2" }, [
          _vm._v("We usually reply in a few minutes"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _c("img", {
          attrs: {
            src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722467754/lt8bgmjxpd3377rnyfx2.png",
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "group group1" }, [
      _c("div", { staticClass: "left" }, [
        _c("p", { staticClass: "text1" }, [_vm._v("Tradilent FAQ.")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _c("img", {
          attrs: {
            src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722468015/fst7ccwwuxw3qvtmpkaq.png",
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("p", { staticClass: "text1" }, [_vm._v("Leave your feedback")]),
      _vm._v(" "),
      _c("p", { staticClass: "text2" }, [
        _vm._v(
          "\n              We believe that you can help us improve with your feedback. Your\n              opinion matters.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("img", {
        attrs: {
          src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722468821/rlginjw2mvxcsvgh1zbv.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }