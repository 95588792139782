<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/order-in-components -->
<!-- eslint-disable vue/order-in-components -->
<template>
  <span>
    <tradilent-vue v-if="dataLoaded" :data="chart" :width="this.width" :height="this.height"
      :chart-config="{ MIN_ZOOM: 1, DEFAULT_LEN: 100 }" ref="tvjs" :toolbar="true" :index-based="index_based"
      :overlays="overlays" :color-back="colors.colorBack" :color-grid="colors.colorGrid" :color-text="colors.colorText"
      :extensions="ext" :legend-buttons="['display', 'settings', 'up', 'down', 'add', 'remove']" :x-settings="xsett">
    </tradilent-vue>
    <span class="gc-mode">
      <input type="checkbox" v-model="index_based" />
      <label>Index Based</label>
    </span>

    <EntryLoader v-if="loading" />
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TradilentVue from "./TradilentVue.vue";
import Overlays from "tvjs-overlays";
import avaliableTF from "../data/timeframes.json";
import XP from "tvjs-xp";
import DataCube from "./helpers/datacube.js";
import authService from "./appwrite/index.js";
import EntryLoader from "./components/EntryLoader.vue";
import { io } from "socket.io-client";
import { SERVER_URL } from "./constant.js";
import HandleGlobalError from "./clearErrorOnConsole.js";
import {
  calculateCustomTF_for_seconds,
  calculateCustomTF_for_minutes,
  calculateCustomTF_for_hours,
  calculateCustomTF_for_days,
  extractNumericTimeframe,
  getCorrectTFForCustom,
} from "./services/CustomTFHandler.js";

// timestamp converter
import {
  // fyers
  get_100_days_previous_date,

  // wazirx
  get_100_days_previous_timestamp_wazirx,

  // Trademade
  get_previous_date_trademade,

  // Binance
  get_previous_date_binance,
} from "./helpers/timestamp_converter.js";

import { createDataProvider } from "./services/dataProviderFactory.js";

// const moment = require('moment');
import moment from "moment";
import { previousDay } from "date-fns";
import Toast from "./components/Toast.vue";

export default {
  name: "App",
  components: {
    TradilentVue,
    EntryLoader,
  },
  props: ["night"],
  data() {
    return {
      currentTradeCrypto: [],
      candleEndTime: [],

      // ***
      dataProvider: null,
      loading: true,
      // isLoading : true,
      dataLoaded: false,
      isInitializing: false,
      chart: {},
      width: window.innerWidth,
      height: window.innerHeight,
      index_based: true,
      log_scale: true,
      xsett: {
        "grid-resize": { min_height: 30 },
      },
      overlays: Object.values(Overlays),
      ext: Object.values(XP),
      socket: null,
      fyersData: null,
      currentSymbol: "",
      timeframe: "",
      assetType: "",
      socket: {},

      CustomTFMode: false,
      CustomTFSelected: "",
      CustomTFInterval: null,
    };
  },
  created() {
    // make socket connect to our backend
    this.socket = io(`${SERVER_URL}`);
    this.$store.commit("UpdateSocketConnection", this.socket);
    this.socket.on("checking", (msg) => { });
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.initializeApp();
  },
  methods: {
    ...mapActions(["initializeAppData"]),

    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },

    async initializeApp() {
      if (this.isInitializing) return;
      this.isInitializing = true;

      try {
        let {
          symbol,
          timeFrame,
          assetType,
          CustomTFMode,
          CustomTFSelected,
          CustomTFInterval,
        } = await this.$store.dispatch("initializeAppData");
        this.currentSymbol = symbol;
        // console.log("currently ", this.currentSymbol);
        const allTFs = avaliableTF[assetType];
        // console.log("currently ", allTFs);
        this.assetType = assetType;
        if (timeFrame >= allTFs.length) timeFrame = 0;
        let selectedTF = allTFs[timeFrame];
        this.timeframe = selectedTF;
        // await this.$nextTick(() => {
        //   this.CustomTFMode = CustomTFMode;
        //   this.CustomTFSelected = CustomTFSelected;
        //   this.CustomTFInterval = CustomTFInterval;
        //   this.timeframe = CustomTFMode
        //     ? getCorrectTFForCustom(
        //         CustomTFSelected,
        //         CustomTFInterval,
        //         assetType
        //       )
        //     : selectedTF;
        // });
        if (this.timeframe.value) this.timeframe = this.timeframe.value;
        // console.log("tf is ", this.timeframe);
        // Ensure that timeframe is set before calling loadInitialData
        // this.timeframe=
        if (!this.timeframe) {
          throw new Error("Timeframe is not set properly.");
        }

        await this.loadInitialData(this.timeframe);
      } catch (error) {
        console.error("Initialization error:", error);
      } finally {
        this.isInitializing = false;
      }
    },

    async loadInitialData() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        if (!this.timeframe) {
          throw new Error("Timeframe is not set properly.");
        }

        // this brings the defined functions according to stock | crypto
        this.dataProvider = createDataProvider(this.assetType);

        let now = new Date();
        let previousDate;

        if (this.assetType === "stock") {
          now.setDate(now.getDate());
          now = now.toISOString().split("T")[0]; // gives present date in this format yyyy-mm-dd
          previousDate = get_100_days_previous_date(now);
        } else if (this.assetType === "crypto") {
          now = moment.now().valueOf(); // In milliseconds
          previousDate = get_previous_date_binance(now, this.timeframe); // In milliseconds
        }

        // main war starts here
        const data = await this.load_chunk([previousDate, now]);
        // console.log("data is", data);
        this.chart = new DataCube(
          {
            ohlcv: data["chart.data"],
            onchart: [
              {
                name: "",
                type: "",
                data: [],
                settings: {},
              },
            ],
          },
          { aggregation: 100, auto_scroll: true }
        );

        this.loading = false;
        this.dataLoaded = true;

        this.chart.onrange(this.load_old_chunk);
        this.dataProvider.connectWebSocket(
          this.currentSymbol,
          this.on_socket_data
        );
        await this.$nextTick(() => {
          this.$refs.tvjs.resetChart();
        });
        window.dc = this.chart; // Debug
        window.tv = this.$refs.tvjs; // Debug
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        this.isLoading = false;
        HandleGlobalError();
      }
    },

    async load_chunk(range) {
      let [t1, t2] = range;

      if (!this.timeframe) {
        throw new Error(
          "Timeframe is not set in load_chunk = with interval",
          this.CustomTFInterval
        );
      }

      if (this.CustomTFMode) {
        let resultantTimeFrame = getCorrectTFForCustom(
          this.CustomTFSelected,
          this.CustomTFInterval,
          this.assetType
        );

        // make sure to update the timeframe
        await this.$nextTick(() => {
          this.timeframe = resultantTimeFrame;
        });
      }

      // Call the Fyers historical api
      // let histo = await this.dataProvider.fetchHistoricalData(
      //   this.currentSymbol,
      //   this.timeframe,
      //   1,
      //   t1,
      //   t2
      // );
      // console.log("ts if ", this.timeframe);
      let histo = await this.dataProvider.fetchHistoricalData(
        this.currentSymbol,
        this.timeframe,
        1,
        t1,
        t2
      );
      return this.format(this.parse_binance(histo));
    },
    // Transforms the received data into charts acceptable format.
    parse_binance(data) {
      if (!Array.isArray(data)) return [];

      if (this.CustomTFMode) {
        let extractedTF = extractNumericTimeframe(this.timeframe); // 10S
        const chunkSize = Math.floor(this.CustomTFInterval / extractedTF); // 20 / 10 = 2

        let mergedCandles = [];

        for (let i = 0; i < data.length; i += chunkSize) {
          // Get the chunk of candles
          const candleChunk = data.slice(i, i + chunkSize);

          // Only merge if we have a complete chunk of candles
          if (candleChunk.length === chunkSize) {
            let mergedCandle = [];

            // Timestamp: take the timestamp of the first candle in the chunk
            mergedCandle[0] = candleChunk[0][0] * 1000;

            // Open value: take the open value of the first candle in the chunk
            mergedCandle[1] = parseFloat(candleChunk[0][1]);

            // High value: take the maximum of the high values in the chunk
            mergedCandle[2] = parseFloat(
              Math.max(...candleChunk.map((candle) => candle[2]))
            );

            // Low value: take the minimum of the low values in the chunk
            mergedCandle[3] = parseFloat(
              Math.min(...candleChunk.map((candle) => candle[3]))
            );

            // Close value: take the close value of the last candle in the chunk
            mergedCandle[4] = parseFloat(candleChunk[chunkSize - 1][4]);

            // Volume: sum the volumes of all candles in the chunk
            mergedCandle[5] = candleChunk.reduce(
              (totalVolume, candle) => totalVolume + candle[5],
              0
            );

            // Add the merged candle to the array of merged candles
            mergedCandles.push(mergedCandle);
          }
        }
        return mergedCandles;
      }

      // if the user isn't selected the custom timeframe, then run normally.
      else {
        return data.map((x) => {
          for (var i = 0; i < x.length; i++) {
            if (i === 0) {
              x[i] = x[0] * 1000;
            } else {
              x[i] = parseFloat(x[i]);
            }
          }
          return x.slice(0, 6);
        });
      }
    },

    format(data) {
      return {
        "chart.data": data,
      };
    },

    // the continous data from socket / Data Hub will be handled here.
    on_socket_data(trade) {
      if (!trade) return;

      if (this.assetType === "stock") {
        if (trade.type === "sf" && trade.symbol === this.currentSymbol) {
          if(trade){
            console.log(trade.ltp + " " + trade.vol_traded_today);
            // For stock, update the chart with price and volume
            this.chart.update({
              price: parseFloat(trade.ltp), // Trade price
              volume: parseFloat(trade.vol_traded_today), // Trade amount
            });
          }
          // Commit the current price to the store
          this.$store.commit("setCurrentPrice", parseFloat(trade.ltp));
        }
      } else if (this.assetType === "crypto") {
        // console.log(trade)
        if (trade.s.toLowerCase() === this.currentSymbol.toLowerCase()) {
          const tradePrice = parseFloat(trade.p);  // Trade price for crypto
          const tradeVolume = parseFloat(trade.q); // Trade quantity/volume

          // Update the chart with price and volume, just like for stocks
          if(tradePrice > 0 && tradeVolume > 0){
            console.log("sankalp :" + tradePrice + " " + tradeVolume)
            this.chart.update({
              price: tradePrice,
              volume: tradeVolume,
            });
          }

          // Commit the current price to the store
          this.$store.commit("setCurrentPrice", tradePrice);
        }
      }
    },
    // this gets the more data, when user scrolls to left and canles are completed, then this fetches more data.
    async load_old_chunk() {
      let topDate = this.chart.get("chart.data");
      let t1 = this.dataProvider.previousDate1(
        topDate[0][0][0],
        this.timeframe
      ); // sending the 1st array timestamp
      let t2 = this.dataProvider.previousDate100(t1, this.timeframe);
      console.log("t1", t1);
      console.log("t2", t2);

      this.load_chunk([t2, t1])
        .then((oldData) => {
          // console.log("loading ", oldData["chart.data"].length);
          const dataTillNow = this.chart.data;
          // console.log("prev", dataTillNow);
          // console.log("old", oldData);
          this.chart.merge("chart.data", oldData["chart.data"], dataTillNow);
          // console.log("now", this.chart.data);
          this.chart.onrange(this.load_old_chunk);
        })
        .catch((error) => {
          console.error("Error in loading more data ", error);
        });
    },

    // this function get's called when user selects new symbol / exchange / stock
    handleSymbolChange(newSymbol, newAssetType, newTimeFrame) {
      if (newSymbol === this.currentSymbol) return;
      // console.log("new is ", newSymbol);
      // breaking the connection of the socket...
      // Disconnect existing socket
      if (this.dataProvider) {
        this.dataProvider.disconnectWebSocket();
      }
      this.currentSymbol = newSymbol;
      this.assetType = newAssetType;

      // change the timeframe according to asset-Type
      const allTFs = avaliableTF[newAssetType];
      this.timeframe = allTFs[newTimeFrame].value;

      this.loading = true;
      this.dataLoaded = false;
      // console.log(newAssetType);
      this.dataProvider = createDataProvider(newAssetType);
      this.loadInitialData();
    },

    // this function get's called when user selects new timeframe
    async handleTimeframeChange(newTimeFrame) {
      if (newTimeFrame === this.timeframe) return;
      await this.$nextTick(() => {
        this.timeframe = newTimeFrame;
      });
      this.loading = true;
      this.dataLoaded = false;
      this.loadInitialData();
    },

    // call when there is a change in custom timeframe selection info
    handleCustomTFChange(
      newCustomMode,
      newCustomTFSelected,
      newCustomTFInterval
    ) {
      if (
        newCustomMode === this.CustomTFMode &&
        newCustomTFSelected === this.CustomTFSelected &&
        newCustomTFInterval === this.CustomTFInterval
      )
        return;

      this.CustomTFMode = newCustomMode;
      this.CustomTFSelected = newCustomTFSelected;
      this.CustomTFInterval = newCustomTFInterval;
      this.timeframe = calculateCustomTF_for_seconds(
        newCustomTFInterval,
        this.assetType
      );

      this.loading = true;
      this.dataLoaded = false;
      this.loadInitialData();
    },
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPrice(newVal, oldVal) {
      // Fetch alerts
      let alerts = JSON.parse(localStorage.getItem("alerts"));
      if (!alerts) return;

      let new_alerts = [];

      for (let i = 0; i < alerts.length; i++) {
        let push = true;
        let selectedSymbol = this.$store.getters.getSelectedSymbol;

        // Handle "Crossing"
        if (
          alerts[i].status == "Crossing" &&
          alerts[i].symbol == selectedSymbol
        ) {
          if (
            (oldVal < this.currentPrice && newVal > this.currentPrice) ||
            (oldVal > this.currentPrice && newVal < this.currentPrice)
          ) {
            this.$toast({
              component: Toast,
              props: {
                title: `Alert for ${alerts[i].symbol}`,
                message: alerts[i].message,
              },
            });

            // Clear alert if trigger is "once"
            if (alerts[i].trigger == "once") {
              push = false;
            }
          }
        }

        // Handle "Crossing up"
        else if (
          alerts[i].status == "Crossing up" &&
          alerts[i].symbol == selectedSymbol
        ) {
          if (oldVal < this.currentPrice && newVal >= this.currentPrice) {
            this.$toast({
              component: Toast,
              props: {
                title: `Crossing Up Alert for ${alerts[i].symbol}`,
                message: alerts[i].message,
              },
            });

            if (alerts[i].trigger == "once") {
              push = false;
            }
          }
        }

        // Handle "Crossing down"
        else if (
          alerts[i].status == "Crossing down" &&
          alerts[i].symbol == selectedSymbol
        ) {
          if (oldVal > this.currentPrice && newVal <= this.currentPrice) {
            this.$toast({
              component: Toast,
              props: {
                title: `Crossing Down Alert for ${alerts[i].symbol}`,
                message: alerts[i].message,
              },
            });

            if (alerts[i].trigger == "once") {
              push = false;
            }
          }
        }

        // Handle "Less than"
        else if (
          alerts[i].status == "Less than" &&
          alerts[i].symbol == selectedSymbol
        ) {
          if (newVal < this.currentPrice) {
            this.$toast({
              component: Toast,
              props: {
                title: `Less Than Alert for ${alerts[i].symbol}`,
                message: alerts[i].message,
              },
            });

            if (alerts[i].trigger == "once") {
              push = false;
            }
          }
        }

        // Handle "Greater than"
        else if (
          alerts[i].status == "Greater than" &&
          alerts[i].symbol == selectedSymbol
        ) {
          if (newVal > this.currentPrice) {
            this.$toast({
              component: Toast,
              props: {
                title: `Greater Than Alert for ${alerts[i].symbol}`,
                message: alerts[i].message,
              },
            });

            if (alerts[i].trigger == "once") {
              push = false;
            }
          }
        }

        // Keep the alert if it should remain active
        if (push) new_alerts.push(alerts[i]);
      }

      // Update alerts in localStorage
      localStorage.setItem("alerts", JSON.stringify(new_alerts));
    },

    log_scale(value) {
      if (this.chart.data.chart) {
        this.$set(this.chart.data.chart, "grid", {
          logScale: value,
        });
      }
    },
    selectedFullSymbol([newSymbol, newAssetType, newTimeFrame]) {
      this.handleSymbolChange(newSymbol, newAssetType, newTimeFrame);
    },
    getSelectedTimeFrame(newTimeFrame) {
      this.handleTimeframeChange(newTimeFrame);
    },
    currentSymbol(newSymbol) {
      this.$store.commit("updateSelectedSymbol", newSymbol);
    },
    selectedCustomTF([
      newCustomMode,
      newCustomTFSelected,
      newCustomTFInterval,
    ]) {
      this.handleCustomTFChange(
        newCustomMode,
        newCustomTFSelected,
        newCustomTFInterval
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    if (this.dataProvider) {
      this.dataProvider.disconnectWebSocket();
    }
  },
  computed: {
    currentPrice() {
      return this.$store.getters.getCurrentPrice();
    },

    ...mapGetters([
      "getSelectedSymbol",
      "getTimeFrame",
      "getAssetType",
      "getIsCustomTFMode",
      "getCustomTFSelected",
      "getCustomTFInterval",
    ]),
    selectedFullSymbol() {
      return [this.getSelectedSymbol, this.getAssetType, this.getTimeFrame];
    },
    selectedCustomTF() {
      return [
        this.getIsCustomTFMode,
        this.getCustomTFSelected,
        this.getCustomTFInterval,
      ];
    },
    getSelectedTimeFrame() {
      let assetName = this.getAssetType;
      const allTFs = avaliableTF[assetName];
      let selectedTF = allTFs[this.getTimeFrame].value;
      if (selectedTF === "Custom Interval") {
        selectedTF = getCorrectTFForCustom(
          this.getCustomTFSelected,
          this.getCustomTFInterval,
          this.getAssetType
        );
      }
      return selectedTF.toString();
    },
    seletectedAssetType() {
      return this.getAssetType;
    },
    colors() {
      return this.getTheme
        ? {
          colorBack: "#1e1e1e", // Dark background for better contrast
          colorGrid: "#333", // Slightly lighter grid lines
          colorText: "#ccc", // Light text color for readability
        }
        : {
          colorBack: "#fff",
          colorGrid: "#eee",
          colorText: "#333",
        };
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
    errorHandler(error, vm, info) {
      // console.clear();
    },
  },
};
</script>

<style>
html,
body {
  background-color: #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.gc-mode {
  display: none;
  position: absolute;
  top: 50px;
  right: 70px;
  color: #888;
  font: 11px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/* styles for toast box */
.Vue-Toastification__toast--default.my-custom-toast-class {
  background-color: gray;
  color: white;
  height: 10px;
}

.Vue-Toastification__toast-body.custom-class-1 {
  font-size: 12px;
}

.Vue-Toastification__toast-component-body.custom-class-2 {
  width: 100%;
}
</style>
