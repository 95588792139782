// rest API
import { fetchHistoricalDataFyers } from "../api/fyers/historical.js";
import { fetchHistoricalDataWazirx } from "../api/wazirx/histo/historical.js";
import { fetchHistoricalDataTrademade } from "../api/trademade/historical/historical.js";
import { fetchHistoricalDataBinance } from "../api/binance/historical/historical.js";
// For real-time data connection
import { io } from "socket.io-client"; // used for real-time
import StreamWazirx from "../api/wazirx/streamer/StreamWazirx.js";
import streamTrademade from "../api/trademade/streamer/streamTrademade.js";
import moment from "moment";
// timestamp converter
import {
  // fyers
  get_100_days_previous_date,
  get_1_day_back_date,

  // wazirx
  get_100_days_previous_timestamp_wazirx,
  get_1_day_back_timestamp_wazirx,

  // Trademade
  get_single_frame_back_trademade,
  get_previous_data_date,
} from "../helpers/timestamp_converter.js";
import { all } from "axios";

// Helper function to group data by week
function groupByWeek(candles) {
  const weeklyData = {};

  candles.forEach((candle) => {
    if (candle.length < 6) return; // Ensure the data is complete

    const date = new Date(candle[0] * 1000); // Convert timestamp to Date
    const { year, week } = getISOWeek(date); // Get ISO year and week number

    const weekKey = `${year}-${week}`; // Combine year and week number as key

    if (!weeklyData[weekKey]) {
      // Initialize the week's data
      weeklyData[weekKey] = [
        candle[0], // Start of the week (first timestamp)
        candle[1], // First 'open' value
        candle[2], // Highest 'high' value
        candle[3], // Lowest 'low' value
        candle[4], // Last 'close' value
        candle[5], // Initialize the volume with the first value
      ];
    } else {
      // Update the high, low, close values, and accumulate volume for the week
      const weekData = weeklyData[weekKey];
      weekData[2] = Math.max(weekData[2], candle[2]); // Update 'high'
      weekData[3] = Math.min(weekData[3], candle[3]); // Update 'low'
      weekData[4] = candle[4]; // Update 'close'
      weekData[5] += candle[5]; // Add volume
    }
  });

  // Convert the weekly data object into an array
  return Object.values(weeklyData);
}

// to adjust x axis according to week
function getISOWeek(date) {
  // Copy date so don't modify original
  const target = new Date(date.valueOf());

  // ISO week date weeks start on Monday
  const dayNr = (date.getDay() + 6) % 7;

  // Set to nearest Thursday: current date + 4 - current day number
  target.setDate(target.getDate() - dayNr + 3);

  // January 4 is always in week 1
  const jan4 = new Date(target.getFullYear(), 0, 4);

  // Calculate full weeks to nearest Thursday
  const weekNumber = Math.ceil(((target - jan4) / 86400000 + 1) / 7);

  return {
    year: target.getFullYear(),
    week: weekNumber,
  };
}

// Helper function to group data by months
function groupByMonth(candles) {
  const monthlyData = {};

  candles.forEach((candle) => {
    if (candle.length < 6) return; // Ensure the data is complete

    const date = new Date(candle[0] * 1000); // Convert timestamp to Date
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`; // Get the month and year as a string

    if (!monthlyData[monthYear]) {
      // Initialize the month's data
      monthlyData[monthYear] = [
        candle[0], // Start of the month (first timestamp)
        candle[1], // First 'open' value
        candle[2], // Highest 'high' value
        candle[3], // Lowest 'low' value
        candle[4], // Last 'close' value
        candle[5], // Initialize the volume with the first value
      ];
    } else {
      // Update the high and low values for the month
      monthlyData[monthYear][2] = Math.max(
        monthlyData[monthYear][2],
        candle[2]
      ); // Update 'high'
      monthlyData[monthYear][3] = Math.min(
        monthlyData[monthYear][3],
        candle[3]
      ); // Update 'low'
      monthlyData[monthYear][4] = candle[4]; // Update 'close'
      monthlyData[monthYear][5] += candle[5]; // Add volume
    }
  });

  // Convert the monthly data object into an array
  return Object.values(monthlyData);
}

// Helper function to group data by six months
function groupBySixMonths(candles) {
  const sixMonthData = {};

  candles.forEach((candle) => {
    if (!Array.isArray(candle) || candle.length < 6) {
      console.error("Invalid candle data:", candle);
      return; // Skip if data is invalid
    }

    const date = new Date(candle[0] * 1000); // Convert timestamp to Date
    const { year, period } = getSixMonthPeriod(date); // Get year and six-month period

    const periodKey = `${year}-H${period}`; // Combine year and period as key

    if (!sixMonthData[periodKey]) {
      // Initialize the six-month data array with open, high, low, close, volume, and last timestamp
      sixMonthData[periodKey] = [
        candle[0], // First timestamp
        candle[1], // Open
        candle[2], // High
        candle[3], // Low
        candle[4], // Close
        candle[5], // Volume
        candle[0], // Last timestamp to track the latest close
      ];
    } else {
      const periodData = sixMonthData[periodKey];

      // Update the high, low, and volume for the six-month period
      periodData[2] = Math.max(periodData[2], candle[2]); // Update 'high'
      periodData[3] = Math.min(periodData[3], candle[3]); // Update 'low'
      periodData[5] += candle[5]; // Add volume

      // If this candle has a later timestamp, update the close value
      if (candle[0] > periodData[6]) {
        periodData[4] = candle[4]; // Update close to the latest
        periodData[6] = candle[0]; // Update the latest timestamp
      }
    }
  });

  // Convert the six-month data object into an array, removing the last timestamp (index 6)
  return Object.values(sixMonthData).map((data) => data.slice(0, 6));
}

// Helper function to determine the six-month period (H1 for Jan-Jun, H2 for Jul-Dec)
function getSixMonthPeriod(date) {
  const year = date.getFullYear();
  const month = date.getMonth(); // 0-indexed (0 = January, 11 = December)

  // Determine the six-month period
  const period = month < 6 ? 1 : 2;

  return {
    year: year,
    period: period,
  };
}

// Helper function to group data by year
function groupByYear(candles) {
  const yearlyData = {};

  candles.forEach((candle) => {
    if (candle.length < 6) return; // Ensure the data is complete

    const date = new Date(candle[0] * 1000); // Convert timestamp to Date
    const year = date.getFullYear(); // Get the year

    const yearKey = `${year}`; // Use year as the key

    if (!yearlyData[yearKey]) {
      // Initialize the year's data
      yearlyData[yearKey] = [
        candle[0], // Start of the year (first timestamp)
        candle[1], // First 'open' value
        candle[2], // Highest 'high' value
        candle[3], // Lowest 'low' value
        candle[4], // Last 'close' value
        candle[5], // Initialize the volume with the first value
      ];
    } else {
      // Update the high, low, close values, and accumulate volume for the year
      const yearData = yearlyData[yearKey];
      yearData[2] = Math.max(yearData[2], candle[2]); // Update 'high'
      yearData[3] = Math.min(yearData[3], candle[3]); // Update 'low'
      yearData[4] = candle[4]; // Update 'close'
      yearData[5] += candle[5]; // Add volume
    }
  });

  // Convert the yearly data object into an array
  return Object.values(yearlyData);
}

function getDateNMonthsAgo(startDate, months = 3) {
  const date = new Date(startDate); // Convert the startDate to a Date object
  date.setMonth(date.getMonth() - months); // Subtract N months
  return date.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
}

// All stocks Data Provider
const createStockDataProvider = () => {
  let socket = null,
    fyersData = null;

  return {
    fetchHistoricalData: async (
      symbol,
      timeframe,
      dateFormat,
      startDate,
      endDate
    ) => {
      if (timeframe === "1w") {
        // 1 week
        const demoTf = "1D";
        const allData = await fetchHistoricalDataFyers(
          symbol,
          demoTf,
          dateFormat,
          startDate,
          endDate
        );

        const weeklyData = groupByWeek(allData.candles);
        return weeklyData;
      } else if (timeframe === "1M") {
        // 1 month
        const demoTf = "1D";
        const allData = await fetchHistoricalDataFyers(
          symbol,
          demoTf,
          dateFormat,
          startDate,
          endDate
        );

        const MonthlyData = groupByMonth(allData.candles);
        return MonthlyData;
      } else if (timeframe === "6M") {
        // 6 months
        const demoTf = "1D";
        const allData = await fetchHistoricalDataFyers(
          symbol,
          demoTf,
          dateFormat,
          startDate,
          endDate
        );

        const SixMonthData = groupBySixMonths(allData.candles);
        return SixMonthData;
      } else if (timeframe === "1Y") {
        // year
        // Calculate the demo start date
        const demoStart = getDateNMonthsAgo(startDate);
        const demoTf = "1D";

        console.log("Start Date: ", demoStart);
        console.log("End Date: ", endDate);

        // Fetch historical data
        const allData = await fetchHistoricalDataFyers(
          symbol,
          demoTf,
          dateFormat,
          demoStart,
          endDate
        );

        // Log the fetched data to ensure it's correct
        console.log("Fetched Candles Data: ", allData.candles);

        // Process the data to group by year
        const yearlyData = groupByYear(allData.candles);

        // Log the processed data
        console.log("Yearly Data: ", yearlyData);

        return yearlyData;
      } else {
        const allData = await fetchHistoricalDataFyers(
          symbol,
          timeframe,
          dateFormat,
          startDate,
          endDate
        );
        console.log(allData.candles);
        return allData.candles;
      }
    },

    previousDate100: (timestamp) => {
      return get_100_days_previous_date(timestamp);
    },

    previousDate1: (timestamp) => {
      return get_1_day_back_date(timestamp);
    },

    connectWebSocket: (symbol, onData) => {
      socket = io(process.env.VUE_APP_DATA_HUB_URL, {
        transports: ["websocket"],
        upgrade: false,
      });

      socket.on("connect", () => {
        socket.emit("subscribe", [symbol]); // Send subscription request
      });

      socket.on("fyersData", onData);
    },

    disconnectWebSocket: () => {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    },
  };
};

// 🪙  Crypto Data Provider Trademade
const createCryptoDataProviderTrademade = () => {
  let stream = null;

  return {
    fetchHistoricalData: async (
      symbol,
      timeframe,
      dateFormat,
      startDate,
      endDate
    ) => {
      let interval = timeframe.includes("D")
        ? "daily"
        : timeframe.includes("H")
        ? "hourly"
        : "minute";

      let period = timeframe.substring(0, timeframe.length - 1);

      let data = await fetchHistoricalDataTrademade(
        symbol,
        interval,
        startDate,
        endDate,
        period
      );

      // parse this data
      let parsedData = data.quotes.map((x) => {
        let date = new Date(x.date).getTime() / 1000; // converts to seconds
        let open = x.open;
        let high = x.high;
        let low = x.low;
        let close = x.close;

        return [date, open, high, low, close];
      });

      if (timeframe === "1w") {
        const weeklyData = groupByWeek(parsedData);
        return weeklyData;
      }

      return parsedData;
    },

    previousDate100: (timestamp, timeframe) => {
      return get_previous_data_date(timestamp, timeframe);
    },

    previousDate1: (timestamp, timeframe) => {
      return get_single_frame_back_trademade(timestamp, timeframe);
    },

    connectWebSocket: (symbol, onData) => {
      stream = streamTrademade(symbol);
      stream.onmessage = (event) => {
        console.log("event is ", event);
      };
      stream.oncrypto = (data) => {
        if (data.s === symbol) {
          console.log(data);
          onData(data);
        }
      };
    },

    disconnectWebSocket: () => {
      console.log("ds 1");
      if (stream) {
        console.log(stream.disconnect);
        stream.disconnect();
        stream = null;
      }
    },
  };
};
const createCryptoDataProviderBinance = () => {
  let stream = null;

  return {
    // Fetch Historical Data from Binance API
    fetchHistoricalData: async (symbol, timeframe, dateFormat, start, end) => {
      if (start.valueOf) start = start.valueOf();
      if (end.valueOf) end = end.valueOf();
      symbol = symbol.toUpperCase();
      return await fetchHistoricalDataBinance(
        symbol,
        timeframe,
        1000,
        start,
        end
      );
    },

    // Utility for getting the previous date based on the timeframe
    previousDate100: (timestamp, timeframe) => {
      const number = parseInt(timeframe.slice(0, -1), 10); // Extract the number part of the timeframe
      const unit = timeframe.slice(-1); // Extract the unit part of the timeframe (m, h, d, w, M)

      let date = moment(timestamp).clone().utc(); // Convert the timestamp to a Moment.js object in UTC

      // Subtract 100 intervals
      switch (unit) {
        case "m": // minutes
          date = date.subtract(number * 1000, "minutes");
          break;
        case "h": // hours
          date = date.subtract(number * 1000, "hours");
          break;
        case "d": // days
          date = date.subtract(number * 1000, "days");
          break;
        case "w": // weeks
          date = date.subtract(number * 1000, "weeks");
          break;
        case "M": // months
          date = date.subtract(number * 100, "months");
          break;
        default:
          throw new Error("Invalid timeframe format");
      }

      return date.valueOf();
    },

    // Utility for getting single frame back based on the timeframe
    previousDate1: (timestamp, timeframe) => {
      const number = parseInt(timeframe.slice(0, -1), 10); // Extract the number part of the timeframe
      const unit = timeframe.slice(-1); // Extract the unit part of the timeframe (m, h, d, w, M)

      let date = moment(timestamp).clone().utc(); // Convert the timestamp to a Moment.js object in UTC

      // Subtract 1 interval
      switch (unit) {
        case "m": // minutes
          date = date.subtract(number, "minutes");
          break;
        case "h": // hours
          date = date.subtract(number, "hours");
          break;
        case "d": // days
          date = date.subtract(number, "days");
          break;
        case "w": // weeks
          date = date.subtract(number, "weeks");
          break;
        case "M": // months
          date = date.subtract(number, "months");
          break;
        default:
          throw new Error("Invalid timeframe format");
      }

      return date.valueOf();
    },
    // Connect to Binance WebSocket for real-time data
    connectWebSocket: (symbol, onData) => {
      const reconnectInterval = 5000;
      let closed = false;
      symbol = symbol.toUpperCase(); // Ensure the symbol is uppercase (Binance standard)
      // symbol = "BTCUSDT";
      const connect = () => {
        stream = new WebSocket(`wss://stream.binance.com:9443/ws`);

        stream.onopen = () => {
          console.log(`WebSocket connected for ${symbol}`);
          stream.send(
            JSON.stringify({
              method: "SUBSCRIBE",
              params: [
                `${symbol.toLowerCase()}@aggTrade`, // Aggregate trade stream for the symbol
                `${symbol.toLowerCase()}@depth`, // Market depth stream for the symbol
              ],
              id: 1,
            })
          );
        };

        stream.onmessage = async (event) => {
          const data = await JSON.parse(event.data);
          // console.log(" data is ", data);

          // Check if the incoming data is an aggTrade or depth message
          if (data && data.e === "aggTrade") {
            // console.log("Aggregate Trade:", data);
            // return;
          } else if (data && data.e === "depthUpdate") {
            // console.log("Market Depth Update:", data);
            // return;
          } else {
            console.log("Other message:", data);
          }

          // Call the provided callback with the data
          if (onData) {
            onData(data);
          }
        };

        stream.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        stream.onclose = () => {
          console.log(
            `WebSocket closed: reconnecting in ${reconnectInterval} ms`
          );
          if (!closed) {
            setTimeout(connect, reconnectInterval); // Reconnect after a delay
          }
        };
      };

      // Call the connection function to establish the WebSocket
      connect();

      stream.disconnect = () => {
        closed = true;
        if (stream) {
          stream.close(1000); // Close connection gracefully
        }
      };
    },

    // Disconnect the WebSocket
    disconnectWebSocket: () => {
      if (stream) {
        stream.close();
        stream = null;
        console.log("WebSocket disconnected");
      }
    },
  };
};

// Factory Function
export const createDataProvider = (assetType) => {
  switch (assetType) {
    case "stock":
      return createStockDataProvider();

    case "crypto":
      return createCryptoDataProviderBinance();

    default:
      throw new Error("Unsupported asset type");
  }
};
