import moment from "moment/moment";

// converts this 1326220200 to this 1326200400000
export function timeStamp_convertor(n) {
  // Calculate IST offset in minutes (UTC+5:30)
  let istOffset = 330;

  // Convert seconds to milliseconds
  let milliseconds = n * 1000;

  // Adjust for IST and local timezone offset
  let adjustedMilliseconds =
    milliseconds - (istOffset + new Date().getTimezoneOffset()) * 60 * 1000;

  // Create a new Date object based on adjusted milliseconds
  let dateObj = new Date(adjustedMilliseconds);

  // Format the date as ISO string (2024-07-05T03:45:00.000Z)
  let isoString = dateObj.toISOString();

  // Convert ISO string to milliseconds since epoch
  let timestamp = Date.parse(isoString);

  return timestamp;
}

// "15:52:34" to 57154000
export function timeStringToTimestamp(timeString) {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a new Date object at Unix epoch
  const date = new Date(0);

  // Set hours, minutes, and seconds on the date object in UTC
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);
  date.setUTCSeconds(seconds);

  // Get the timestamp in milliseconds since the Unix epoch
  const timestamp = date.getTime();

  return timestamp;
}

// 1720444180491 to 2024-07-08
export function formatDateFromTimestamp(timestamp) {
  let d = new Date(timestamp);
  let year = d.getFullYear();
  let month = (d.getMonth() + 1).toString().padStart(2, "0");
  let day = d.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// this will get the previous year date. 👆
export function getPreviousYearDate(timestamp) {
  let date = new Date(timestamp);
  date.setFullYear(date.getFullYear() - 1);
  return formatDateFromTimestamp(date.getTime());
}

// function provided by dhan api
export function Scientific_ISO_ms(n) {
  let offset1 = new Date().getTimezoneOffset();
  let istOffset = 330;
  n = n - (istOffset + offset1) * 60;
  let a = ["1980", "01", "01", "05", "30", "00"];
  let time = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  time.setSeconds(n);
  let year = time.getFullYear();
  let month = ("0" + (time.getMonth() + 1)).slice(-2);
  let day = ("0" + time.getDate()).slice(-2);
  let hours = ("0" + time.getHours()).slice(-2);
  let min = ("0" + time.getMinutes()).slice(-2);
  let sec = ("0" + time.getSeconds()).slice(-2);
  let strTime =
    year + "-" + month + "-" + day + "-" + hours + "-" + min + "-" + sec;
  let strArry = strTime.split("-");

  // gives an ISO foramt 2024-07-10T03:45:00.000Z
  let convertedDate = new Date(
    strArry[0],
    strArry[1] - 1,
    strArry[2],
    strArry[3],
    strArry[4],
    strArry[5]
  );

  // converting into ms
  return new Date(convertedDate).getTime();
}

// This is especially used for socket's time converter. B'coz the data is getting in watch format. ( hh-mm-ss ). So
export function convertToUnixTimestamp(timeString) {
  const today = new Date();
  const dateString = today.toISOString().split("T")[0];
  console.log("the date is : ", dateString);

  // Combine date and time
  const fullDateTimeString = `${dateString}T${timeString}`;

  // Create a Date object
  const date = new Date(fullDateTimeString);

  // Get the Unix timestamp (in milliseconds) and convert to seconds
  const unixTimestamp = Math.floor(date.getTime());

  return unixTimestamp;
}

export function simpleTimeConverter(timeString) {
  const baseDate = new Date();

  // Extract hours and minutes from the time string
  const [hours, minutes, seconds = 0] = timeString.split(":").map(Number);

  // Create a date object with the given time in IST
  const istDate = new Date(baseDate);
  istDate.setHours(hours, minutes, seconds, 0);

  // Convert IST to GMT by subtracting 5 hours and 30 minutes
  const gmtDate = new Date(istDate.getTime() - (5 * 60 + 30) * 60 * 1000);

  // Return the Unix timestamp in milliseconds
  return gmtDate.getTime();
}

// -------------------------🔘- Fyers -------------------------------------
export function get_100_days_previous_date(date) {
  let oldDate = new Date(date);

  oldDate.setDate(oldDate.getDate() - 100);

  return oldDate.toISOString().split("T")[0];
}

export function get_1_day_back_date(date) {
  let oldDate = new Date(date);

  oldDate.setDate(oldDate.getDate() - 1);

  return oldDate.toISOString().split("T")[0];
}
// -------------------------🔘- Wazirx -------------------------------------
export function get_100_days_previous_timestamp_wazirx(timestamp) {
  let oldDate = new Date(timestamp);

  oldDate.setDate(oldDate.getDate() - 100);

  oldDate = Math.floor(oldDate / 1000);

  return oldDate; // wazirx -> needs the timestamp in terms of seconds not interms of milliseconds.
}

export function get_1_day_back_timestamp_wazirx(timestamp) {
  let oldDate = new Date(timestamp);

  oldDate.setDate(oldDate.getDate() - 1);

  oldDate = Math.floor(oldDate / 1000);

  return oldDate; // wazirx -> needs the timestamp in terms of seconds not interms of milliseconds.
}

// -------------------------trademade--------------------------------------------------
export function get_previous_date_trademade(timeframe) {
  let oldDate = moment().utc();
  if (timeframe.includes("D")) oldDate = oldDate.subtract(11, "months");
  else if (timeframe.includes("H")) oldDate = oldDate.subtract(30, "days");
  else if (timeframe.includes("M")) oldDate = oldDate.subtract(2, "days");
  oldDate = oldDate.utc();
  return oldDate;
}
export function get_previous_date_binance(oldDate, timeframe) {
  // Ensure oldDate is a Moment.js object
  let date = moment(oldDate).utc();
  const limit = 1000;

  // Timeframe parsing logic
  const number = parseInt(timeframe.slice(0, -1), 10);
  const unit = timeframe.slice(-1);

  // Adjust date based on timeframe unit
  switch (unit) {
    case "m": // minutes
      date = date.subtract(number * limit, "minutes");
      break;
    case "h": // hours
      date = date.subtract(number * limit, "hours");
      break;
    case "d": // days
      date = date.subtract(number * limit, "days");
      break;
    case "w": // weeks
      date = date.subtract(number * limit, "weeks");
      break;
    case "M": // months
      date = date.subtract(number * 100, "months");
      break;
    default:
      throw new Error("Invalid timeframe format");
  }

  return date;
}

export function get_single_frame_back_trademade(timestamp, timeframe) {
  let date = moment(timestamp).utc();
  let unitTime = Number(timeframe.substring(0, timeframe.length - 1));

  // console.log(date.format("YYYY-MM-DD-HH:mm"), unitTime);

  if (timeframe.includes("D")) date = date.subtract(unitTime, "days");
  else if (timeframe.includes("H")) date = date.subtract(unitTime, "hours");
  else if (timeframe.includes("M")) date = date.subtract(unitTime, "minutes");
  date = date.utc();

  if (timeframe.includes("D")) date = date.format("YYYY-MM-DD");
  else date = date.format("YYYY-MM-DD-HH:mm");
  return date;
}

export function get_previous_data_date(timestamp, timeframe) {
  let date;

  if (timeframe.includes("D")) date = moment(timestamp, "YYYY-MM-DD").utc();
  else date = moment(timestamp, "YYYY-MM-DD-HH:mm").utc();

  if (timeframe.includes("D")) date = date.subtract(11, "months");
  else if (timeframe.includes("H")) date = date.subtract(30, "days");
  else if (timeframe.includes("M")) date = date.subtract(2, "days");
  date = date.utc();

  if (timeframe.includes("D")) return date.format("YYYY-MM-DD");
  return date.format("YYYY-MM-DD-HH:mm");
}
