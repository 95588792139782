import Pin from "../primitives/pin.js";

export default class SquarePin extends Pin {
  constructor(comp, name, params = {}) {
    super(comp, name, params);
    this.direction = params.direction || "horizontal"; // Default direction

    this.comp.$bus.$on(`pin_changed${params.id}`, (data) => {
      if (this.direction === "vertical") {
        this.update_from([data.t, this.y$], true);
      } else {
        this.update_from([this.t, data.y$], true);
      }
    });
  }

  update() {
    this.y$ = this.comp.$props.cursor.y$;
    this.y = this.comp.$props.cursor.y;
    this.t = this.comp.$props.cursor.t;
    this.x = this.comp.$props.cursor.x;

    // Constrain movement based on direction
    if (this.direction === "horizontal") {
      this.y$ = this.comp.$props.settings[this.name]
        ? this.comp.$props.settings[this.name][1]
        : this.y$;
    } else if (this.direction === "vertical") {
      this.t = this.comp.$props.settings[this.name]
        ? this.comp.$props.settings[this.name][0]
        : this.t;
    }

    this.comp.$emit("change-settings", {
      [this.name]: [this.t, this.y$],
    });
  }

  update_from(data, emit = false) {
    if (!data) return;
    this.layout = this.comp.layout;

    this.t = data[0];
    this.x = this.layout.t2screen(this.t);
    this.y$ = data[1];
    this.y = this.layout.$2screen(this.y$);
    if (emit)
      this.comp.$emit("change-settings", {
        [this.name]: [this.t, this.y$],
      });
  }

  mousemove(event) {
    switch (this.state) {
      case "tracking":
      case "dragging":
        this.moved = true;
        this.update();
        break;
    }
  }

  draw_pin(ctx) {
    // WE WILL DRAW A SQUARE AS IT IS A SQAURE PIN

    this.layout = this.comp.layout;
    if (this.comp.selected) {
      var r = this.RADIUS,
        lw = 1.5;
    } else {
      var r = this.RADIUS * 0.95,
        lw = 1;
    }

    ctx.lineWidth = lw;
    ctx.strokeStyle = this.COLOR_BR;
    ctx.fillStyle = this.COLOR_BACK;

    var x = this.layout.t2screen(this.t) - r;
    var y = this.layout.$2screen(this.y$) - r;
    var width = 2 * r;
    var height = 2 * r;

    // Drawing square
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.closePath();

    ctx.fill();
    ctx.stroke();
  }
}
