<template>
  <div class="page">
    <div class="profile" @click="handlePopup">
      <img v-if="profile_pic" :src="profile_pic" alt="avatar" class="logo" />
      <img v-else src="/public/Tradilent_logo.png" alt="" class="logo" />
    </div>
    <div v-if="popupSignup" :class="popupSignupClass">
      <span class="cross" @click="closePopup">X</span>
      <div class="form">
        <div class="nameSection">
          <div class="logoDiv">
            <img
              v-if="profile_pic"
              :src="profile_pic"
              alt="avatar"
              class="logo"
            />
            <img v-else src="/public/Tradilent_logo.png" alt="" class="logo" />
          </div>
          <input
            type="text"
            readonly
            :class="inputClass"
            v-model="userName"
            placeholder="John doe"
          />
        </div>
      </div>
      <button :class="itemsClass" @click="() => accountSettingsOpen(0)">
        Personal information
      </button>
      <button :class="itemsClass" @click="() => accountSettingsOpen(1)">
        Account and Billing
      </button>
      <router-link v-if="!user" to="/signup" class="sign-up-box">
        SignUp
      </router-link>
      <div v-else class="sign-up-box" @click="logoutHandler">Logout</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { SERVER_URL } from "../constant.js";

export default {
  name: "Profile",
  components: {},
  data() {
    return {
      user: null,
      popupSignup: false,
      userName: "",
      profile_pic: "",

      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },
  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    popupSignupClass() {
      return this.isDark ? "popupSignup-dark" : "popupSignup";
    },
    inputClass() {
      return this.isDark ? "input-dark" : "input";
    },
    itemsClass() {
      return this.isDark ? "items-dark" : "items";
    },
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("isLogin"))) {
      if (localStorage.getItem("email")) {
        const email = localStorage.getItem("email");
        // console.log("email : ", email )
        const response = await axios.post(`${SERVER_URL}/api/getUser`, {
          email,
        });
        // console.log(response.data.user)
        this.user = response.data.user;
        this.userName = response.data.user.username;
        this.profile_pic = response.data.user.profile_pic;
      } else {
        console.log("users email is not defined please login again");
      }
    } else {
      console.log("users is not login please login ");
    }
  },
  methods: {
    handlePopup() {
      this.popupSignup = !this.popupSignup;
      this.$store.commit("updateIsOtherInputSearchOpen", this.popupSignup);
    },
    closePopup() {
      this.$store.commit("updateIsOtherInputSearchOpen", false);
      this.popupSignup = false;
    },
    accountSettingsOpen(index) {
      localStorage.setItem("selected", index);
      this.$store.commit("updateIsAccountSettingsOpen", true);
    },
    async logoutHandler() {
      localStorage.setItem("isLogin", false);
      localStorage.setItem("email", "");
      localStorage.setItem("isAccountSettingsPageOpen", false);
      await axios.get(`${SERVER_URL}/api/logout`).then(() => {
        this.$router.push("/");
        this.$toast("logout successfully", {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"],
        });
        localStorage.setItem("showRightBar", false);
        localStorage.removeItem("watchlistSelected");
        this.$store.commit("toggleShowBar", false);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
};
</script>

<style scoped>
.page {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 5px;
}
.profile {
  overflow: hidden;
  cursor: pointer;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: 0px auto;
  border-radius: 50%;
}
.logoDiv {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.logo {
  width: 28px;
  height: 28px;
}
.popupSignup {
  position: absolute;
  bottom: 0px;
  height: 200px;
  width: 200px;
  left: 1px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 50px;
  z-index: 10001;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
  padding-right: 50px;
  padding-top: 10px;
  padding-left: 10px;
}
.popupSignup-dark {
  position: absolute;
  bottom: 0px;
  height: 200px;
  width: 200px;
  left: 1px;
  background-color: #181a1b;
  border-radius: 8px;
  margin-left: 50px;
  z-index: 10001;
  box-sizing: border-box;
  box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
  padding-right: 50px;
  padding-top: 10px;
  padding-left: 10px;
}
.cross {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  color: #aeb0b3;
}

.cross:hover {
  background-color: #76878319;
}
.input {
  height: 20px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
.input-dark {
  height: 20px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: #aeb0b3;
}
.nameSection {
  margin-top: 10px;
  padding: 5px 0px 5px 5px;
  gap: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #aeb0b3;
}
.items {
  color: black;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 15px;
}
.items-dark {
  color: #aeb0b3;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 15px;
}
.items:hover {
  color: #430393;
}
.items-dark:hover {
  color: #fff;
}
.sign-up-box {
  margin-top: 10px;
  width: 120px;
  height: 34px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(29, 0, 66, 1) 0%,
    rgba(118, 0, 255, 1) 100%
  );
  color: white;
  cursor: pointer;
}

/*  @media (max-width: 500px) { */ 
/* .profile { */
/*  margin-top: 200px; */
/*  } */
/* } */
</style>
