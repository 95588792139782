var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.getIsCustomTFSelectorOpen
    ? _c("div", { staticClass: "page" }, [
        _c("div", { class: _vm.mainClass }, [
          _c(
            "button",
            { staticClass: "cross", on: { click: _vm.closeCustomTFSelector } },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _c("h1", [_vm._v("Add custom interval ⌛")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "inputType" }, [
            _c("p", [_vm._v("Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedType,
                    expression: "selectedType",
                  },
                ],
                class: _vm.selectorClass,
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.presetTFs, function (tfType, i) {
                return _c("option", { key: i, domProps: { value: tfType } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(tfType) +
                      "\n                "
                  ),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputInterval" }, [
            _c("p", [_vm._v("Interval")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.interval,
                  expression: "interval",
                },
              ],
              attrs: { type: "number", name: "interval", id: "interval" },
              domProps: { value: _vm.interval },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.interval = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          this.isShowWarn
            ? _c("p", { staticClass: "limitWarn" }, [
                _vm._v(_vm._s(this.warner()) + "\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "addCancelBtns" }, [
            _c(
              "button",
              {
                staticClass: "cancelBtn",
                on: { click: _vm.closeCustomTFSelector },
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "addBtn",
                attrs: { disabled: this.isShowWarn },
                on: { click: _vm.updateInterval },
              },
              [_c("span", [_vm._v("Add")])]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }