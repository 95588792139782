
// for seconds
export function calculateCustomTF_for_seconds (interval, assetType) {

    // return `5sec` if TF % 5 === 0 && TF % 10 !== 0
    if( interval % 5 === 0 && interval % 10 !== 0 ){
        return '5S'
    }

    // return `10sec` if TF % 10 === 0
    else if( interval % 10 === 0 ){
        return '10S'
    }
}

// ---------------****------------------

// For minutes
export function calculateCustomTF_for_minutes(interval, assetType) {
    const existingTFs = [1, 2, 3, 5, 10, 15, 20, 30];
    let result = interval;
    while (!existingTFs.includes(result)) {
        result = factors_for_minutes(result);
        if (result === 1) break; // If the largest factor is 1, exit the loop
    }
    
    return `${result}`;
}

// Factors for minutes
function factors_for_minutes (n){
    let factors = new Array();
    for(let i = 1; i < (n-1); i++){
        if(n % i === 0){
            factors.push(i);
        }
    }
    return Math.max(...factors);
}

// ---------------****------------------

// For hours
export function calculateCustomTF_for_hours(interval, assetType) {
    
    if(interval % 2 !== 0){
        return '60';
    }

    else{
        if(interval % 4 === 0){
            return '240';
        }
        else{
            return '120';
        }
    }
}

// ---------------****------------------

// For days
export function calculateCustomTF_for_days(interval, assetType) {
    return '1D'
}


// ---------------****------------------
export function extractNumericTimeframe(timeframe) {    
    // Use a regular expression to remove any non-numeric characters
    const numericPart = timeframe.replace(/\D/g, ''); // \D matches any non-digit character
    
    // Convert the extracted numeric part to an integer
    let cal = parseInt(numericPart, 10);
    if(cal === 60) return 1;
    else if(cal === 120) return 2;
    else if(cal === 240) return 4;
    else return cal;
}


// ---------------****------------------
// The switch case handler
export function getCorrectTFForCustom(selectedType, interval, assetType){
    let resultantTimeFrame = '';
    switch (selectedType){
        case 'seconds' : 
          resultantTimeFrame = calculateCustomTF_for_seconds(interval, assetType);
          break;

        case 'minutes' : 
          resultantTimeFrame = calculateCustomTF_for_minutes(interval, assetType);
          break;

        case 'hours' : 
          resultantTimeFrame = calculateCustomTF_for_hours(interval, assetType);
          break;

        case 'days' : 
          resultantTimeFrame = calculateCustomTF_for_days(interval, assetType);
          break;
      }

      return resultantTimeFrame;
}
