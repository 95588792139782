<template>
  <component
    :is="floatComponent"
    v-if="floatingBarVisible"
    :set-color="setColor"
    :current-color="color"
    :set-line-width="setLineWidth"
    :current-width="lineWidth"
  />
</template>

<script>
// Line drawing tool
// TODO: make an angle-snap when "Shift" is pressed

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Pin2 from "../primitives/pin2.js";
import Seg from "../primitives/seg.js";
import Line from "../primitives/line.js";
import Ray from "../primitives/ray.js";
import PriceLine from "../primitives/priceline.js";
import FloatingBar from "../FloatingBar.vue";
import TrendAngle from "../primitives/trendangle.js";
import HorizontalRay from "../primitives/horizontalray.js";
import CrossLine from "../primitives/crossline.js";
import InfoLine from "../primitives/infoline.js";
export default {
  name: "LineTool",
  components: {
    FloatingBar,
  },
  mixins: [Overlay, Tool],
  props: ["magnet", "floatingBar", "cursor", "redraw"],
  data() {
    return {
      floatComponent: FloatingBar,
      color: localStorage.getItem("lineToolColor") || "#42b28a",
      floatingBarVisible: true,
      lineWidth: localStorage.getItem("lineToolWidth") || 1,
    };
  },
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    // line_width() {
    //   return this.sett.lineWidth || 0.9;
    // },
    // color() {
    //   return this.sett.color || "#42b28a";
    // },
  },
  methods: {
    setLineWidth(val) {
      localStorage.setItem("lineToolWidth", val);
      this.lineWidth = val;
      this.$props.redraw();
    },

    meta_info() {
      return { author: "C451", version: "1.1.0" };
    },
    tool() {
      return {
        group: "Lines",
        icon: Icons["segment.svg"],
        type: "Segment",
        hint: "This hint will be shown on hover",
        data: [],
        settings: {},
        mods: {
          Extended: {
            settings: { extended: true },
            icon: Icons["extendedline.svg"],
          },
          Ray: {
            settings: { ray: true },
            icon: Icons["ray.svg"],
          },
          PriceLine: {
            settings: { price_line: true },
            icon: Icons["price_line.svg"],
          },
          TrendAngle: {
            settings: { trend_angle: true },
            icon: Icons["trend_angle.svg"],
          },
          HorizontalRay: {
            settings: { horizontal_ray: true },
            icon: Icons["horizontal_ray.svg"],
          },
          CrossLine: {
            settings: { crossline: true },
            icon: Icons["cross-line.svg"],
          },
          InfoLine: {
            settings: { infoline: true },
            icon: Icons["info-line.svg"],
          },
        },
      };
    },
    init() {
      // console.log(this.$props.floatingBar);
      // First pin is settled at the mouse position
      if (this.$props.magnet) {
        this.pins.push(new Pin2(this, "p1"));
        if (this.sett.price_line) {
          this.pins.push(new Pin2(this, "p2", { state: "nodragging" }));
          this.set_state("finished");
          this.$emit("drawing-mode-off");
        } else {
          this.pins.push(new Pin2(this, "p2", { state: "tracking" }));
          this.pins[1].on("settled", () => {
            this.set_state("finished");
            this.$emit("drawing-mode-off");
          });
        }
      } else {
        this.pins.push(new Pin(this, "p1"));
        if (
          this.sett.price_line ||
          this.sett.crossline ||
          this.sett.horizontal_ray
        ) {
          this.pins.push(new Pin(this, "p2", { state: "nodragging" }));
          this.set_state("finished");
          this.$emit("drawing-mode-off");
        } else {
          this.pins.push(new Pin(this, "p2", { state: "tracking" }));
          this.pins[1].on("settled", () => {
            this.set_state("finished");
            this.$emit("drawing-mode-off");
          });
        }
      }

      this.chooseDraw();
    },
    chooseDraw() {
      console.log(this.$props.magnet);

      if (this.$props.magnet) {
        this.draw = (ctx) => {
          if (!this.p1 || (!this.p2 && !this.sett.price_line)) return;

          const layout = this.$props.layout;
          const data1 = layout.c_magnet(this.p1[0]);
          const newp1_1 = data1.raw[2];
          const newp1 = [this.p1[0], newp1_1];

          const data2 = layout.c_magnet(this.p2[0]);
          const newp1_2 = data2.raw[2];
          const newp2 = [this.p2[0], newp1_2];

          ctx.lineWidth = this.lineWidth;
          ctx.strokeStyle = this.color;
          ctx.beginPath();
          if (this.sett.ray) {
            new Ray(this, ctx).draw(newp1, newp2);
          } else if (this.sett.extended) {
            new Line(this, ctx).draw(newp1, newp2);
          } else if (this.sett.price_line) {
            new PriceLine(this, ctx).draw(newp1);
          } else if (this.sett.trend_angle) {
            new TrendAngle(this, ctx).draw(this.p1, this.p2);
          } else if (this.sett.horizontal_ray) {
            new HorizontalRay(this, ctx).draw(this.p1);
          } else if (this.sett.crossline) {
            new CrossLine(this, ctx).draw(this.p1);
          } else {
            new Seg(this, ctx).draw(newp1, newp2);
          }
          ctx.stroke();
          this.drawMagnetIcon(ctx);
          this.render_pins(ctx);
        };
      } else {
        this.draw = (ctx) => {
          if (!this.p1 || (!this.p2 && !this.sett.price_line)) return;

          ctx.lineWidth = this.lineWidth;
          ctx.strokeStyle = this.color;
          ctx.beginPath();
          if (this.sett.ray) {
            new Ray(this, ctx).draw(this.p1, this.p2);
          } else if (this.sett.extended) {
            new Line(this, ctx).draw(this.p1, this.p2);
          } else if (this.sett.price_line) {
            new PriceLine(this, ctx).draw(this.p1);
          } else if (this.sett.trend_angle) {
            new TrendAngle(this, ctx).draw(this.p1, this.p2);
          } else if (this.sett.horizontal_ray) {
            new HorizontalRay(this, ctx).draw(this.p1);
          } else if (this.sett.crossline) {
            new CrossLine(this, ctx).draw(this.p1);
          } else if (this.sett.infoline) {
            new InfoLine(this, ctx).draw(this.p1, this.p2, this.color);
          } else {
            new Seg(this, ctx).draw(this.p1, this.p2);
          }
          ctx.stroke();

          this.render_pins(ctx);
        };
      }
    },
    drawMagnetIcon(ctx) {
      // Calculate the midpoint of the line
      const layout = this.$props.layout;
      const x1 = layout.t2screen(this.p1[0]);
      const x2 = layout.t2screen(this.p2[0]);

      const data1 = layout.c_magnet(this.p1[0]);
      const newp1_1 = data1.raw[2];
      const data2 = layout.c_magnet(this.p2[0]);
      const newp1_2 = data2.raw[2];
      console.log(newp1_1, newp1_2);

      const y1 = layout.$2screen(newp1_1);
      const y2 = layout.$2screen(newp1_2);
      console.log(y1, y2);

      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2 - 8;
      const iconWidth = 8; // Width of the magnet
      const iconHeight = 3; // Height of the magnet

      // Adjust the Y position to place the magnet above the line
      const magnetY = midY;
      ctx.beginPath();

      // Draw the U shape of the magnet (Outer arc)
      ctx.moveTo(midX - iconWidth / 2, magnetY + iconHeight); // Start left side
      ctx.lineTo(midX - iconWidth / 2, magnetY); // Down left side
      ctx.arc(midX, magnetY + iconHeight, iconWidth / 2, Math.PI, 0, true); // Outer arc
      ctx.lineTo(midX + iconWidth / 2, magnetY + iconHeight); // Up right side

      // Draw the inner arc of the magnet
      const innerIconHeight = iconHeight * 0.8; // Adjust height for the inner arc
      ctx.moveTo(midX - (iconWidth / 2) * 0.4, magnetY); // Start left side (inner)
      ctx.lineTo(midX - (iconWidth / 2) * 0.4, magnetY); // Down left side (inner)
      ctx.arc(
        midX,
        magnetY + innerIconHeight,
        (iconWidth / 2) * 0.5,
        Math.PI,
        0,
        true
      ); // Inner arc
      ctx.lineTo(midX + (iconWidth / 2) * 0.4, magnetY); // Up right side (inner)

      // Draw the poles
      ctx.moveTo(midX - iconWidth / 2, magnetY + iconHeight); // Left pole
      ctx.lineTo(midX - iconWidth / 2, magnetY - iconHeight / 2);
      ctx.lineTo(midX - iconWidth / 4, magnetY - iconHeight / 2);
      ctx.lineTo(midX - iconWidth / 4, magnetY + iconHeight);

      ctx.moveTo(midX + iconWidth / 2, magnetY + iconHeight); // Right pole
      ctx.lineTo(midX + iconWidth / 2, magnetY - iconHeight / 2);
      ctx.lineTo(midX + iconWidth / 4, magnetY - iconHeight / 2);
      ctx.lineTo(midX + iconWidth / 4, magnetY + iconHeight);

      ctx.strokeStyle = this.color;
      ctx.lineWidth = 1;
      ctx.stroke();
    },
    use_for() {
      return ["LineTool"];
    },
    data_colors() {
      return [this.color];
    },

    setColor(val) {
      localStorage.setItem("lineToolColor", val);
      this.color = val;
      this.$props.redraw();
    },
  },
};
</script>
