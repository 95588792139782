<!-- UxLayer.vue -->
<template>
  <span
    :class="`tradilent-vue-grid-ux-${id}`"
    :style="style"
    @mouseenter="showOptions"
    @mouseleave="hideOptions"
  >
    <ux-wrapper
      v-for="ux of uxs"
      @custom-event="on_custom_event"
      :key="ux.uuid"
      :ux="ux"
      :updater="updater"
      :colors="colors"
      :config="config"
    />
  </span>
</template>

<script>
import UxWrapper from "./UxWrapper.vue";
import data from "../../data/data.json";

export default {
  name: "UxLayer",
  components: { UxWrapper },
  props: [
    "tv_id",
    "id",
    "uxs",
    "updater",
    "colors",
    "config",
    "width",
    "height", // Add height as a prop
  ],

  // is used to define the initial state
  data() {
    return {
      isActive: false, // Track active state
      pointerEvent: "none",
    };
  },
  computed: {
    style() {
      return {
        top: this.$props.id !== 0 ? "1px" : 0,
        left: 0,
        width: "100%",
        height: this.height + "px", // Set height dynamically
        position: "absolute",
        "z-index": "1",
        "pointer-events": "none",
        overflow: "hidden",
      };
    },
  },
  mounted() {
    // this clone the data as soon as the component is mounted
    window.addEventListener("mousemove", this.handleMouseMove);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleMouseMove);
  },
  methods: {
    on_custom_event(event) {
      this.$emit("custom-event", event);
    },
    showOptions() {
      this.isActive = true; // Show GridOptions
    },
    hideOptions() {
      this.isActive = false; // Hide GridOptions
    },
  },
};
</script>
<style scoped>
/* Scoped styles */
.grid-options {
  position: absolute;
  top: 10px; /* Adjust this value to fine-tune the vertical position */
  right: 10px; /* Adjust this value to fine-tune the horizontal position */
  z-index: 100; /* Ensure it's above other content */
  pointer-events: auto;
  transition: opacity 0.3s ease; /* Smooth transition animation */
}

/* Define a class to hide elements */
.hidden {
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Disable pointer events when hidden */
}
</style>
