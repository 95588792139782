var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tvjs-item-list",
      style: _vm.list_style(),
      on: { mousedown: _vm.thismousedown },
    },
    _vm._l(_vm.items, function (item) {
      return !item.hidden
        ? _c(
            "div",
            {
              class: _vm.item_class(item),
              style: _vm.item_style(item),
              on: { click: (e) => _vm.item_click(e, item) },
            },
            [
              _c("div", {
                staticClass: "tradilent-vue-tbicon tvjs-pixelated",
                style: _vm.icon_style(item),
                domProps: { innerHTML: _vm._s(item.icon) },
              }),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(item.type))]),
            ]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }