var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.dataLoaded
        ? _c("tradilent-vue", {
            ref: "tvjs",
            attrs: {
              data: _vm.chart,
              width: this.width,
              height: this.height,
              "chart-config": { MIN_ZOOM: 1, DEFAULT_LEN: 100 },
              toolbar: true,
              "index-based": _vm.index_based,
              overlays: _vm.overlays,
              "color-back": _vm.colors.colorBack,
              "color-grid": _vm.colors.colorGrid,
              "color-text": _vm.colors.colorText,
              extensions: _vm.ext,
              "legend-buttons": [
                "display",
                "settings",
                "up",
                "down",
                "add",
                "remove",
              ],
              "x-settings": _vm.xsett,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "gc-mode" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.index_based,
              expression: "index_based",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.index_based)
              ? _vm._i(_vm.index_based, null) > -1
              : _vm.index_based,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.index_based,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.index_based = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.index_based = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.index_based = $$c
              }
            },
          },
        }),
        _vm._v(" "),
        _c("label", [_vm._v("Index Based")]),
      ]),
      _vm._v(" "),
      _vm.loading ? _c("EntryLoader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }