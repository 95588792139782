<template>
  <div :class="pageClass">
    <div class="mainComponent">
      <!-- absolute component -->
      <p :class="companyNameClass" @click="demo">
        <img src="/public/Tradilent_logo.png" alt="logo" class="logo">
        Tradilent
      </p>
      <p class="register">New Here ? <router-link to="/signup" :class="signinClass">Sign Up</router-link></p>
      <div class="contant">
        <!-- <img src="./images/question.png" alt=""> -->
        <p>Contact us</p>
      </div>

      <div :class="formFrameClass">
        <div class="loginWithPassword">
          <div class="form">
            <h3 :class="headingClass">Welcome Back!</h3>
            <p class="para">Join Tradilent by entering your email</p>
            <p class="nameText">You can sign in with Passsword</p>
            <div class="emailSection">
              <p class="nameText">Email</p>
              <input type="email" id="email" v-model="email" @input="updateEmail" placeholder="example@gmail.com">
            </div>
            <div class="PasswordSection">
              <p class="nameText">Password</p>
              <div class="passwordField">
                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password"
                  @input="updatePassword">
                <button class="showPasswordBtn" @click="toggleShowPassword">
                  <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28"
                    height="28">
                    <path fill="currentColor"
                      d="M22.2 6.5 6.5 22.2l-.7-.7L21.5 5.8l.7.7ZM14 6c1.54 0 2.9.4 4.1 1l-.74.75A8 8 0 0 0 14 7c-3.05 0-5.42 1.76-7.07 3.59A17.13 17.13 0 0 0 4.56 14a17.13 17.13 0 0 0 2.77 3.84l-.7.7-.44-.45c-1.1-1.24-2-2.61-2.74-4.09a17.7 17.7 0 0 1 2.74-4.08C7.92 7.99 10.55 6 14 6ZM21.8 9.92l-.41-.45-.7.7.38.42c1.29 1.43 2.1 2.88 2.37 3.41-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21a7.99 7.99 0 0 1-3.35-.75L9.9 21c1.2.6 2.57 1 4.1 1 3.45 0 6.08-2 7.8-3.91 1.11-1.23 2.03-2.6 2.75-4.09a17.82 17.82 0 0 0-2.74-4.08Z">
                    </path>
                    <path fill="currentColor"
                      d="M13.01 17.88A4 4 0 0 0 17.87 13l-.87.87V14a3 3 0 0 1-3.11 3l-.88.88ZM10.13 15.02l.87-.88V14a3 3 0 0 1 3.13-3l.87-.87a4 4 0 0 0-4.87 4.89Z">
                    </path>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                    <path fill="currentColor"
                      d="M21.8 9.92C20.09 7.99 17.46 6 14 6S7.92 8 6.2 9.92A17.7 17.7 0 0 0 3.44 14a18.56 18.56 0 0 0 2.74 4.08C7.92 20.01 10.55 22 14 22c3.45 0 6.08-2 7.8-3.92 1.11-1.22 2.03-2.6 2.75-4.08a17.82 17.82 0 0 0-2.74-4.08ZM14 21c-3.05 0-5.42-1.76-7.07-3.58A17.13 17.13 0 0 1 4.56 14c.27-.53 1.08-1.98 2.37-3.42C8.58 8.76 10.95 7 14 7c3.05 0 5.42 1.76 7.07 3.58 1.29 1.44 2.1 2.89 2.37 3.42-.27.53-1.08 1.98-2.37 3.42C19.42 19.24 17.05 21 14 21Z">
                    </path>
                    <path fill="currentColor" fill-rule="evenodd"
                      d="M10 14a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm1 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z">
                    </path>
                  </svg>
                </button>
              </div>
            </div>
            <button class="signupEmail" @click="signinUsingPassword" :disabled="!CanLogin">Login</button>
            <div class="separator"><span class="separatorMsg">or sign up with</span></div>

            <div class="toAppearOnSmallWidthDevice">
              <button class="signupEmail" @click="handleOTP">Request OTP</button>
              <div class="separator"><span class="separatorMsg">or sign up with</span></div>
            </div>
            <div class="authProviders">
              <button class="signupGoogle" @click="handleGoogle">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <g clip-path="url(#clip0_5_68)">
                    <path
                      d="M20.1028 10.1313C20.1028 9.36907 20.0395 8.81285 19.9025 8.23603H10.9956V11.6763H16.2238C16.1184 12.5313 15.5492 13.8188 14.2843 14.684L14.2666 14.7992L17.0828 16.9311L17.2779 16.9502C19.0698 15.333 20.1028 12.9536 20.1028 10.1313Z"
                      fill="#4285F4" />
                    <path
                      d="M10.9958 19.1954C13.5571 19.1954 15.7074 18.3713 17.278 16.9499L14.2845 14.6837C13.4834 15.2297 12.4082 15.6108 10.9958 15.6108C8.48709 15.6108 6.35788 13.9937 5.59888 11.7585L5.48762 11.7677L2.55929 13.9823L2.521 14.0863C4.08101 17.1146 7.2854 19.1954 10.9958 19.1954Z"
                      fill="#34A853" />
                    <path
                      d="M5.59856 11.7588C5.39829 11.1819 5.28238 10.5639 5.28238 9.92527C5.28238 9.28661 5.39829 8.6686 5.58802 8.09179L5.58271 7.96894L2.61769 5.71877L2.52068 5.76386C1.87772 7.02053 1.50879 8.43171 1.50879 9.92527C1.50879 11.4188 1.87772 12.8299 2.52068 14.0866L5.59856 11.7588Z"
                      fill="#FBBC05" />
                    <path
                      d="M10.9958 4.23925C12.7771 4.23925 13.9788 4.99118 14.6639 5.61955L17.3413 3.06503C15.697 1.57147 13.5571 0.654732 10.9958 0.654732C7.2854 0.654732 4.08101 2.7354 2.521 5.7637L5.58834 8.09162C6.35788 5.85644 8.48709 4.23925 10.9958 4.23925Z"
                      fill="#EB4335" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_68">
                      <rect width="20" height="20" fill="white" transform="translate(0.810547)" />
                    </clipPath>
                  </defs>
                </svg>
                Sign up with google
              </button>
            </div>
          </div>
        </div>

        <div class="loginWithOTP">
          <div class="form">
            <h3 :class="headingClass">Welcome Back!</h3>
            <p class="para">Join Tradilent by entering your email</p>
            <p class="nameText">Request OTP To login</p>
            <div class="emailSection">
              <p class="nameText">Email</p>
              <input type="email" id="email" v-model="email" @input="updateEmail" placeholder="example@gmail.com">
            </div>

            <button class="signupEmail" @click="handleOTP">Request OTP</button>
            <div class="separator"><span class="separatorMsg">or sign up with</span></div>
            <div class="authProviders">
              <button class="signupGoogle" @click="handleGoogle">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <g clip-path="url(#clip0_5_68)">
                    <path
                      d="M20.1028 10.1313C20.1028 9.36907 20.0395 8.81285 19.9025 8.23603H10.9956V11.6763H16.2238C16.1184 12.5313 15.5492 13.8188 14.2843 14.684L14.2666 14.7992L17.0828 16.9311L17.2779 16.9502C19.0698 15.333 20.1028 12.9536 20.1028 10.1313Z"
                      fill="#4285F4" />
                    <path
                      d="M10.9958 19.1954C13.5571 19.1954 15.7074 18.3713 17.278 16.9499L14.2845 14.6837C13.4834 15.2297 12.4082 15.6108 10.9958 15.6108C8.48709 15.6108 6.35788 13.9937 5.59888 11.7585L5.48762 11.7677L2.55929 13.9823L2.521 14.0863C4.08101 17.1146 7.2854 19.1954 10.9958 19.1954Z"
                      fill="#34A853" />
                    <path
                      d="M5.59856 11.7588C5.39829 11.1819 5.28238 10.5639 5.28238 9.92527C5.28238 9.28661 5.39829 8.6686 5.58802 8.09179L5.58271 7.96894L2.61769 5.71877L2.52068 5.76386C1.87772 7.02053 1.50879 8.43171 1.50879 9.92527C1.50879 11.4188 1.87772 12.8299 2.52068 14.0866L5.59856 11.7588Z"
                      fill="#FBBC05" />
                    <path
                      d="M10.9958 4.23925C12.7771 4.23925 13.9788 4.99118 14.6639 5.61955L17.3413 3.06503C15.697 1.57147 13.5571 0.654732 10.9958 0.654732C7.2854 0.654732 4.08101 2.7354 2.521 5.7637L5.58834 8.09162C6.35788 5.85644 8.48709 4.23925 10.9958 4.23925Z"
                      fill="#EB4335" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_68">
                      <rect width="20" height="20" fill="white" transform="translate(0.810547)" />
                    </clipPath>
                  </defs>
                </svg>
                Sign up with google
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth } from '../../firebase/firebaseConfig.js' // firebase auth
import {SERVER_URL} from '../../constant.js'

export default {
  data() {
    return {
      email: '',
      isEmailValid: false,
      CanLogin: false,
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
      password: "",
      showPassword: false,
    }
  },
  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    pageClass() {
      return this.isDark ? "page-dark" : "page";
    },
    companyNameClass() {
      return this.isDark ? "companyName-dark" : "companyName";
    },
    formFrameClass() {
      return this.isDark ? "formFrame-dark" : "formFrame";
    },
    headingClass() {
      return this.isDark ? "heading-dark" : "heading";
    },
    signinClass() {
      return this.isDark ? "signin-dark" : "signin";
    },
  },
  methods: {
    async handleGoogle(e) {
      // console.log(e)
      const provider = await new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      if (result) {
        // console.log(result.user)
        localStorage.setItem('email', result.user.email)
        const data = {
          username: result.user.displayName,
          email: result.user.email,
          profile_pic: result.user.photoURL,
        }
        // console.log(data)
        await axios.post(`${SERVER_URL}/api/registerUserUsingGoogle`, data)
          .then((response) => {
            localStorage.setItem('isLogin', true)
            this.$toast('login successfully', {
                
              toastClassName: "my-custom-toast-class",
  
              bodyClassName: ["custom-class-1", "custom-class-2"]
            })
          })
        this.$router.push('/')
      } else {
        console.log("error while creating google account")
      }
    },
    updateEmail(e) {
      this.email = e.target.value;
      if (this.email.includes('@') && this.email.includes('.')) {
        this.isEmailValid = true;
      } else {
        this.isEmailValid = false;
      }
      this.setCanLogin()
    },
    updatePassword(e) {
      this.padding = e.target.value;
    },
    setCanLogin() {
      if (this.isEmailValid) {
        this.CanLogin = true;
      } else {
        this.CanLogin = false;
      }
    },
    async handleOTP(e) {
      e.preventDefault()
      // console.log("first")
      localStorage.setItem('email', this.email);
      localStorage.setItem('isLogin', false);
      const otp = this.generateOTP()

      // mailing service
      const url = `${SERVER_URL}/api/sendMail`
      const data = {
        email: this.email,
        otp: otp
      }
      // console.log(data)
      if (this.isEmailValid) {
        await axios.post(url, data)
          .then((res) => {
            this.$toast(res.data.message, {
                
              toastClassName: "my-custom-toast-class",
  
              bodyClassName: ["custom-class-1", "custom-class-2"]
            })
            if (res.data.redirect)
              this.$router.push(`${res.data.redirect}`);
          })
          .catch((err) => {
            console.log("error : ", err)
          })
      } else {
        this.$toast("Invalid Email", {
            
          toastClassName: "my-custom-toast-class",

          // For the toast body when using strings or a custom component as content
          bodyClassName: ["custom-class-1", "custom-class-2"]
        })
      }
    },
    generateOTP() {
      return Math.floor(100000 + Math.random() * 900000).toString();
    },
    async signinUsingPassword() {
      // console.log("first")
      const url = `${SERVER_URL}/api/loginUsingPassword`
      const data = {
        email: this.email,
        password: this.password
      }
      await axios.post(url, data)
        .then((res) => {
          this.$toast(res.data.message, {
              
            toastClassName: "my-custom-toast-class",

            // For the toast body when using strings or a custom component as content
            bodyClassName: ["custom-class-1", "custom-class-2"]
          })
          localStorage.setItem('email', this.email)
          localStorage.setItem('isLogin', true)
          if (res.data.redirect) {
            this.$router.push(`${res.data.redirect}`);
          }
        })
        .catch((err) => {
          console.log("error : ", err)
          this.$toast(err.response.data.message, {
              
            toastClassName: "my-custom-toast-class",

            // For the toast body when using strings or a custom component as content
            bodyClassName: ["custom-class-1", "custom-class-2"]
          })
        })
    },
    toggleShowPassword(e) {
      e.preventDefault()
      this.showPassword = !this.showPassword;
    },
    demo(){
      console.log(window.innerWidth)
    }
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.page-dark {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.mainComponent {
  width: 90%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyName {
  display: flex;
  align-items: center;
  position: absolute;
  top: -3rem;
  left: 0;
  color: #000;
  font-family: Montserrat;
  font-size: 1.26075rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00475rem;
}

.companyName-dark {
  display: flex;
  align-items: center;
  position: absolute;
  top: -3rem;
  left: 0;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.26075rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00475rem;
}

.register {
  color: var(--Neutral-Var-40, #646866);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  position: absolute;
  top: -3rem;
  right: 0;
}

.signin {
  color: var(--secondary-60, #EE9644);
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;

}

.signin-dark {
  background-image: linear-gradient(180deg, #fff 14.17%, #b372cf);
  -webkit-background-clip: text;
  /* For WebKit browsers */
  background-clip: text;
  /* For other browsers */
  color: transparent;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration: underline;
  text-decoration-color: #b372cf;
}

.contant {
  color: var(--Neutral-30, #4C4C4C);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  position: absolute;
  bottom: -3rem;
  left: 0;
}

.formFrame {
  padding: 20px;
  height: fit-content;
  width: 80%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0px -15px 100px 15px rgba(7, 24, 20, 0.08);
  padding-bottom: 20px;
  background-color: #fbfefd;
}

.formFrame-dark {
  padding: 20px;
  height: fit-content;
  width: 80%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0px -15px 100px 15px #602A9A;
  padding-bottom: 20px;
  background-color: #18162099;
}

.form {
  height: fit-content;
  width: 90%;
}

.heading {
  color: var(--Black6, #071814);
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}

.heading-dark {
  color: var(--Black6, #fff);
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}

.para {
  color: var(--Neutral-Var-40, #646866);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  margin-top: -15px;
}

.emailSection {
  margin: 0;
  padding: 0;
  margin-top: 40px;
}

.PasswordSection {
  margin: 0;
  padding: 0;
}

#email {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  margin-top: -15px;
  outline: 1px solid #DEF1EC;
  padding-left: 5px;

}

#password {
  width: 100%;
  height: 100%;
  /* border-radius: 0.5rem; */
  border: none;
  outline: none;
  padding-left: 5px;
}

.checkbox {
  display: flex;
  align-items: start;
  margin-top: 20px;
  margin-bottom: 20px;
}

#checkbox {
  cursor: pointer;
}

.notify {
  color: var(--Neutral-Var-40, #646866);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  cursor: pointer;
}

.signupEmail {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Neutral-Var-40, #646866);
  width: 100%;
  padding: 0.8125rem 7.75rem;
  border-radius: 0.5rem;
  text-wrap: nowrap;
  text-align: center;
  border: 1px solid var(--Neutral-Var-40, #646866);
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  background-color: transparent;
}

.signupEmail:hover {
  background-color: black;
  color: white;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.nameText {
  color: #aaa;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1rem;
}

.separatorMsg {
  color: var(--Neutral-Var-40, #646866);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.signupGoogle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 0.8125rem 7.75rem;
  border-radius: 0.5rem;
  text-wrap: nowrap;
  text-align: center;
  border: 1px solid var(--Neutral-Var-40, #646866);
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  background-color: transparent;
  color: var(--Neutral-Var-40, #646866);
}

.signupGoogle:hover {
  background-color: black;
  color: white;
}

.authProviders {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 40px;
  width: 50px;
}

.loginWithPassword {
  height: fit-content;
  width: 50%;
  border-right: 1px solid #ccc;
}

.loginWithOTP {
  width: 50%;
  display: flex;
  justify-content: center;

}

.passwordField {
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1px solid #DEF1EC;
  outline: 1px solid #DEF1EC;
  overflow: hidden;
}

.showPasswordBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}


@media (min-width: 900px) {
  .toAppearOnSmallWidthDevice{
    display: none;
  }
}

@media (max-width: 900px) {

  .formFrame {
    height: fit-content;
    width: 80%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .form{
    width: 100%;
  }

  .formFrame-dark {
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loginWithOTP{
    display: none;
  }
  .loginWithPassword {
    height: fit-content;
    width: 100%;
    border-right: none;
  }
  #email{
    width: 100%;
  }
  .companyName {
    font-size: 1rem;
    flex-direction: column;
    top: -5rem;
  }

  .companyName-dark {
    font-size: 1rem;
    flex-direction: column;
    top: -5rem;
  }

  .register {
    font-size: 1rem;
  }

  .contant {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .formFrame {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .formFrame-dark {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .form {
    height: 100%;
    width: 100%;
  }

  .mainComponent {
    margin-left: 2px;
    margin-right: 2px;
    width: 90%;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .companyName {
    font-size: 1rem;
    flex-direction: column;
    top: -5rem;
  }

  .companyName-dark {
    font-size: 1rem;
    flex-direction: column;
    top: -5rem;
  }

  .register {
    font-size: 1rem;
  }

  .contant {
    font-size: 1rem;
  }

}
</style>