var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "con",
      class: {
        "dark-bg": _vm.isDark,
      },
      style: {
        width: _vm.containerWidth + "px",
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "checkbox",
          class: { disabled: !_vm.replay && _vm.replayActive },
          attrs: { for: "checkbox1" },
          on: { change: _vm.handleReplayType },
        },
        [
          _c(
            "span",
            {
              staticClass: "label",
              class: {
                "dark-clr": _vm.isDark,
              },
            },
            [_vm._v("Moving Candles")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.movingCandles,
                expression: "movingCandles",
              },
            ],
            attrs: { id: "checkbox1", type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.movingCandles)
                ? _vm._i(_vm.movingCandles, null) > -1
                : _vm.movingCandles,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.movingCandles,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.movingCandles = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.movingCandles = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.movingCandles = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item",
          class: { disabled: _vm.disabled },
          on: { click: _vm.togglePause },
        },
        [
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.replayPaused === undefined || _vm.replayPaused === true,
                  expression:
                    "replayPaused === undefined || replayPaused === true",
                },
              ],
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 28 28",
                width: "28",
                height: "28",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  "fill-rule": "evenodd",
                  d: "m10.997 6.93 7.834 6.628a.58.58 0 0 1 0 .88l-7.834 6.627c-.359.303-.897.04-.897-.44V7.37c0-.48.538-.743.897-.44Zm8.53 5.749a1.741 1.741 0 0 1 0 2.637l-7.834 6.628c-1.076.91-2.692.119-2.692-1.319V7.37c0-1.438 1.616-2.23 2.692-1.319l7.834 6.628Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.replayPaused === false,
                  expression: "replayPaused === false",
                },
              ],
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 28 28",
                width: "28",
                height: "28",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  "fill-rule": "evenodd",
                  d: "M10 6h2v16h-2V6ZM9 6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Zm7 0h2v16h-2V6Zm-1 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V6Z",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item", on: { click: _vm.increaseSlider } }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 28 28",
              width: "28",
              height: "28",
            },
          },
          [
            _c("path", {
              attrs: {
                fill: "currentColor",
                "fill-rule": "evenodd",
                d: "M20 6v16h1V6h-1Zm-3.908 7.628L9.834 7.996A.5.5 0 0 0 9 8.368v11.264a.5.5 0 0 0 .834.372l6.258-5.632a.5.5 0 0 0 0-.744Zm.67 1.487a1.5 1.5 0 0 0 0-2.23l-6.259-5.632C9.538 6.384 8 7.07 8 8.368v11.264c0 1.299 1.538 1.984 2.503 1.115l6.258-5.632Z",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c("vue-slider", {
            attrs: { min: 0.25, max: 20, interval: 0.25 },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "item",
          class: { disabled: _vm.disabled },
          on: { click: _vm.stopReplay },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 28 28",
                width: "28",
                height: "28",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  "fill-rule": "evenodd",
                  d: "M18 22V6h1v16h-1ZM8.834 7.996l6.258 5.632a.5.5 0 0 1 0 .744l-6.258 5.632A.5.5 0 0 1 8 19.632V8.368a.5.5 0 0 1 .834-.372Zm6.927 4.89a1.5 1.5 0 0 1 0 2.229l-6.258 5.632C8.538 21.616 7 20.93 7 19.632V8.368C7 7.07 8.538 6.384 9.503 7.253l6.258 5.632ZM21 6v16h1V6h-1Z",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "item", on: { click: _vm.cancelReplay } }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 17 17",
              width: "17",
              height: "17",
              fill: "currentColor",
            },
          },
          [
            _c("path", { attrs: { d: "m.58 1.42.82-.82 15 15-.82.82z" } }),
            _vm._v(" "),
            _c("path", { attrs: { d: "m.58 15.58 15-15 .82.82-15 15z" } }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }