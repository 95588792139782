var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tradilent-vue-section",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.openContextMenu.apply(null, arguments)
        },
      },
    },
    [
      _c("chart-legend", {
        ref: "legend",
        attrs: {
          values: _vm.section_values,
          grid_id: _vm.grid_id,
          common: _vm.legend_props,
          meta_props: _vm.get_meta_props,
        },
        on: { "legend-button-click": _vm.button_click },
      }),
      _vm._v(" "),
      _c(
        "grid",
        _vm._b(
          {
            ref: "grid",
            attrs: {
              "select-candle": _vm.selectCandle,
              grid_id: _vm.grid_id,
              magnet: _vm.magnet,
            },
            on: {
              "register-kb-listener": _vm.register_kb,
              "remove-kb-listener": _vm.remove_kb,
              "range-changed": _vm.range_changed,
              "cursor-changed": _vm.cursor_changed,
              "cursor-locked": _vm.cursor_locked,
              "layer-meta-props": _vm.emit_meta_props,
              "custom-event": _vm.emit_custom_event,
              "sidebar-transform": _vm.sidebar_transform,
              "rezoom-range": _vm.rezoom_range,
            },
          },
          "grid",
          _vm.grid_props,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "sidebar",
        _vm._b(
          {
            ref: "sb-" + _vm.grid_id,
            attrs: { grid_id: _vm.grid_id, rerender: _vm.rerender },
            on: { "sidebar-transform": _vm.sidebar_transform },
          },
          "sidebar",
          _vm.sidebar_props,
          false
        )
      ),
      _vm._v(" "),
      _vm.showContextMenu
        ? _c("context-menu", {
            attrs: { position: _vm.contextMenuPosition },
            on: {
              close: function ($event) {
                _vm.showContextMenu = false
              },
              "settings-click": _vm.openSettings,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCandlesModal
        ? _c("ChartSettings", {
            on: {
              close: function ($event) {
                _vm.showCandlesModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }