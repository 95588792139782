var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "floating-bar",
      style: {
        top: _vm.positionX + "px",
        left: _vm.positionY + "px",
        "background-color": !_vm.$store.getters.getTheme
          ? "#dddddd"
          : "rgb(45, 45, 45)",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "floating-bar-item",
          style: {
            "border-right": "0.1px solid rgb(67, 67, 67)",
          },
          on: {
            dblclick: function ($event) {
              _vm.isDraggable = !_vm.isDraggable
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                "aria-hidden": "true",
                width: "30",
                height: "30",
                viewBox: "0 0 20 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M7.5 6C8.05228 6 8.5 5.55228 8.5 5C8.5 4.44772 8.05228 4 7.5 4C6.94772 4 6.5 4.44772 6.5 5C6.5 5.55228 6.94772 6 7.5 6Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M7.5 11C8.05228 11 8.5 10.5523 8.5 10C8.5 9.44772 8.05228 9 7.5 9C6.94772 9 6.5 9.44772 6.5 10C6.5 10.5523 6.94772 11 7.5 11Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M8.5 15C8.5 15.5523 8.05228 16 7.5 16C6.94772 16 6.5 15.5523 6.5 15C6.5 14.4477 6.94772 14 7.5 14C8.05228 14 8.5 14.4477 8.5 15Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M12.5 6C13.0523 6 13.5 5.55228 13.5 5C13.5 4.44772 13.0523 4 12.5 4C11.9477 4 11.5 4.44772 11.5 5C11.5 5.55228 11.9477 6 12.5 6Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M13.5 10C13.5 10.5523 13.0523 11 12.5 11C11.9477 11 11.5 10.5523 11.5 10C11.5 9.44772 11.9477 9 12.5 9C13.0523 9 13.5 9.44772 13.5 10Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M12.5 16C13.0523 16 13.5 15.5523 13.5 15C13.5 14.4477 13.0523 14 12.5 14C11.9477 14 11.5 14.4477 11.5 15C11.5 15.5523 11.9477 16 12.5 16Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "floating-bar-item" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentColor,
              expression: "currentColor",
            },
          ],
          staticClass: "color-picker",
          attrs: { type: "color", default: "#42b28a" },
          domProps: { value: _vm.currentColor },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.currentColor = $event.target.value
              },
              _vm.onColorChange,
            ],
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "floating-bar-item", on: { click: _vm.toggleDropdown } },
        [
          _c(
            "svg",
            {
              attrs: {
                "aria-hidden": "true",
                width: "30",
                height: "30",
                viewBox: "0 0 20 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M3.125 5H16.875V6.25H3.125V5Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M3.125 8.125H16.875V10.625H3.125V8.125Z",
                  fill: "currentColor",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "shape-rendering": "auto",
                  d: "M16.875 12.5H3.125V15.625H16.875V12.5Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDropdown,
              expression: "showDropdown",
            },
          ],
          staticClass: "dropdown",
          style: {
            "background-color": !_vm.$store.getters.getTheme
              ? "#dddddd"
              : "rgb(45, 45, 45)",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dropdown-item",
              class: {
                "bg-light":
                  _vm.currentWidthSelected === 1 && _vm.$store.getters.getTheme,
                "bg-dark":
                  _vm.currentWidthSelected === 1 &&
                  !_vm.$store.getters.getTheme,
              },
              on: {
                click: function ($event) {
                  return _vm.setWidth(1)
                },
              },
            },
            [
              _c("div", {
                class: {
                  "bg-white": _vm.$store.getters.getTheme,
                  "bg-black": !_vm.$store.getters.getTheme,
                },
                style: {
                  height: "1px",
                  width: "80px",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-item",
              class: {
                "bg-light":
                  _vm.currentWidthSelected === 2 && _vm.$store.getters.getTheme,
                "bg-dark":
                  _vm.currentWidthSelected === 2 &&
                  !_vm.$store.getters.getTheme,
              },
              on: {
                click: function ($event) {
                  return _vm.setWidth(2)
                },
              },
            },
            [
              _c("div", {
                class: {
                  "bg-white": _vm.$store.getters.getTheme,
                  "bg-black": !_vm.$store.getters.getTheme,
                },
                style: {
                  height: "2px",
                  width: "80px",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-item",
              class: {
                "bg-light":
                  _vm.currentWidthSelected == 3 && _vm.$store.getters.getTheme,
                "bg-dark":
                  _vm.currentWidthSelected === 3 &&
                  !_vm.$store.getters.getTheme,
              },
              on: {
                click: function ($event) {
                  return _vm.setWidth(3)
                },
              },
            },
            [
              _c("div", {
                class: {
                  "bg-white": _vm.$store.getters.getTheme,
                  "bg-black": !_vm.$store.getters.getTheme,
                },
                style: {
                  height: "3px",
                  width: "80px",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-item",
              class: {
                "bg-light":
                  _vm.currentWidthSelected === 4 && _vm.$store.getters.getTheme,
                "bg-dark":
                  _vm.currentWidthSelected === 4 &&
                  !_vm.$store.getters.getTheme,
              },
              on: {
                click: function ($event) {
                  return _vm.setWidth(4)
                },
              },
            },
            [
              _c("div", {
                class: {
                  "bg-white": _vm.$store.getters.getTheme,
                  "bg-black": !_vm.$store.getters.getTheme,
                },
                style: {
                  height: "4px",
                  width: "80px",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }