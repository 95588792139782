export async function fetchHistoricalDataBinance(
  symbol,
  interval,
  limit = 500,
  startTime = null,
  endTime = null
) {
  const baseUrl = "https://api.binance.com/api/v3/klines";

  // Build the URL dynamically based on the presence of startTime and endTime
  let url = `${baseUrl}?symbol=${symbol}&interval=${interval}&limit=${limit}`;
  console.log(url);
  if (startTime) {
    url += `&startTime=${startTime}`;
  }

  if (endTime) {
    url += `&endTime=${endTime}`;
  }

  try {
    const response = await fetch(url);
    const data = await response.json();
    const candles = data.map((candle) => [
      candle[0] / 1000,
      parseFloat(candle[1]),
      parseFloat(candle[2]),
      parseFloat(candle[3]),
      parseFloat(candle[4]),
      parseFloat(candle[5]),
      candle[6] / 1000,
    ]);

    // console.log(candles);
    return candles;
  } catch (error) {
    console.error("Error fetching Binance historical data:", error);
  }
}
