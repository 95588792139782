var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBar
    ? _c(
        "div",
        { class: _vm.barClass, style: { width: _vm.width + "px" } },
        [
          _c("div", {
            staticClass: "resizeHandle",
            on: { mousedown: _vm.onMouseDown },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "toolBar" },
            [
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  attrs: { title: "Info" },
                  on: { click: _vm.handleInfoBar },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 44 44",
                        width: "44",
                        height: "44",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  attrs: { title: "Screenshot" },
                  on: { click: _vm.getScreenShot },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "28",
                        height: "28",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            fill: "none",
                            "fill-rule": "evenodd",
                            stroke: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M13.111 18.5H10.5a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-8.389z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M18.5 20v1.5a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1H8",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  attrs: { title: "Download" },
                  on: { click: _vm.handleDownload },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "28",
                        height: "28",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          stroke: "currentColor",
                          d: "M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 5V17m-4-3.5l4 4l4-4",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  attrs: { title: "Copy Link" },
                  on: { click: _vm.copyLink },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 28 28",
                        width: "28",
                        height: "28",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          stroke: "currentColor",
                          d: "M19 15l2.5-2.5c1-1 1.5-3.5-.5-5.5s-4.5-1.5-5.5-.5L13 9M10 12l-2.5 2.5c-1 1-1.5 3.5.5 5.5s4.5 1.5 5.5.5L16 18M17 11l-5 5",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "toolbarItem",
                  attrs: { title: "screen recorder" },
                  on: { click: _vm.openRecOptions },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "38",
                        height: "38",
                        viewBox: "0 0 38 38",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M18.8903 37.7806C8.45747 37.7806 0 29.323 0 18.8903C0 8.45747 8.45747 0 18.8903 0C29.323 0 37.7806 8.45747 37.7806 18.8903C37.7806 29.323 29.323 37.7806 18.8903 37.7806ZM18.8903 34.0026C27.2366 34.0026 34.0026 27.2366 34.0026 18.8903C34.0026 10.544 27.2366 3.77806 18.8903 3.77806C10.544 3.77806 3.77806 10.544 3.77806 18.8903C3.77806 27.2366 10.544 34.0026 18.8903 34.0026ZM18.8903 28.3355C13.6739 28.3355 9.44515 24.1067 9.44515 18.8903C9.44515 13.6739 13.6739 9.44515 18.8903 9.44515C24.1067 9.44515 28.3355 13.6739 28.3355 18.8903C28.3355 24.1067 24.1067 28.3355 18.8903 28.3355Z",
                          fill: "#CCCCCC",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("rec")]),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "white",
                        width: "15",
                        height: "4",
                        viewBox: "0 0 10 8",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0.780273 0.365234L4.57409 3.41383L8.90987 0.365234",
                          stroke: "black",
                          "stroke-opacity": "0.76",
                          "stroke-width": "0.5081",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.openRec
                ? _c(
                    "div",
                    { staticClass: "toolbarItem2" },
                    [_c("ScreenRecorder")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("FeedBack", { attrs: { title: "Feedback" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("watchlist"),
        ],
        1
      )
    : _c("div", { staticClass: "noRightToolBar" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }