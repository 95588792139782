import Math2 from "../../stuff/math.js";
import Utils from "../../stuff/utils.js";

export default class TrendAngle {
  // Overlay ref, canvas ctx
  constructor(overlay, ctx) {
    this.ctx = ctx;
    this.comp = overlay;
    this.T = overlay.$props.config.TOOL_COLL;
    if (Utils.is_mobile) this.T *= 2;
  }

  // p1[t, $], p2[t, $] (time-price coordinates)
  draw(p1, p2) {
    const layout = this.comp.$props.layout;

    let x1 = layout.t2screen(p1[0]);
    let y1 = layout.$2screen(p1[1]);
    let x2 = layout.t2screen(p2[0]);
    let y2 = layout.$2screen(p2[1]);

    // Draw the line segment
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke(); // Draw the line

    // Calculate the angle in degrees
    const angle = this.calculateAngle([x1, y1], [x2, y2]);
    console.log("Angle:", angle.toFixed(2) + "°");

    // Draw small dots along the arc
    this.drawDots([x1, y1], angle, this.calculateDistance([x1, y1], [x2, y2]));

    // Display the angle label near the last dot
    this.displayAngleLabel(
      [x1, y1],
      angle,
      this.calculateDistance([x1, y1], [x2, y2])
    );

    // Add the collision function
    this.comp.collisions.push(this.make([x1, y1], [x2, y2]));
  }

  // Calculate the angle between two points in degrees
  calculateAngle(p1, p2) {
    const deltaY = p2[1] - p1[1];
    const deltaX = p2[0] - p1[0];
    let radians = Math.atan2(deltaY, deltaX); // Calculate the angle in radians

    // Invert the angle to reverse the sign convention
    radians = -radians;

    const degrees = radians * (180 / Math.PI); // Convert to degrees
    return degrees;
  }

  // Calculate the distance between two points
  calculateDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));
  }

  // Draw small dots along the arc from 0 degrees to the calculated angle
  drawDots(center, angle, length) {
    const numDots = 20; // Increased number of dots
    const dotLength = length / 5; // Length of the line where dots will be placed

    // Convert angle to radians
    const angleRadians = angle * (Math.PI / 180);

    for (let i = 0; i <= numDots; i++) {
      const currentAngle = (i / numDots) * angleRadians; // Proportionate angle
      const dotX = center[0] + Math.cos(currentAngle) * dotLength;
      const dotY = center[1] - Math.sin(currentAngle) * dotLength; // Adjust for canvas coordinates

      // Draw the dot
      this.ctx.beginPath();
      this.ctx.arc(dotX, dotY, 1.5, 0, 2 * Math.PI); // Draw a smaller dot with radius 1.5
      this.ctx.fill();
    }
  }

  // Display the angle label near the last dot
  displayAngleLabel(center, angle, length) {
    const angleRadians = angle * (Math.PI / 180);
    const labelX = center[0] + (Math.cos(angleRadians) * length) / 5;
    const labelY = center[1] - (Math.sin(angleRadians) * length) / 5;

    this.ctx.font = "12px Arial";
    this.ctx.fillStyle = "black";
    this.ctx.fillText(angle.toFixed(2) + "°", labelX + 5, labelY - 5); // Adjust the position of the label
  }

  // Collision function. x, y - mouse coord.
  make(p1, p2) {
    return (x, y) => {
      return Math2.point2seg([x, y], p1, p2) < this.T;
    };
  }
}
