<script>
// The Fibonacci tool for drawing Fibonacci retracement levels on a chart

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Pin2 from "../primitives/pin2.js";
import Seg from "../primitives/seg.js";

export default {
  name: "FiboTool", // Name of the component
  mixins: [Overlay, Tool], // Mixin dependencies
  // props: ["newIsLocked"],
  props: ["magnet"],

  // watch: {
  //   newIsLocked() {
  //     this.isLocked = this.newIsLocked;
  //     this.isLockTool(this.isLocked);
  //   },
  // },
  methods: {
    // Meta information about the tool
    meta_info() {
      return { author: "chandan-atiuttam", version: "2.0.1" };
    },
    // Tool descriptor
    tool() {
      return {
        // Group under which the tool will be categorized
        group: "Fibonacci",
        // Icon representing the tool
        icon: Icons["fibretracement.svg"],
        // Type of the tool
        type: "Retracement",
        // Hint for the tool
        hint: "Fibonacci Retracement",
        // Default data for the tool
        data: [],
        // Default settings for the tool
        settings: {
          lineColor: "#000000",
          backgroundColors: [
            "#ff0052",
            "#e0e0ff8c",
            "#e1f3ff8f",
            "#e4fffe8a",
            "#e4fff087",
            "#e4ffe294",
            "#f1ffe194",
            "#f6ffeb99",
            "#fffddc99",
            "#ffefe091",
          ],
        },
        // Modifications for different modes of the tool
        mods: {
          ShiftMode: {
            settings: {
              price: false,
              time: true,
              shiftMode: true,
            },
            hidden: true,
          },
        },
      };
    },
    // Called after the overlay is mounted
    init() {
      if (this.$props.magnet) {
        this.pins.push(new Pin2(this, "p1"));

        this.pins.push(new Pin2(this, "p2", { state: "tracking" }));
        this.pins[1].on("settled", () => {
          this.set_state("finished");
          this.$emit("drawing-mode-off");
        });
      } else {
        this.pins.push(new Pin(this, "p1"));

        this.pins.push(new Pin(this, "p2", { state: "tracking" }));
        this.pins[1].on("settled", () => {
          this.set_state("finished");
          this.$emit("drawing-mode-off");
        });
      }
      this.chooseDraw();
    },
    // Draw method for rendering the Fibonacci retracement levels
    chooseDraw() {
      if (this.$props.magnet) {
        this.draw = (ctx) => {
          if (!this.p1 || !this.p2) return;

          const layout = this.$props.layout;
          const data1 = layout.c_magnet(this.p1[0]);
          const newp1_1 = data1.raw[2];
          const newp1 = [this.p1[0], newp1_1];

          const data2 = layout.c_magnet(this.p2[0]);
          const newp1_2 = data2.raw[2];
          const newp2 = [this.p2[0], newp1_2];
          const xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5);

          ctx.lineWidth = this.line_width;

          let x1 = layout.t2screen(newp1[0]);
          let y1 = layout.$2screen(newp1[1]);
          let x2 = layout.t2screen(newp2[0]);
          let y2 = layout.$2screen(newp2[1]);

          let priceRange = Math.abs(newp2[1] - newp1[1]);
          let fibLevels = [
            0, 0.236, 0.382, 0.5, 0.618, 1, 1.618, 2.618, 3.618, 4.236,
          ];
          let fibPercentages = [
            "0.000%",
            "23.600%",
            "38.200%",
            "50.000%",
            "61.800%",
            "100.000%",
            "161.800%",
            "261.800%",
            "361.800%",
            "423.600%",
          ];
          let textColors = [
            "#C3B4B4",
            "#F71010",
            "#E3F952",
            "#3EDA4D",
            "#3EDA81",
            "#3390B7",
            "#978034",
            "#8D1DCE",
            "#E828EF",
            "#752777",
          ];

          let previousY = y1;
          fibLevels.forEach((level, index) => {
            // this will get the actual price from the price / side bar
            let fibPrice =
              newp1[1] + level * priceRange * (newp2[1] > newp1[1] ? 1 : -1);
            let y = layout.$2screen(fibPrice);

            // Fill the background color between the previousY and the current y
            if (index < this.sett.backgroundColors.length) {
              this.horizontal(
                ctx,
                x1,
                previousY,
                x2,
                y,
                this.sett.backgroundColors[index]
              );
            }
            ctx.strokeStyle = this.line_color[index];
            ctx.beginPath();
            ctx.moveTo(x1, y);
            ctx.lineTo(x2, y);
            ctx.stroke();

            // Draw the price value at the current Fibonacci level
            this.drawPriceValues(
              ctx,
              x1,
              y,
              fibPrice,
              fibPercentages[index],
              textColors[index]
            );

            previousY = y;
          });

          // Render pins
          this.drawMagnetIcon(ctx);
          this.render_pins(ctx);
        };
      } else {
        this.draw = (ctx) => {
          if (!this.p1 || !this.p2) return;

          const layout = this.$props.layout;
          const xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5);

          ctx.lineWidth = this.line_width;

          let x1 = layout.t2screen(this.p1[0]);
          let y1 = layout.$2screen(this.p1[1]);
          let x2 = layout.t2screen(this.p2[0]);
          let y2 = layout.$2screen(this.p2[1]);

          let priceRange = Math.abs(this.p2[1] - this.p1[1]);
          let fibLevels = [
            0, 0.236, 0.382, 0.5, 0.618, 1, 1.618, 2.618, 3.618, 4.236,
          ];
          let fibPercentages = [
            "0.000%",
            "23.600%",
            "38.200%",
            "50.000%",
            "61.800%",
            "100.000%",
            "161.800%",
            "261.800%",
            "361.800%",
            "423.600%",
          ];
          let textColors = [
            "#C3B4B4",
            "#F71010",
            "#E3F952",
            "#3EDA4D",
            "#3EDA81",
            "#3390B7",
            "#978034",
            "#8D1DCE",
            "#E828EF",
            "#752777",
          ];

          let previousY = y1;
          fibLevels.forEach((level, index) => {
            // this will get the actual price from the price / side bar
            let fibPrice =
              this.p1[1] +
              level * priceRange * (this.p2[1] > this.p1[1] ? 1 : -1);
            let y = layout.$2screen(fibPrice);

            // Fill the background color between the previousY and the current y
            if (index < this.sett.backgroundColors.length) {
              this.horizontal(
                ctx,
                x1,
                previousY,
                x2,
                y,
                this.sett.backgroundColors[index]
              );
            }
            ctx.strokeStyle = this.line_color[index];
            ctx.beginPath();
            ctx.moveTo(x1, y);
            ctx.lineTo(x2, y);
            ctx.stroke();

            // Draw the price value at the current Fibonacci level
            this.drawPriceValues(
              ctx,
              x1,
              y,
              fibPrice,
              fibPercentages[index],
              textColors[index]
            );

            previousY = y;
          });

          // Render pins
          this.render_pins(ctx);

          this.collisions.push((x, y) => {
            return x >= x1 && x <= x2;
          });
        };
      }
    },
    // Draw horizontal lines and arrows
    horizontal(ctx, x1, yTop, x2, yBottom, backgroundColor) {
      const layout = this.$props.layout;
      const xdir = Math.sign(this.p2[0] - this.p1[0]);
      const ym =
        (layout.$2screen(this.p1[1]) + layout.$2screen(this.p2[1])) / 2;

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(x1, yTop, x2 - x1, yBottom - yTop);

      ctx.beginPath();

      ctx.moveTo(x2 - 5 * xdir, ym - 4);
      ctx.lineTo(x2, ym);
      ctx.lineTo(x2 - 5 * xdir, ym + 4);
    },

    // this gives you the correct price values equally to the horizontal values
    drawPriceValues(ctx, x, y, price, percentage, textColor) {
      if (!this.p1 || !this.p2) return;

      // Example: Draw price at p1
      ctx.font = "12px Arial";
      ctx.fillStyle = textColor;
      ctx.textAlign = "left";
      ctx.fillText(`${percentage} ( ${price.toFixed(2)} )`, x + -70, y + -8);
    },
    drawMagnetIcon(ctx) {
      // Calculate the midpoint of the line
      const layout = this.$props.layout;
      const x1 = layout.t2screen(this.p1[0]);
      const x2 = layout.t2screen(this.p2[0]);

      const data1 = layout.c_magnet(this.p1[0]);
      const newp1_1 = data1.raw[2];
      const data2 = layout.c_magnet(this.p2[0]);
      const newp1_2 = data2.raw[2];
      console.log(newp1_1, newp1_2);

      const y1 = layout.$2screen(newp1_1);
      const y2 = layout.$2screen(newp1_2);
      console.log(y1, y2);

      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2 - 8;
      const iconWidth = 8; // Width of the magnet
      const iconHeight = 3; // Height of the magnet

      // Adjust the Y position to place the magnet above the line
      const magnetY = midY;
      ctx.beginPath();

      // Draw the U shape of the magnet (Outer arc)
      ctx.moveTo(midX - iconWidth / 2, magnetY + iconHeight); // Start left side
      ctx.lineTo(midX - iconWidth / 2, magnetY); // Down left side
      ctx.arc(midX, magnetY + iconHeight, iconWidth / 2, Math.PI, 0, true); // Outer arc
      ctx.lineTo(midX + iconWidth / 2, magnetY + iconHeight); // Up right side

      // Draw the inner arc of the magnet
      const innerIconHeight = iconHeight * 0.8; // Adjust height for the inner arc
      ctx.moveTo(midX - (iconWidth / 2) * 0.4, magnetY); // Start left side (inner)
      ctx.lineTo(midX - (iconWidth / 2) * 0.4, magnetY); // Down left side (inner)
      ctx.arc(
        midX,
        magnetY + innerIconHeight,
        (iconWidth / 2) * 0.5,
        Math.PI,
        0,
        true
      ); // Inner arc
      ctx.lineTo(midX + (iconWidth / 2) * 0.4, magnetY); // Up right side (inner)

      // Draw the poles
      ctx.moveTo(midX - iconWidth / 2, magnetY + iconHeight); // Left pole
      ctx.lineTo(midX - iconWidth / 2, magnetY - iconHeight / 2);
      ctx.lineTo(midX - iconWidth / 4, magnetY - iconHeight / 2);
      ctx.lineTo(midX - iconWidth / 4, magnetY + iconHeight);

      ctx.moveTo(midX + iconWidth / 2, magnetY + iconHeight); // Right pole
      ctx.lineTo(midX + iconWidth / 2, magnetY - iconHeight / 2);
      ctx.lineTo(midX + iconWidth / 4, magnetY - iconHeight / 2);
      ctx.lineTo(midX + iconWidth / 4, magnetY + iconHeight);

      ctx.strokeStyle = this.color;
      ctx.lineWidth = 1;
      ctx.stroke();
    },
    // Define which tools this component should be used for
    use_for() {
      return ["FiboTool"];
    },

    // Define the colors for the data
    data_colors() {
      return [this.color];
    },
  },
  // Define internal settings and constants
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    line_color() {
      // if (this.$store.getters.getTheme) {
      //   return "#FFFFFF";
      // } else {
      //   return "#000000";
      // }
      return [
        "#BB67FF",
        "#8489FF",
        "#67BDFF",
        "#66FFFA",
        "#76FFB6",
        "#66FF67",
        "#B8FF66",
        "#FEF99F",
        "#FFCCA3",
        "#FFAF66",
        "#FF6666",
      ];
    },
    color() {
      return this.sett.color || this.$props.colors.cross;
    },
    back_color() {
      return this.sett.backColor || "#9b9ba316";
    },
    value_back() {
      return this.sett.valueBack || "#9b9ba316";
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text;
    },
    prec() {
      return this.sett.precision || 2;
    },
    new_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    price() {
      return "price" in this.sett ? this.sett.price : true;
    },
    time() {
      return "time" in this.sett ? this.sett.time : false;
    },
    shift() {
      return this.sett.shiftMode;
    },
  },
  data() {
    return {};
  },
};
</script>
