class Alert {
  constructor(symbol, price, status, title, message, trigger) {
    this.symbol = symbol;
    this.price = price;
    this.status = status;
    this.title = title;
    this.message = message;
    this.trigger = trigger;
  }
}

export default Alert;
