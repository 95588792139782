<template>
    <div class="soon">
        <p>Coming Soon</p>
    </div>
</template>
<script>
export default {
    name: 'ComingSoon',
}
</script>
<style>
.soon{
    width: fit-content;
    height: 100%;
    border: 1px solid gray;
    border-radius: 12px;
    background-color: transparent;
    border-radius: 12px;
    padding: 10px 70px 10px ;
    font-size: large;
    font-weight: 500;
    color: gray;
    margin: 170px auto 70px;
}
</style>