import store from "../../store/index.js";

let URL;

export async function fetchHistoricalDataFyers(
    symbol,
    resolution,
    data_format,
    range_from,
    range_to,
){  
    URL = `${process.env.VUE_APP_WORKER_URL}stocks?symbol=${symbol}&resolution=${resolution}&date_format=${data_format}&range_from=${range_from}&range_to=${range_to}&cont_flag=1`;

    const access_token = store.getters.getAccessToken;

    const response = await fetch(URL, {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'Authorization' : access_token
        }
    });

    return response.json();
}
