var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tradilent-vue-chart", style: _vm.styles },
    [
      _c("keyboard", { ref: "keyboard" }),
      _vm._v(" "),
      _vm._l(_vm._layout.grids, function (grid, i) {
        return _c("grid-section", {
          key: grid.id,
          ref: "sec",
          refInFor: true,
          attrs: {
            replay: _vm.replay,
            "select-candle": _vm.selectCandle,
            common: _vm.section_props(i),
            grid_id: i,
            tradingVueRef: _vm.tradingVueRef,
            magnet: _vm.magnet,
          },
          on: {
            "register-kb-listener": _vm.register_kb,
            "remove-kb-listener": _vm.remove_kb,
            "range-changed": _vm.range_changed,
            "cursor-changed": _vm.cursor_changed,
            "cursor-locked": _vm.cursor_locked,
            "sidebar-transform": _vm.set_ytransform,
            "layer-meta-props": _vm.layer_meta_props,
            "custom-event": _vm.emit_custom_event,
            "legend-button-click": _vm.legend_button_click,
          },
        })
      }),
      _vm._v(" "),
      _c(
        "botbar",
        _vm._b(
          {
            attrs: {
              last: _vm.last_candle,
              shaders: _vm.shaders,
              timezone: _vm.timezone,
            },
          },
          "botbar",
          _vm.botbar_props,
          false
        )
      ),
      _vm._v(" "),
      _c("replay-controls", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.replayActive,
            expression: "replayActive",
          },
        ],
        attrs: {
          replay: _vm.replay,
          "replay-active": _vm.replayActive,
          "replay-paused": _vm.replayPaused,
          "toggle-pause": _vm.togglePause,
          "cancel-replay": _vm.cancelReplay,
          "stop-replay": _vm.stopReplay,
          "set-wait": _vm.setWait,
          disabled: _vm.candle_till_replayed === undefined,
          width: _vm.$props.width,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }