var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      class: `tradilent-vue-grid-ux-${_vm.id}`,
      style: _vm.style,
      on: { mouseenter: _vm.showOptions, mouseleave: _vm.hideOptions },
    },
    _vm._l(_vm.uxs, function (ux) {
      return _c("ux-wrapper", {
        key: ux.uuid,
        attrs: {
          ux: ux,
          updater: _vm.updater,
          colors: _vm.colors,
          config: _vm.config,
        },
        on: { "custom-event": _vm.on_custom_event },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }