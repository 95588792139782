<template>
  <div
    :class="[
      'tradilent-vue-tbitem',
      !selected ? 'tradilent-vue-tbitem-hover' : '',
      selected ? 'selected-item' : '',
      replay ? 'disabled' : '',
    ]"
    @click="emit_selected('click')"
    @mousedown="mousedown"
    @touchstart="mousedown"
    @touchend="emit_selected('touch')"
    :style="item_style"
  >
    <div
      class="tradilent-vue-tbicon tvjs-pixelated"
      :style="icon_style"
      v-html="this.sub_item ? this.sub_item.icon : this.$props.data.icon"
    ></div>
    <div
      class="tradilent-vue-tbitem-exp"
      v-if="data.group"
      :style="exp_style"
      @click="exp_click"
      @mousedown="expmousedown"
      @mouseover="expmouseover"
      @mouseleave="expmouseleave"
    >
      ᐳ
    </div>
    <item-list
      :config="config"
      :items="data.items"
      v-if="show_exp_list"
      :colors="colors"
      :dc="dc"
      @close-list="close_list"
      @item-selected="emit_selected_sub"
    />
  </div>
</template>

<script>
import ItemList from "./ItemList.vue";
import Utils from "../stuff/utils.js";
// import Utils from "../icons";

export default {
  name: "ToolbarItem",
  props: [
    "replay",
    "data",
    "selected",
    "colors",
    "tv_id",
    "config",
    "dc",
    "subs",
  ],
  components: { ItemList },
  mounted() {
    if (this.data.group) {
      let type = this.subs[this.data.group];
      let item = this.data.items.find((x) => x.type === type);
      if (item) this.sub_item = item;
    }
  },
  methods: {
    mousedown(e) {
      //   if (this.replay) return; // Does not allow if replay is true
      this.click_start = Utils.now();
      this.click_id = setTimeout(() => {
        this.show_exp_list = true;
      }, this.config.TB_ICON_HOLD);
    },
    expmouseover() {
      this.exp_hover = true;
    },
    expmouseleave() {
      this.exp_hover = false;
    },
    expmousedown(e) {
      if (this.show_exp_list) e.stopPropagation();
    },
    emit_selected(src) {
      // if user is not login show popup
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.$store.commit("updateJoinUsPopup", true);
      }

      if (this.replay) return;
      if (Utils.now() - this.click_start > this.config.TB_ICON_HOLD) return;
      clearTimeout(this.click_id);
      //if (Utils.is_mobile && src === 'click') return
      // TODO: double firing
      if (!this.data.group) {
        this.$emit("item-selected", this.data);
      } else {
        let item = this.sub_item || this.data.items[0];
        this.$emit("item-selected", item);
      }
    },
    emit_selected_sub(item) {
      this.$emit("item-selected", item);
      this.sub_item = item;
    },
    exp_click(e) {
      if (!this.data.group) return;
      e.cancelBubble = true;
      this.show_exp_list = !this.show_exp_list;
    },
    close_list() {
      this.show_exp_list = false;
    },
  },
  computed: {
    item_style() {
      if (this.$props.data.type === "System:Splitter") {
        return this.splitter;
      }
      let conf = this.$props.config;
      let im = conf.TB_ITEM_M;
      let m = (conf.TOOLBAR - conf.TB_ICON) * 0.5 - im;
      let s = conf.TB_ICON + im * 2;
      let b = this.exp_hover ? 0 : 3;
      return {
        width: `${s}px`,
        height: `${s}px`,
        margin: `10px ${m}px 10px ${m}px`,
        "border-radius": `3px ${b}px ${b}px 3px`,
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      };
    },
    icon_style() {
      if (this.$props.data.type === "System:Splitter") {
        return {};
      }
      let conf = this.$props.config;
      let br = conf.TB_ICON_BRI;
      let sz = conf.TB_ICON;
      let im = conf.TB_ITEM_M;
      // The line `let ic = this.sub_item ? this.sub_item.icon : this..data.icon` is a conditional
      // statement that checks if `this.sub_item` exists. If `this.sub_item` is truthy (not null or
      // undefined), it assigns the value of `this.sub_item.icon` to the variable `ic`. Otherwise, it assigns
      // the value of `this..data.icon` to `ic`.
      let ic = this.sub_item ? this.sub_item.icon : this.$props.data.icon;
      return {
        // "background-image": `url(${ic})`,
        width: `22px`,
        height: `22px`,
        // margin: `${im}px`,
        // margin: "2px",
        filter: `brightness(${br})`,
        display: `flex`,
        "align-items": "center",
        "justify-content": "center",
      };
    },
    exp_style() {
      let conf = this.$props.config;
      let im = conf.TB_ITEM_M;
      let s = conf.TB_ICON * 0.5 + im;
      let p = (conf.TOOLBAR - s * 2) / 4;
      return {
        padding: `${s}px ${p}px`,
        transform: this.show_exp_list ? `scale(-0.6, 1)` : `scaleX(0.6)`,
      };
    },
    splitter() {
      let conf = this.$props.config;
      let colors = this.$props.colors;
      let c = colors.grid;
      let im = conf.TB_ITEM_M;
      let m = (conf.TOOLBAR - conf.TB_ICON) * 0.5 - im;
      let s = conf.TB_ICON + im * 2;
      return {
        width: `${s}px`,
        height: "1px",
        margin: `8px ${m}px 8px ${m}px`,
        "background-color": c,
      };
    },
  },
  data() {
    return {
      exp_hover: false,
      show_exp_list: false,
      sub_item: null,
    };
  },
};
</script>

<style>
.tradilent-vue-tbitem {
}

.tradilent-vue-tbitem-hover {
}

.tradilent-vue-tbitem-hover:hover {
  background-color: #76878319;
}

.tradilent-vue-tbitem.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.tradilent-vue-tbitem-exp {
  position: absolute;
  right: -3px;
  padding: 18.5px 5px;
  font-stretch: extra-condensed;
  transform: scaleX(0.6);
  font-size: 0.6em;
  opacity: 0;
  user-select: none;
  line-height: 0;
}

.tradilent-vue-tbitem:hover .tradilent-vue-tbitem-exp {
  opacity: 0.5;
}

.tradilent-vue-tbitem-exp:hover {
  background-color: #76878330;
  opacity: 0.9 !important;
}

.tradilent-vue-tbicon {
  position: absolute;
}

.tradilent-vue-tbitem.selected-item > .tradilent-vue-tbicon,
.tvjs-item-list-item.selected-item > .tradilent-vue-tbicon {
  background-color: #76878330;
  border-radius: 3px;
  /* filter: brightness(1.45) sepia(1) hue-rotate(90deg) saturate(4.5) !important; */
}

.tvjs-pixelated {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
</style>
