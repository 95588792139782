<template>
    <div class="page" v-if="getIsCustomTFSelectorOpen">
        <div :class="mainClass">

            <!-- close popup -->
            <button class="cross" @click="closeCustomTFSelector">X</button>

            <!-- Title -->
            <h1>Add custom interval ⌛</h1>
            <hr>

            <div class="inputType">
                <p>Type</p>
                <select v-model="selectedType" :class="selectorClass">
                    <option v-for="(tfType, i) in presetTFs" :key="i" :value="tfType">
                        {{ tfType }}
                    </option>
                </select>
            </div>

            <div class="inputInterval">
                <p>Interval</p>
                <input type="number" name="interval" id="interval" v-model="interval">
            </div>
            <p class="limitWarn" v-if="this.isShowWarn">{{ this.warner() }}
            </p>

            <hr>

            <div class="addCancelBtns">
                <button class="cancelBtn" @click="closeCustomTFSelector">Cancel</button>
                <button :disabled="this.isShowWarn" class="addBtn" @click="updateInterval">
                    <span>Add</span>
                </button>
            </div>



        </div>
    </div>
</template>

<script>

import CustomTimframeLimits from "../../../data/CustomTFLimits.json";

export default {
    name: 'CustomTFSelector',

    data() {
        return {
            selectedType: 'minutes',
            interval: null,
            isShowWarn: false,
            isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme
        }
    },
    computed: {
        presetTFs() {
            return ['seconds', 'minutes', 'hours', 'days'];
        },
        getTheme() {
            return this.$store.getters.getTheme;
        },
        getIsCustomTFSelectorOpen() {
            return this.$store.getters.getIsCustomTFSelectorOpen;
        },
        selectorClass() {
            return this.isDark ? "select-dark" : "select";
        },
        mainClass() {
            return this.isDark ? "main-dark" : "main";
        },
    },
    methods: {
        closeCustomTFSelector() {
            this.$store.commit('updateCustomTFSelectorOpen', false);
        },
        updateInterval() {
            if(this.interval === null || this.interval === ''){
                this.isShowWarn = true;
                return;
            }
            this.$store.commit("updateCustomTFMode", true);
            this.$store.commit("updateCustomTFSelected", this.selectedType);
            this.$store.commit("updateCustomTFInterval", this.interval);
            let CustomTFRegistry = {
                CustomTFMode: true,
                CustomTFSelected: this.selectedType,
                CustomTFInterval: this.interval
            };
            localStorage.setItem("customTFVault", JSON.stringify(CustomTFRegistry));

            // set the timeframe index = 0, so that in header dropdown user can see as `Custom Timeframe`
            this.$store.commit("updateTimeFrame", 0);
            // after updating, close the popup
            this.closeCustomTFSelector();
        },
        limitRepresenter(){
            return CustomTimframeLimits[this.selectedType];
        },
        warner() {
            let txt = '';
            switch (this.selectedType) {
                case 'seconds':
                    txt = `Please enter values like 5,10,15,20,25.... and values must be less than 60 sec!`
                    break;

                default:
                    txt = `Please enter input values below ${this.limitRepresenter()}!`
                    break;
            }
            return txt;
        },
    },
    watch: {

        // Show warning to the user if user exceed the timeframe limit.
        interval(newInterval, oldInterval) {
            let result = this.limitRepresenter();
            if (parseInt(newInterval) > parseInt(result)) {
                this.isShowWarn = true;
            }
            else if(this.selectedType === 'seconds' && parseInt(newInterval) % 5 !== 0){
                this.isShowWarn = true;
            }
            else {
                this.isShowWarn = false;
            }
        }
    }

}

</script>

<style scoped>
.page {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(50, 59, 40, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1005;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column
}

.main {
    width: 20%;
    height: auto;
    background-color: white;
    border-radius: 10px;
    position: relative;
    padding: 10px;
}

.main-dark {
    width: 20%;
    height: auto;
    background-color: #1e1e1e;
    border-radius: 10px;
    position: relative;
    padding: 10px;
}

.cross {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
    color: #aeb0b3;
}

.cross:hover {
    background-color: #76878319;
}

.select {
    background: transparent;
    border: none;
    outline: none;
}

.select-dark {
    background: transparent;
    border: none;
    outline: none;
    color: #ccc;
}

option {
    background: white;
    width: 70px;
}

.inputType,
.inputInterval {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.addCancelBtns {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.cancelBtn {
    --color: #0077ff;
    font-family: inherit;
    display: inline-block;
    width: 6em;
    height: 2.6em;
    line-height: 2.5em;
    overflow: hidden;
    cursor: pointer;
    margin: 20px;
    font-size: 10px;
    z-index: 1;
    color: var(--color);
    border: 2px solid var(--color);
    border-radius: 6px;
    position: relative;
}

.cancelBtn::before {
    position: absolute;
    content: "";
    background: var(--color);
    width: 150px;
    height: 200px;
    z-index: -1;
    border-radius: 50%;
}

.cancelBtn:hover {
    color: white;
}

.cancelBtn:before {
    top: 100%;
    left: 100%;
    transition: 0.3s all;
}

.cancelBtn:hover::before {
    top: -30px;
    left: -30px;
}

/* From Uiverse.io by adamgiebl */
.addBtn {
    position: relative;
    font-family: inherit;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.05em;
    border-radius: 0.8em;
    cursor: pointer;
    border: none;
    background: linear-gradient(to right, #8e2de2, #4a00e0);
    color: ghostwhite;
    overflow: hidden;
}

.addBtn svg {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
}

.addBtn span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
    display: inline-flex;
    align-items: center;
    padding: 0.8em 1.2em 0.8em 1.05em;
}

.addBtn::before,
.addBtn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.addBtn::before {
    content: "";
    background: #000;
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.addBtn:hover::before {
    transform: translate3d(100%, 0, 0);
}

.addBtn:active {
    transform: scale(0.95);
}

.limitWarn {
    color: red;
    font-size: medium;
    font-weight: 500;
}
</style>