import Vuex from "vuex";
import Vue from "vue";
import { getAccessToken } from "../api/fyers/auto_login/getAccessToken";

Vue.use(Vuex);
// Create a new store instance.
const store = new Vuex.Store({
  state() {
    return {
      currentPrice: undefined,

      //for right tool bar
      rightToolBarWidth: 56,
      isInfoOpen: false,
      toolsSection: 56,
      bottomBar: 35,
      showBar: JSON.parse(localStorage.getItem("showRightBar")) || false,

      // for time frame (numbers are indices )
      timeFrame: JSON.parse(localStorage.getItem("tf")) || 6,

      // for SymbolSearch
      selectedSymbol: "",
      selectedSymbolName: "",
      selectedSymbolExchange: "",
      assetType: localStorage.getItem("assetType") || "",
      symbolSearch: "",
      isSymbolSearchOpen: false, // make true by default for development of symbol search
      focusInput: false,
      isAdvertisementOpen: false,
      // access token of fyers
      access_token: "",

      //for theme
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || false,

      // if there is any input box of type text then use this isOtherInputSearchOpen because when isOtherInputSearchOpen this is false keyboard listener will open symbolSeearch
      isOtherInputSearchOpen: false,

      IsAccountSettingsOpen:
        JSON.parse(localStorage.getItem("isAccountSettingsPageOpen")) || false,

      // for signup popup
      JoinUsPopup: false,

      //for watchlist
      isWatchlistOpen: false,
      FocusInputOnWatchlist: false,
      selectedWatchlist: {},

      // socket connection
      socket: {},

      // custom timeframe popup
      CustomTFSelectorOpen: false,
      CustomTFMode: false,
      CustomTFSelected: "minutes",
      CustomTFInterval: 4,

      // for lock
      isLocked : false,
    };
  },
  mutations: {
    setCurrentPrice(state, price) {
      state.currentPrice = price;
    },

    // for user
    setUser(state, user) {
      state.user = user;
    },
    toggleShowBar(state, show) {
      localStorage.setItem("showRightBar", show);
      state.showBar = show;
    },
    //for right tool bar
    updateRightToolBarWidth(state, width) {
      // console.log(width)
      state.rightToolBarWidth = width;
    },
    toggleInfoOpen(state, isOpen) {
      state.isInfoOpen = isOpen;
    },
    updateBottomHeight(state, height) {
      state.bottomBar = height;
    },
    // for signup popup
    updateJoinUsPopup(state, bool) {
      console.log("show popup");
      state.JoinUsPopup = bool;
    },

    // for watchlist
    updateIsWatchlistOpen(state, bool) {
      console.log(bool);
      state.isWatchlistOpen = bool;
    },
    setFocusInputOnWatchlist(state, value) {
      state.focusInput = value;
    },
    setSelectedWatchlist(state, watchlist) {
      console.log(watchlist);
      localStorage.setItem(
        "watchlistSelected",
        JSON.stringify({ color: watchlist.color, name: watchlist.name })
      );
      state.selectedWatchlist = {
        color: watchlist.color,
        name: watchlist.name,
      };
    },

    // socket connection
    UpdateSocketConnection(state, socket) {
      state.socket = socket;
    },

    // for time frame
    updateTimeFrame(state, tf) {
      localStorage.setItem("tf", tf);
      state.timeFrame = tf;
    },

    // for SymbolSearch
    updateSymbolSearch(state, symbol) {
      state.symbolSearch = symbol;
    },
    updateIsSymbolSearchOpen(state, bool) {
      state.isSymbolSearchOpen = bool;
    },
    setFocusInput(state, value) {
      state.focusInput = value;
    },

    // user's selected symbol will be stored here
    updateSelectedSymbol(state, symbol) {
      state.selectedSymbol = symbol;
    },

    updateSelectedSymbolName(state, name) {
      state.selectedSymbolName = name;
    },

    updateSelectedSymbolExchange(state, exchange) {
      state.selectedSymbolExchange = exchange;
    },

    updateAssetType(state, type) {
      localStorage.setItem("assetType", type);
      state.assetType = type;
    },

    setAccessToken(state, token) {
      state.access_token = token;
    },

    // for theme
    toggleTheme(state, theme) {
      localStorage.setItem("themeIsDark", theme);
      state.isDark = theme;
    },

    updateIsOtherInputSearchOpen(state, bool) {
      state.isOtherInputSearchOpen = bool;
    },
    updateIsAccountSettingsOpen(state, bool) {
      localStorage.setItem("isAccountSettingsPageOpen", bool);
      state.IsAccountSettingsOpen = bool;
    },

    updateIsAdvertisementOpen(state, bool) {
      state.isAdvertisementOpen = bool;
    },

    updateCustomTFSelectorOpen(state, bool) {
      state.CustomTFSelectorOpen = bool;
    },

    updateCustomTFMode(state, bool) {
      state.CustomTFMode = bool;
    },

    updateCustomTFSelected(state, value) {
      state.CustomTFSelected = value;
    },

    updateCustomTFInterval(state, value) {
      state.CustomTFInterval = value;
    },

    // for lock
    updateIsLocked(state, bool) {
      localStorage.setItem("isLocked", bool);
      state.isLocked = bool;
    }

  },
  actions: {
    // for SymbolSearch
    triggerFocusInput({ commit }) {
      commit("setFocusInput", true);
    },

    async initializeAppData({ dispatch, commit, state }) {
      // Fetch access token if not already present
      if (!state.access_token) {
        const token = await getAccessToken();
        commit("setAccessToken", token);
      }

      // Set symbols vault
      await dispatch("setSymbolsVault");

      // Set time frame
      await dispatch("setTimeFrame");

      // Set asset type
      await dispatch("setAssetType");

      // Set custom timeframe vault
      await dispatch("setCustomTFVault");

      return {
        symbol: state.selectedSymbol,
        timeFrame: state.timeFrame,
        assetType: state.assetType,
        CustomTFMode: state.CustomTFMode,
        CustomTFSelected: state.CustomTFSelected,
        CustomTFInterval: state.CustomTFInterval,
      };
    },

    // intially set or retrive the symbol registry from the local storage and update.
    setSymbolsVault({ commit }) {
      return new Promise((resolve) => {
        let storedVault = localStorage.getItem("symbolVault");

        if (!storedVault) {
          let symbolRegistry = {
            tradingSymbol: "NSE:IDFC-EQ",
            name: "IDFC LIMITED",
            exchange: "NSE",
          };
          localStorage.setItem("symbolVault", JSON.stringify(symbolRegistry));
          storedVault = symbolRegistry;
        } else {
          storedVault = JSON.parse(storedVault);
        }
        commit("updateSelectedSymbol", storedVault.tradingSymbol);
        commit("updateSelectedSymbolName", storedVault.name);
        commit("updateSelectedSymbolExchange", storedVault.exchange);

        resolve(); // Resolve the promise when done
      });
    },

    setTimeFrame({ commit }) {
      return new Promise((resolve) => {
        let storedTF = localStorage.getItem("tf");

        if (!storedTF) {
          localStorage.setItem("tf", 6);
        } else {
          commit("updateTimeFrame", JSON.parse(storedTF));
        }

        resolve();
      });
    },

    setAssetType({ commit }) {
      return new Promise((resolve) => {
        let storedAssetType = localStorage.getItem("assetType");

        if (!storedAssetType) {
          let defaultType = "stock";
          localStorage.setItem("assetType", defaultType);
          commit("updateAssetType", defaultType); // Use the default value here
        } else {
          commit("updateAssetType", storedAssetType); // Use the string directly
        }

        resolve();
      });
    },

    setCustomTFVault({ commit }) {
      return new Promise((resolve) => {
        let storedVault = localStorage.getItem("customTFVault");

        if (!storedVault) {
          let CustomTFRegistry = {
            CustomTFMode: false,
            CustomTFSelected: "minutes",
            CustomTFInterval: 4,
          };
          localStorage.setItem(
            "customTFVault",
            JSON.stringify(CustomTFRegistry)
          );
          storedVault = CustomTFRegistry;
        } else {
          storedVault = JSON.parse(storedVault);
        }
        commit("updateCustomTFMode", storedVault.CustomTFMode);
        commit("updateCustomTFSelected", storedVault.CustomTFSelected);
        commit("updateCustomTFInterval", storedVault.CustomTFInterval);

        resolve();
      });
    },
  },
  getters: {
    getCurrentPrice: (state) => {
      return state.currentPrice;
    },

    // for user
    getUser: (state) => {
      return state.user;
    },

    //for right tool bar
    rightToolBarWidth: (state) => {
      return state.rightToolBarWidth;
    },
    isInfoOpen: (state) => {
      return state.isInfoOpen;
    },
    getBottomBarHeight: (state) => {
      return state.bottomBar;
    },

    getShowBar: (state) => {
      return state.showBar;
    },

    // for time frame
    getTimeFrame: (state) => {
      return state.timeFrame;
    },

    // for SymbolSearch
    getSymbolSearch: (state) => {
      return state.symbolSearch;
    },
    getIsSymbolSearchOpen: (state) => {
      return state.isSymbolSearchOpen;
    },
    getFocusInput: (state) => {
      return state.focusInput;
    },

    getSelectedSymbol: (state) => {
      return state.selectedSymbol;
    },

    getSelectedSymbolName: (state) => {
      return state.selectedSymbolName;
    },

    getAssetType: (state) => {
      return state.assetType;
    },

    getAccessToken: (state) => {
      return state.access_token;
    },

    // for theme
    getTheme: (state) => {
      return state.isDark;
    },

    getIsOtherInputSearchOpen: (state) => {
      return state.isOtherInputSearchOpen;
    },
    getIsAccountSettingsPageOpen: (state) => {
      return state.IsAccountSettingsOpen;
    },

    getIsAdvertisementOpen: (state) => {
      return state.isAdvertisementOpen;
    },
    // for signup popup
    getJoinUsPopup: (state) => {
      return state.JoinUsPopup;
    },

    //for watchlist
    getisWatchlistOpen: (state) => {
      return state.isWatchlistOpen;
    },
    getFocusInputOnWatchlist: (state) => {
      return state.FocusInputOnWatchlist;
    },
    getSelectedWatchlist: (state) => {
      return state.selectedWatchlist;
    },

    // for sockets
    getSocketConnection: (state) => {
      return state.socket;
    },

    getIsCustomTFSelectorOpen(state) {
      return state.CustomTFSelectorOpen;
    },

    getIsCustomTFMode(state) {
      return state.CustomTFMode;
    },

    getCustomTFSelected(state) {
      return state.CustomTFSelected;
    },

    getCustomTFInterval(state) {
      return state.CustomTFInterval;
    },

    // for lock
    getIsLockOpen(state) {
      return state.isLocked;
    },
  },
});

export default store;
