var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "popup" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "popup_content" }, [
        _c("img", {
          staticClass: "img",
          attrs: {
            src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722335596/pqtnvtamu9vwlnjkslnd.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "imgAb",
          attrs: {
            src: "https://res.cloudinary.com/dzd8l9wym/image/upload/v1722335651/klr4fayx6fsq1cytrrdf.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "signup" }, [
          _c(
            "button",
            { staticClass: "signinBtn", on: { click: _vm.handleSignUpPopup } },
            [_c("p", { staticClass: "signupText" }, [_vm._v("Signup")])]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "popup_header" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v("Do more "),
        _c("br"),
        _c("span", { staticClass: "titleColored" }, [_vm._v("with Signup.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }