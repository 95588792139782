<!-- 

🔸<div class="tradilent-vue-toolbar" :style="styles" :key="tool_count">: Defines a container for the toolbar with dynamic styles and a key for reactivity.
🔸v-for="(tool, i) in groups": Loops through each tool in the groups computed property.
🔸v-if="tool.icon && !tool.hidden": Only renders tools that have an icon and are not hidden.
🔸@item-selected="selected": Listens for the item-selected event and calls the selected method.
🔸:data="tool": Passes the tool data to the toolbar-item component.
🔸:subs="sub_map": Passes the sub-map for grouped tools.
🔸:dc="data": Passes the overall toolbar data.
🔸:config="config": Passes configuration settings.
🔸:colors="colors": Passes color settings.
🔸:selected="is_selected(tool)": Determines if the tool is currently selected. 

-->

<template>
  <div class="tradilent-vue-toolbar" :style="styles" :key="tool_count">
    <toolbar-item
      v-for="(tool, i) in groups"
      v-if="tool.icon && !tool.hidden && !isZoomOut(tool)"
      @item-selected="selected"
      :replay="$props.replay"
      :key="i"
      :data="tool"
      :subs="sub_map"
      :dc="data"
      :config="config"
      :colors="colors"
      :selected="is_selected(tool)"
    >
    </toolbar-item>

    <Lock/>
    <Profile></Profile>
  </div>
</template>

<script>
import ToolbarItem from "./ToolbarItem.vue";
import Profile from "./Profile.vue";
import Lock from "./overlays/Lock.vue";

export default {
  name: "Toolbar",
  components: { ToolbarItem, Profile, Lock },

  /*
          Props
          props: ['data', 'height', 'colors', 'tv_id', 'config']: Defines the props that the component accepts:
          data: Contains the tools and their state.
          height: The height of the toolbar.
          colors: Color settings.
          tv_id: An ID for the toolbar.
          config: Configuration settings.

      */
  props: ["replay", "data", "height", "colors", "tv_id", "config"],
  computed: {
    styles() {
      let colors = this.$props.colors;
      let b = this.$props.config.TB_BORDER;
      let w = this.$props.config.TOOLBAR - b;
      let c = colors.grid;
      let cb = colors.tbBack || colors.back;
      let brd = colors.tbBorder || colors.scale;
      let st = this.$props.config.TB_B_STYLE;
      return {
        width: `${w}px`,
        height: `${this.$props.height - 3}px`,
        "background-color": cb,
        "border-right": `${b}px ${st} ${brd}`,
      };
    },
    groups() {
      let arr = [];

      for (var tool of this.data.tools || []) {
        // console.log("tools are ", tool);
        if (!tool.group) {
          arr.push(tool);
          continue;
        }
        let g = arr.find((x) => x.group === tool.group);
        if (!g) {
          arr.push({
            group: tool.group,
            icon: tool.icon,
            items: [tool],
            // svg: tool.svg,
          });
        } else {
          g.items.push(tool);
        }
      }

      return arr;
    },
  },
  mounted() {
    this.$bus.$on("setZoomOutVisible", (val) => {
      this.zoomOutVisible = val;
    });
  },
  methods: {
    // toggleLock() {
    //   this.isLocked = !this.isLocked;
    //   this.$emit("isLocked", this.isLocked);
    // },
    isZoomOut(tool) {
      if (tool.type == "System:ZoomOut") {
        if (this.zoomOutVisible) {
          return false;
        }
        return true;
      } else return false;
    },
    isMagnet() {
      this.magnet = !this.magnet;
      console.log(this.magnet);

      this.$emit("magnet", this.magnet);
    },
    selected(tool) {
      // if (tool.type === "System:Lock") {
      //   this.toggleLock();
      // }
      if (tool.type === "System:ZoomOut") {
        this.$bus.$emit("getPreviousRange");
      }
      if (tool.type === "System:magnet") {
        console.log(tool.type);

        this.isMagnet();
      }

      this.$emit("custom-event", {
        event: "tool-selected",
        args: [tool.type],
      });
      if (tool.group) {
        // TODO: emit the sub map to DC (save)
        this.sub_map[tool.group] = tool.type;
      }
    },
    is_selected(tool) {
      if (tool.group) {
        return !!tool.items.find((x) => x.type === this.data.tool);
      }
      return tool.type === this.data.tool;
    },
  },
  watch: {
    data: {
      handler(n) {
        // For some reason Vue.js doesn't want to
        // update 'tools' automatically when new item
        // is pushed/removed. Yo, Vue, I herd you
        // you want more dirty tricks?
        if (n.tools) this.tool_count = n.tools.length;
      },
      deep: true,
    },
  },
  data() {
    return { tool_count: 0, sub_map: {}, zoomOutVisible: false, magnet: false };
  },
};
</script>

<style>
.tradilent-vue-toolbar {
  position: absolute;
  border-right: 1px solid black;
  z-index: 101;
  padding-top: 3px;
  user-select: none;
}
</style>
