<template>
  <div :class="headerClass" class="mainClass">
    <!-- static path for dev mode : ./Tradilent_logo.png -->
    <!-- static path for prod mode : /public/Tradilent_logo.png -->
    <img class="logo" src="/public/Tradilent_logo.png" alt="logo" />
    <div class="selector-div">
      <div class="selector" name="" id="" @click="openSymbolSearchPopup">
        {{ selectedSymbol }}
      </div>
    </div>
    <div class="divider-1"></div>
    <div class="scrollable-content">
      <div class="feature">
        <div class="timeframe" :class="{ disabled: replay }">
          <TFSelector></TFSelector>
        </div>
        <div class="divider-2"></div>
        <div class="alert-box">
          <svg
            width="20"
            pe
            height="20"
            stroke="currentColor"
            stroke-width="0.04"
            viewBox="0 0 0.63 0.99"
          >
            <path fill="none" d="M.52.02v.95M.11.02v.95"></path>
            <rect
              x=".02"
              y=".16"
              width=".19"
              height=".5"
              rx=".02"
              ry=".07"
              fill="currentColor"
            ></rect>
            <rect
              x=".42"
              y=".35"
              width=".19"
              height=".5"
              rx=".02"
              ry=".07"
              fill="currentColor"
            ></rect>
          </svg>
        </div>
        <div class="divider-2"></div>

        <div @click="this.legend_button_click" class="alert-box">
          <p :class="IndicatorClasses">Indicator</p>
        </div>
        <div class="divider-2"></div>
        <div class="alert-box">
          <div class="svg-container">
            <svg
              @click="$bus.$emit('show_alert_box')"
              class="bell-1"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.57731 2.47415C5.35245 1.53027 6.40377 1 7.5 1C8.59622 1 9.64753 1.53027 10.4227 2.47415C11.1978 3.41803 11.6333 4.6982 11.6333 6.03305C11.6333 6.81846 11.7398 7.55647 11.8999 8.31134C11.9447 8.48223 11.9942 8.64348 12.0473 8.79561C12.295 9.50511 12.977 9.74721 13.4859 10.2059C14.2455 10.8907 14.0986 12.3558 13.4668 12.9247C13.4668 12.9247 12.4467 14 7.5 14C2.55321 14 1.53314 12.9247 1.53314 12.9247C0.90135 12.3558 0.754497 10.8907 1.51407 10.2059C2.02295 9.74721 2.70493 9.50516 2.95269 8.79567C3.19965 8.08847 3.36669 7.1838 3.36669 6.03305C3.36669 4.6982 3.80216 3.41803 4.57731 2.47415Z"
                stroke="#9F9F9F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <svg
            class="bell-2"
            width="6"
            height="3"
            viewBox="0 0 6 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1C1.38229 1.59513 2.13454 2 2.99999 2C3.86546 2 4.61769 1.59513 5 1"
              stroke="#9F9F9F"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <a @click="$bus.$emit('show_alert_box')">
            <p class="alert-1">Alert</p>
          </a>
        </div>
        <div class="divider-2"></div>
        <div class="alert-box">
          <div
            class="replay"
            @click="replaySelected"
            :class="{ disabled: replayActive }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="none"
                stroke="currentColor"
                d="M13.5 20V9l-6 5.5 6 5.5zM21.5 20V9l-6 5.5 6 5.5z"
              ></path>
            </svg>
            <p class="alert">Replay</p>
          </div>
        </div>
      </div>

      <div class="sign-save-box">
        <!-- switch to toggle theme  -->
        <div class="switch-home">
          <label class="container">
            <input
              checked="checked"
              type="checkbox"
              v-model="isDark"
              @click="handleTheme"
            />
            <svg
              viewBox="0 0 384 512"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="moon"
            >
              <path
                d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"
              ></path>
            </svg>
            <svg
              viewBox="0 0 512 512"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="sun"
            >
              <path
                d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"
              ></path>
            </svg>
          </label>
        </div>

        <!-- vertical seperation line -->
        <div class="divider-3"></div>
        <div @click="handleFullScreen" class="fullscreen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="25"
            height="25"
          >
            <path
              fill="currentColor"
              d="M8.5 6A2.5 2.5 0 0 0 6 8.5V11h1V8.5C7 7.67 7.67 7 8.5 7H11V6H8.5zM6 17v2.5A2.5 2.5 0 0 0 8.5 22H11v-1H8.5A1.5 1.5 0 0 1 7 19.5V17H6zM19.5 7H17V6h2.5A2.5 2.5 0 0 1 22 8.5V11h-1V8.5c0-.83-.67-1.5-1.5-1.5zM22 19.5V17h-1v2.5c0 .83-.67 1.5-1.5 1.5H17v1h2.5a2.5 2.5 0 0 0 2.5-2.5z"
            ></path>
          </svg>
        </div>
        <div class="divider-3"></div>

        <!-- vertical seperation line -->

        <!-- switch to toggle display of right tool bar -->
        <div class="toggelRightToolBar">
          <div class="toggle-container">
            <input
              type="checkbox"
              class="toggle-input"
              v-model="isChecked"
              @click="handleRightBarDisplay"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 292 142"
              class="toggle"
            >
              <path
                d="M71 142C31.7878 142 0 110.212 0 71C0 31.7878 31.7878 0 71 0C110.212 0 119 30 146 30C173 30 182 0 221 0C260 0 292 31.7878 292 71C292 110.212 260.212 142 221 142C181.788 142 173 112 146 112C119 112 110.212 142 71 142Z"
                class="toggle-background"
              ></path>
              <rect
                rx="6"
                height="64"
                width="12"
                y="39"
                x="64"
                class="toggle-icon on"
              ></rect>
              <path
                d="M221 91C232.046 91 241 82.0457 241 71C241 59.9543 232.046 51 221 51C209.954 51 201 59.9543 201 71C201 82.0457 209.954 91 221 91ZM221 103C238.673 103 253 88.6731 253 71C253 53.3269 238.673 39 221 39C203.327 39 189 53.3269 189 71C189 88.6731 203.327 103 221 103Z"
                fill-rule="evenodd"
                class="toggle-icon off"
              ></path>
              <g filter="url('#goo')">
                <rect
                  fill="#fff"
                  rx="29"
                  height="58"
                  width="116"
                  y="42"
                  x="13"
                  class="toggle-circle-center"
                ></rect>
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="14"
                  class="toggle-circle left"
                ></rect>
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="164"
                  class="toggle-circle right"
                ></rect>
              </g>
              <filter id="goo">
                <feGaussianBlur
                  stdDeviation="10"
                  result="blur"
                  in="SourceGraphic"
                ></feGaussianBlur>
                <feColorMatrix
                  result="goo"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  mode="matrix"
                  in="blur"
                ></feColorMatrix>
              </filter>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TFSelector from "./Timeframes/TFSelector.vue";
import axios from "axios";

export default {
  name: "Header",
  components: { TFSelector },
  props: ["replay", "replaySelected", "replayActive"],
  data() {
    return {
      fullscreen: false,
      selectedTime: "",
      username: false,
      userEmail: "",
      showHoverComponent: false,
      isChecked: JSON.parse(localStorage.getItem("showRightBar")) || false,
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || false,
    };
  },
  async mounted() {
    if (!JSON.parse(localStorage.getItem("isLogin"))) {
      // making right side tool bar impossible to open without login
      this.isChecked = false;
    }
    this.$bus.$on("removeScreenShotComponent", this.handleMouseLeave);
    this.$bus.$on("handleFullScreen", this.handleFullScreen);
  },
  methods: {
    handleFullScreen() {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen", this.fullscreen);
    },
    handleMouseOver(showHoverComponent) {
      showHoverComponent = true;
      this.$bus.$emit("showScreenShot", showHoverComponent);
      this.$emit("passShowSreenShot", showHoverComponent);
    },
    handleMouseLeave(showHoverComponent) {
      showHoverComponent = false;
      this.$bus.$emit("showScreenShot", showHoverComponent);
      this.$emit("passshowSreenShot", showHoverComponent);
    },
    handleHideScreenShot() {
      console.log("heloo");
      console.log(this.handleHideScreenShot);
    },
    transferMOuseLeave(handleMouseLeave) {
      this.$bus.$emit("showScreenShot", handleMouseLeave);
    },
    cancle() {
      this.popup = false;
    },
    handleRightBarDisplay() {
      // if user is not login show popup
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.$store.commit("updateJoinUsPopup", true);
        return;
      }
      this.$store.commit("toggleShowBar", !this.isChecked);
    },
    openSymbolSearchPopup() {
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.$store.commit("updateJoinUsPopup", true);
        return;
      }
      this.$store.commit("updateIsSymbolSearchOpen", true);
    },
    handleTheme() {
      this.$store.commit("toggleTheme", !this.isDark);
    },
    legend_button_click() {
      let event = {
        button: "add",
        dataIndex: 0,
        grid: 0,
        overlay: "",
        type: "onchart",
      };
      this.$emit("legend-button-click", event);
    },
  },
  computed: {
    ...mapGetters(["getSelectedSymbol"]),
    selectedSymbol() {
      const symbol = this.getSelectedSymbol;
      if (!symbol) {
        return "";
      }

      if (symbol.includes(":")) {
        return symbol.split(":")[1].split("-")[0];
      } else if (!symbol.includes(":")) {
        return symbol;
      }
    },
    headerClass() {
      return {
        "tradilent-vue-header": true,
        "tradilent-vue-header-dark": this.isDark,
      };
    },
    IndicatorClasses() {
      return {
        timeframeSelector: true,
        "timeframeSelector-dark": this.isDark,
      };
    },
  },
};
</script>
<style>
/*------ Settings ------*/

.mainClass {
  background-color: whitesmoke;
}

.switch-home {
  margin-right: 5px;
  margin-left: 20px;
}
.container {
  --size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: var(--size);
  user-select: none;
  fill: var(--color);
}

.container .moon {
  fill: gray;
  position: absolute;
  animation: keyframes-fill 0.5s;
}

.container .sun {
  fill: orangered;
  position: absolute;
  display: none;
  animation: keyframes-fill 0.5s;
}

/* ------ On check event ------ */
.container input:checked ~ .moon {
  display: none;
}

.container input:checked ~ .sun {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    transform: translateY(-100%) rotate(-360deg) scale(0);
    opacity: 0;
  }

  /* 50% {
    transform: translateY(50%) rotate(0deg) scale(1);
    opacity: 1;
  } */

  100% {
    transform: translateY(0%) rotate(25deg) scale(1);
    opacity: 1;
  }
}

.logo {
  height: 100%;
  margin: 0px 10px;
}
.replay {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-container {
  --active-color: #9e69f5;
  --inactive-color: #d3d3d6;
  position: relative;
  aspect-ratio: 292 / 142;
  height: 1.875em;
}

.toggle-input {
  appearance: none;
  margin: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.toggle {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.toggle-background {
  fill: var(--inactive-color);
  transition: fill 0.4s;
}

.toggle-input:checked + .toggle .toggle-background {
  fill: var(--active-color);
}

.toggle-circle-center {
  transform-origin: center;
  transition: transform 0.6s;
}

.toggle-input:checked + .toggle .toggle-circle-center {
  transform: translateX(150px);
}

.toggle-circle {
  transform-origin: center;
  transition: transform 0.45s;
  backface-visibility: hidden;
}

.toggle-circle.left {
  transform: scale(1);
}

.toggle-input:checked + .toggle .toggle-circle.left {
  transform: scale(0);
}

.toggle-circle.right {
  transform: scale(0);
}

.toggle-input:checked + .toggle .toggle-circle.right {
  transform: scale(1);
}

.toggle-icon {
  transition: fill 0.4s;
}

.toggle-icon.on {
  fill: var(--inactive-color);
}

.toggle-input:checked + .toggle .toggle-icon.on {
  fill: #fff;
}

.toggle-icon.off {
  fill: #eaeaec;
}

.toggle-input:checked + .toggle .toggle-icon.off {
  fill: var(--active-color);
}

.selector-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; /* Prevent shrinking */
  font-size: 14px;
}
.selector {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.1cap solid #b7b7b7;
  outline-style: none;
  height: 32px;
  padding: 0px 15px;
  font-weight: bold;
  cursor: pointer;
}
.scrollable-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}
.divider-1 {
  width: 1px;
  height: 30px; /* Fixed height for better visibility */
  background-color: #b7b7b7;
  margin: 0 10px;
}
.divider-2 {
  width: 1px;
  height: 20px; /* Fixed height for better visibility */
  background-color: #b7b7b7;
  margin: 0 10px;
}
.divider-3 {
  width: 1px;
  height: 20px; /* Fixed height for better visibility */
  background-color: #b7b7b7;
  margin: 0 5px;
}
.left_box {
  display: flex;
}
.feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1cap solid #b7b7b7;
  border-radius: 8px;
  height: 32px;
}
.timeframe {
  margin-left: 10px;
}
.timeframeSelector {
  background-color: transparent;
  border: none;
  outline: none;
}
.timeframeSelector-dark {
  background-color: transparent;
  border: none;
  outline: none;
  color: #ccc;
}
.alert-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.alert {
  font-size: 14px;
  margin: 0px 10px 0px 1px;
}
.alert-1 {
  font-size: 14px;
  margin: auto;
}
.sign-save-box {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  gap: 10px;
}
.sign-up-box {
  width: 120px;
  height: 34px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(29, 0, 66, 1) 0%,
    rgba(118, 0, 255, 1) 100%
  );
  color: white;
}
.save {
  /* margin-left: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.fullscreen {
  margin-top: 2px;
}
.save-text {
  font-size: 14px;
}
.sign-up-box a {
  color: white;
  text-decoration: none;
}
.svg-container {
  position: relative;
}
.bell-1 {
  display: block;
}
.bell-2 {
  position: relative;
  margin-top: 15px;
  right: 10px;
}
.indicator-box-1 {
  position: relative;
}
.indicator-line-1 {
  position: absolute;
}

.tradilent-vue-header {
  height: 42px;
  border-bottom: 1px solid rgba(55, 50, 50, 0.273);
  display: flex;
  align-items: center;
  padding-right: 40px;
}
.tradilent-vue-header-dark {
  height: 42px;
  background-color: rgb(30, 30, 30);
  border-bottom: 1px solid rgba(55, 50, 50, 0.273);
  border-bottom: 1px solid #333;
  color: #ccc;
  display: flex;
  align-items: center;
  padding-right: 40px;
}

@media (max-width: 768px) {
  .tradilent-vue-header {
    padding-right: 10px;
  }
  .scrollable-content {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: thin; /* Firefox */
  }
  .sign-save-box {
    display: flex;
  }
  .save {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .sign-up-box {
    margin-left: 10px;
    width: auto;
    padding: 0 10px;
  }
  .scrollable-content {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow-x: auto;
  }
}
</style>
