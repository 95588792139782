let URL;

export async function fetchHistoricalDataWazirx(
  symbol,
  interval,
  startTime,
  endTime
) {
  // url after adding required qyries...
  URL = `${process.env.VUE_APP_WORKER_URL}crypto?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}`;
 
  const response = await fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
}
