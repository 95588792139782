<template>
  <div class="infoBar" :style="{ width: width - 56 + 'px' }" v-if="getIsInfoOpen">
    <!-- Header of info bar -->
    <div class="watchlist">
      <div class="header">
        <span class="title" @click="handleWatchlistPopup">{{ selectedWatchlist ? selectedWatchlist.name : "Watchlist" }}</span>
        <div class="header-icons">
          <button class=" icon" :class="headerBtnClass" @click="AddSymbolsInWatchlist">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
              width="28" height="28" fill="none">
              <path fill="currentColor" d="M7 13h7V6h1v7h7v1h-7v7h-1v-7H7v-1z"></path>
            </svg>
          </button>
          <button class=" icon" :class="headerBtnClass"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
              width="28" height="28" fill="none">
              <path d="M0 0h28v28H0z"></path>
              <path stroke="currentColor"
                d="M13.5 23.5a9 9 0 0 0 9-9h-9v-9a9 9 0 0 0 0 18zM22 6l-6.5 6.5m4-8l-4 4m8 0l-4 4"></path>
              <path stroke="currentColor" d="M24.5 12.5a9 9 0 0 0-9-9v9h9z"></path>
            </svg></button>
          <button class="icon" :class="headerBtnClass"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
              width="28" height="28">
              <path fill="currentColor"
                d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z">
              </path>
            </svg></button>
        </div>
      </div>

      <!-- Watchlist table -->
      <table>
        <thead>
          <tr class="tableHeader">
            <th>Symbol</th>
            <th>Last</th>
            <th>Chg</th>
            <th>Chg%</th>
          </tr>
        </thead>
      </table>


      <div v-if="watchlist.length <= 0" class="empty">
        <!-- <button class="BtnCSS" id="screeners">
          <p>Add Screener</p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12" fill="currentColor"
            focusable="false" preserveAspectRatio="none">
            <path d="M14 12l-4-6 4-6H0v12z"></path>
          </svg>
        </button> -->
        <button class="BtnCSS" id="watchlist" @click="handleWatchlistPopup">
          <p>Add Watchlist</p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12" fill="currentColor"
            focusable="false" preserveAspectRatio="none">
            <path d="M14 12l-4-6 4-6H0v12z"></path>
          </svg>
        </button>
      </div>
      <div v-else class="watchlist-table">
        <table>
          <tbody>
            <tr v-for="(item, index) in watchlist.symbols" :key="index" @click="selectSymbol(item)">
              <td class="tdSym">
                <span @click="removeSymbol(item)">
                  <svg :fill="selectedWatchlist.color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" width="14" height="12"
                    focusable="false" preserveAspectRatio="none">
                    <path d="M14 12l-4-6 4-6H0v12z"></path>
                  </svg>
                </span>
                {{ item }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>

    <!-- Bottom bar -->
    <div :class="bottomBarClass" :style="{ height: bottomBarHeight + 'px' }">
      <div class="resizeHandleBottom" @mousedown="onMouseDownBottomBar"></div>
      <div class="bottomBarHeader">
        <div class="bottomBarTitle">
          {{ selectedSymbol }} 
        </div>
        <div class="bottomBarHeaderRight">
          <button :class="headerBtnClass"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28"
              height="28">
              <path fill="currentColor" fill-rule="evenodd"
                d="M7 6h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1ZM5 7c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7Zm12-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1Zm-2 1c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V7Zm-4 9H7a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1Zm-4-1a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H7Zm10 1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Zm-2 1c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-4Z">
              </path>
            </svg></button>
          <button :class="headerBtnClass"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28"
              height="28">
              <path fill="currentColor"
                d="M15 6H7.5C6.67 6 6 6.67 6 7.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16h1v4.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 5 20.5v-13A2.5 2.5 0 0 1 7.5 5H15v1Z">
              </path>
              <path fill="currentColor"
                d="M22.41 5.7a2 2 0 0 0-2.82 0L11 14.3V18h3.7l8.6-8.59a2 2 0 0 0 0-2.82l-.89-.88Zm-2.12.71a1 1 0 0 1 1.42 0l.88.88a1 1 0 0 1 0 1.42l-.59.58L19.7 7l.6-.59Zm1 3.59-7 7H12v-2.3l7-7 2.3 2.3Z">
              </path>
            </svg></button>
          <button :class="headerBtnClass"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28"
              height="28">
              <path fill="currentColor"
                d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z">
              </path>
            </svg></button>
        </div>
      </div>
    </div>



    <!-- watchlist popup -->
    <div v-show="openWatchlistPopup" class="page">
      <div class="WatchlistPopup">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Data from '../../data/data.json';
import {SERVER_URL} from '../constant.js'

export default {
  name: "Watchlist",
  data() {
    return {
      width: 0,
      isResizing: false,
      startX: 0,
      startWidth: 0,
      isInfoOpen: false,
      showBar: JSON.parse(localStorage.getItem("showRightBar")) || this.getShowBar,

      // For bottom bar
      bottomBarHeight: 0,
      isResizingBottombar: false,
      startY: 0,
      startHeight: 0,
      bottomBarInfo: null,

      //for theme
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,

      // for watchlist
      selectedSymbol: "",
      watchlist: [],
      openWatchlistPopup: true,
      selectedWatchlist: JSON.parse(localStorage.getItem('watchlistSelected')) || this.getSelectedWatchlist,
    

      socket:{},
    };
  },
  computed: {
    getWidth() {
      return this.$store.getters.rightToolBarWidth;
    },
    getIsInfoOpen() {
      return this.$store.getters.isInfoOpen;
    },
    getBottomBarHeight() {
      return this.$store.getters.getBottomBarHeight;
    },
    getShowBar() {
      return this.$store.getters.getShowBar;
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
    barClass() {
      return this.isDark ? "bar-dark" : "bar";
    },
    headerBtnClass() {
      return this.isDark ? "headerBtn-dark" : "headerBtn";
    },
    bottomBarClass() {
      return this.isDark ? "bottomBar-dark" : "bottomBar";
    },
    getWatchlist() {
      return this.$store.getters.getWatchlist;
    },
    getSelectedWatchlist() {
      return this.$store.getters.getSelectedWatchlist;
    },
    // get socket from store
    getSocketConnection(){
          return this.$store.getters.getSocketConnection;
      }
  },
  methods: {
    AddSymbolsInWatchlist(){
      this.$store.commit('updateIsSymbolSearchOpen', true)
    },
    async removeSymbol(item){
      const url = `${SERVER_URL}/api/watchlist/removeSymbolFromWatchlist`
      const data = {
        email: localStorage.getItem('email'),
        symbol: item,
        color: this.selectedWatchlist.color,
      }
      await axios.post(url, data)
      .then((response) => {
        // console.log(response)
        this.watchlist = response.data.watchlist
        this.socket.emit('newListCreated', data)
      })
      .catch((error) => {
        console.log(error)
      })
      
    },
    handleWatchlistPopup(e) {
      e.preventDefault()
      this.openWatchlistPopup = true;
      this.$store.commit('updateIsWatchlistOpen', true)
      this.$store.commit('updateIsOtherInputSearchOpen', true)
      this.$store.dispatch('triggerFocusInputOnWatchlist');
    },
    onMouseDown(event) {
      this.isResizing = true;
      this.startX = event.clientX;
      this.startWidth = this.width;
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
      event.preventDefault(); // Prevent text selection
    },
    onMouseMove(event) {
      if (this.isResizing) {
        const delta = this.startX - event.clientX;
        this.width = this.startWidth + delta;
        if (this.width < 56) {
          // Minimum width
          this.width = 56;
        } else if (this.width < 256) {
          this.$store.commit("toggleInfoOpen", false);
          this.width = 56;
        } else if (this.width > 256 && this.width < window.innerWidth * 0.6) {
          this.$store.commit("toggleInfoOpen", true);
        } else if (this.width > window.innerWidth * 0.6) {
          // Maximum width is 60% of innerWidth
          this.width = window.innerWidth * 0.6;
        }
        this.$store.commit("updateRightToolBarWidth", this.width);
      }
    },
    onMouseUp() {
      if (this.isResizing) {
        this.isResizing = false;
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
      }
    },
    onMouseDownBottomBar(event) {
      this.isResizingBottombar = true;
      this.startY = event.clientY;
      this.startHeight = this.bottomBarHeight;
      document.addEventListener("mousemove", this.onMouseMoveBottomBar);
      document.addEventListener("mouseup", this.onMouseUpBottomBar);
      event.preventDefault(); // Prevent text selection
    },
    onMouseMoveBottomBar(event) {
      if (this.isResizingBottombar) {
        const delta = this.startY - event.clientY;
        this.bottomBarHeight = this.startHeight + delta;
        if (this.bottomBarHeight < 35) {
          // Minimum height
          this.bottomBarHeight = 35;
        }
        this.$store.commit("updateBottomHeight", this.bottomBarHeight);
      }
    },
    onMouseUpBottomBar() {
      if (this.isResizingBottombar) {
        this.isResizingBottombar = false;
        document.removeEventListener("mousemove", this.onMouseMoveBottomBar);
        document.removeEventListener("mouseup", this.onMouseUpBottomBar);
      }
    },
    handleInfoBar() {
      if (this.$store.getters.isInfoOpen) {
        this.$store.commit("toggleInfoOpen", false);
        this.width = 56;
        this.$store.commit("updateRightToolBarWidth", this.width);
      } else {
        this.$store.commit("toggleInfoOpen", true);
        this.width = 356;
        this.$store.commit("updateRightToolBarWidth", this.width);
      }
    },
    selectSymbol(symbol) {
      this.selectedSymbol = symbol;
      // console.log(symbol)
    },
    ToggleFutures() {
      this.isFuturesOpen = !this.isFuturesOpen;
    },
    ToggleForex() {
      this.isForexOpen = !this.isForexOpen;
    },
    ToggleCrypto() {
      this.isCryptoOpen = !this.isCryptoOpen;
    },
    InfoFromSymbol(name) {
      Data.watchlist.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      })
      Data.futures.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      })
      Data.forex.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      })
      Data.crypto.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      })
      // console.log(this.bottomBarInfo)
    }
  },
  async mounted() {
    // console.log("watchlist mounted")
    this.width = this.getWidth;
    this.bottomBarHeight = this.getBottomBarHeight;

    // this component mounts only when user is login
    const email = localStorage.getItem('email')
    // console.log(email)

    //send email to backend and get all watchlist of user
    const url = `${SERVER_URL}/api/watchlist/getWatchlist`
    const data = {
      email: email
    }
    await axios.post(url, data)
      .then((res) => {
        this.watchlist = res.data.watchlist
      })
      .catch((err) => {
        console.log(err)
      })

    if (this.selectedWatchlist.color.trim() != '') {
      this.watchlist = this.watchlist.find(item => item.color == this.selectedWatchlist.color)
      this.selectedSymbol = this.watchlist.symbols[0]
    }

    // socket code
    this.socket = this.getSocketConnection
      this.socket.on('newWatchlist', (data) => {
        // console.log(data.watchlist.symbols)
        if(this.selectedWatchlist.color == data.watchlist.color){
          this.watchlist = data.watchlist
          // console.log("sankalp", this.watchlist)
        }
      })

      this.socket.on('selectedWatchlistSymbols', (data) => {
        this.watchlist = data
      })
  },
  watch: {
    getWidth(newWidth) {
      this.width = newWidth;
    },
    getBottomBarHeight(newHeight) {
      this.bottomBarHeight = newHeight;
    },
    getShowBar(bool) {
      this.showBar = bool;
    },
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
    getSelectedWatchlist(newWatchlist) {
      this.selectedWatchlist = newWatchlist
    }
  },
};
</script>

<style scoped>
#screeners svg {
  fill: white;
}

#watchlist svg {
  fill: red;
}

.empty {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.BtnCSS {
  outline: none;
  border-radius: 8px;
  background-clip: border-box;
  position: relative;
  background: rgba(140, 69, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 3px 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 0 15px rgba(140, 69, 255);
  /* Inner shadow */
  cursor: pointer;
  color: white;
  gap: 10px;
}

.watchlist {
  width: calc(100% - 56px);
  margin-right: 56px;
  font-family: Arial, sans-serif;
}
.bottomBarTitle{
  margin-left: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 15px;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
}

.title:hover {
  cursor: pointer;
  background-color: #76878319;
}

.header-icons {
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tdSym {
  display: flex;
  align-items: center;
  gap: 2px
}

.tdImg {
  background-color: #e0e3eb;
  border-radius: 100%;
  padding: 5px;
  font-weight: bold;
  color: white;
}

tbody {
  width: 100%;
}

th {
  font-weight: bold;
  color: #888;
}

tr:hover {
  background-color: #ac86eb;
}

.tableHeader:hover {
  background-color: transparent;
}

.selected {
  border-left: 4px solid blue;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.categories {
  display: flex;
  flex-direction: column;
}

.category {
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category:hover {
  color: black;
}

.bar {
  border-top: 1px solid rgba(55, 50, 50, 0.273);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-top: 42px;
  display: flex;
  background-color: white;
  z-index: 1001;
}

.bar-dark {
  border-top: 1px solid rgba(55, 50, 50, 0.273);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-top: 42px;
  display: flex;
  background-color: #1e1e1e;
  z-index: 1001;
}

.toolBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid rgba(55, 50, 50, 0.273);
  z-index: 10;
}

.toolbarItem {
  margin-top: 15px;
  width: 45px;
  height: 45px;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarItem:hover {
  background-color: #76878319;
  cursor: pointer;
}

.resizeHandle {
  position: absolute;
  top: 0;
  left: -1px;
  width: 3px;
  height: 100%;
  cursor: ew-resize;
  z-index: 12;
  background-color: #ccc;
}

.infoBar {
  height: calc(100% - 70px);
  overflow-y: scroll;
  width: 200px;
  margin-top: 10px;
}

.header {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerBtn {
  padding: 1px 10px;
  border-radius: 10%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerBtn-dark {
  padding: 1px 10px;
  border-radius: 10%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}

.bottomBarHeaderRight {
  display: flex;
  align-items: center;
}

.headerBtn:hover {
  background-color: #76878319;
}

.headerBtn-dark:hover {
  background-color: #76878319;
}

.toggleBtn {
  height: 10px;
  width: 10px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.leftImg {
  height: 30%;
}

.leftImg:hover {
  height: 30%;
  animation: downArrow 1s ease-in-out forwards;
}

.bottomBar {
  position: absolute;
  bottom: 35px;
  height: 35px;
  width: calc(100% - 56px);
  background-color: #fff;
  margin-right: 56px;
}

.bottomBar-dark {
  position: absolute;
  bottom: 35px;
  height: 35px;
  width: calc(100% - 56px);
  background-color: #1e1e1e;
  margin-right: 56px;
}

.resizeHandleBottom {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 3px;
  cursor: ns-resize;
  z-index: 5;
  background-color: #ccc;
}

.bottomBarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.noRightToolBar {
  display: none;
}

@keyframes downArrow {
  0% {
    padding-top: 0px;
  }

  50% {
    padding-top: 5px;
  }

  100% {
    padding-top: 10px;
  }
}
</style>