<template>
    <div class="page">
        <div class="box">
            <div class="section1">
                <img class="logo" src="/public/your_logo_here.jpg" alt="brand logo">
                <div class="contents">
                    <p class="hookLine">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <p class="specifiedHookLine">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi sequi
                        nihil
                        commodi, cumque ex voluptatem officia quos provident.</p>
                </div>
            </div>
            <button class="visitBtn">Click here to visit.</button>

            <p class="disclaimer">Disclaimer : Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint aliquam
                repudiandae quidem. Et
                suscipit corrupti dicta rem ipsa aliquid accusamus, deleniti est tempore nisi aspernatur aperiam.
                Voluptatibus itaque corrupti consequuntur.</p>
            <p class="AD_txt">AD</p>
            <p class="close" @click="closeAdvertisement">X</p>

        </div>
    </div>
</template>

<script>
export default {
    name: 'AdTemplate',
    methods: {
        closeAdvertisement() {
            this.$store.commit('updateIsAdvertisementOpen', false);
        }
    }
}

</script>

<style scoped>
.page {
    width: fit-content;
    height: fit-content;
    position: fixed;
    bottom: 25px;
    left: 60px;
    z-index: 9999;
    /* margin-left: 10px; */
}

.box {
    position: relative;
    width: 500px;
    background-color: #373A40;
    height: fit-content;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: start;
}

.section1 {
    display: flex;
    justify-content: start;
    align-items: start;
}

.logo {
    width: 100px;
    height: 100px;
    border-top-left-radius: 12px;
    float: left;
    background-size: cover;
    margin-right: 5px;
}

.contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 4px;
    place-self: center;
}
p{
    margin: 0;
}

.hookLine {
    color: rgba(255, 255, 255, 0.93);
    font-weight: 600;
    font-size: 17px;
}

.specifiedHookLine {
    color: rgb(170, 170, 170);
    font-weight: 400;
    font-size: 12px;
}

.visitBtn {
    background-color: white;
    border-radius: 8px;
    padding: 5px 14px 5px;
    color: black;
    font-size: 14px;
    border: none;
    font-weight: 400;
    cursor: pointer;
}

.disclaimer {
    color: #939395;
    font-weight: 500;
    width: 90%;
}

.AD_txt {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: larger;
    background-color: rgb(99, 100, 101);
    color: white;
    border-radius: 8px;
    padding: 3px 10px 3px;
    margin: 6px;
    font-size: 12px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: larger;
    color: white;
    cursor: pointer;
    margin: 6px;
}

.close:hover {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: larger;
    color: white;
    background-color: #57575853;
}
</style>