import Math2 from "../../stuff/math.js";
import Utils from "../../stuff/utils.js";

export default class InfoLine {
  // Overlay ref, canvas ctx
  constructor(overlay, ctx) {
    this.ctx = ctx;
    this.comp = overlay;
    this.T = overlay.$props.config.TOOL_COLL;
    if (Utils.is_mobile) this.T *= 2;
  }

  // p1[t, $], p2[t, $] (time-price coordinates)
  draw(p1, p2, textColor) {
    const layout = this.comp.$props.layout;

    let x1 = layout.t2screen(p1[0]);
    let y1 = layout.$2screen(p1[1]);
    let x2 = layout.t2screen(p2[0]);
    let y2 = layout.$2screen(p2[1]);

    let priceRange = Math.abs(p2[1] - p1[1]);
    let slopeRadians = Math.atan((p2[1] - p1[1]) / (p2[0] - p1[0]));
    let slopeDegrees = slopeRadians * (180 / Math.PI); // Convert slope to degrees
    let bars = p2[0] - p1[0];

    // Draw the line between p1 and p2
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke();

    // Draw the price box
    this.drawPriceBox(
      this.ctx,
      x1,
      y1,
      x2,
      y2,
      slopeDegrees,
      priceRange,
      bars,
      textColor
    );

    // Add collision detection for this line
    this.comp.collisions.push(this.make([x1, y1], [x2, y2]));
  }

  drawPriceBox(ctx, x1, y1, x2, y2, slopeDegrees, priceRange, bars, textColor) {
    // Calculate position for the box
    const boxX = Math.min(x1, x2) - 75; // Position box slightly left of the midpoint
    const boxY = (y1 + y2) / 2 - 25; // Center the box vertically between y1 and y2

    // Draw the box and text
    ctx.font = "12px Arial";
    ctx.fillStyle = textColor;
    ctx.textAlign = "left";
    ctx.fillRect(boxX, boxY, 180, 55); // Background box for the text
    ctx.fillStyle = "#FFFFFF"; // Text color inside the box

    // Write all data in separate lines
    ctx.fillText(`Bars: ${bars}`, boxX + 5, boxY + 16);
    ctx.fillText(`Dist: ${priceRange.toFixed(2)}`, boxX + 5, boxY + 32);
    ctx.fillText(`Slope: ${slopeDegrees.toFixed(2)}°`, boxX + 5, boxY + 48);
  }

  // Collision function. x, y - mouse coordinates
  make(p1, p2) {
    return (x, y) => {
      return Math2.point2seg([x, y], p1, p2) < this.T;
    };
  }
}
