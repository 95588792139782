<template>
  <div
    class="floating-bar"
    :style="{
      top: positionX + 'px',
      left: positionY + 'px',
      'background-color': !$store.getters.getTheme
        ? '#dddddd'
        : 'rgb(45, 45, 45)',
    }"
  >
    <div
      class="floating-bar-item"
      :style="{
        'border-right': '0.1px solid rgb(67, 67, 67)',
      }"
      @dblclick="isDraggable = !isDraggable"
    >
      <svg
        aria-hidden="true"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          shape-rendering="auto"
          d="M7.5 6C8.05228 6 8.5 5.55228 8.5 5C8.5 4.44772 8.05228 4 7.5 4C6.94772 4 6.5 4.44772 6.5 5C6.5 5.55228 6.94772 6 7.5 6Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M7.5 11C8.05228 11 8.5 10.5523 8.5 10C8.5 9.44772 8.05228 9 7.5 9C6.94772 9 6.5 9.44772 6.5 10C6.5 10.5523 6.94772 11 7.5 11Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M8.5 15C8.5 15.5523 8.05228 16 7.5 16C6.94772 16 6.5 15.5523 6.5 15C6.5 14.4477 6.94772 14 7.5 14C8.05228 14 8.5 14.4477 8.5 15Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M12.5 6C13.0523 6 13.5 5.55228 13.5 5C13.5 4.44772 13.0523 4 12.5 4C11.9477 4 11.5 4.44772 11.5 5C11.5 5.55228 11.9477 6 12.5 6Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M13.5 10C13.5 10.5523 13.0523 11 12.5 11C11.9477 11 11.5 10.5523 11.5 10C11.5 9.44772 11.9477 9 12.5 9C13.0523 9 13.5 9.44772 13.5 10Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M12.5 16C13.0523 16 13.5 15.5523 13.5 15C13.5 14.4477 13.0523 14 12.5 14C11.9477 14 11.5 14.4477 11.5 15C11.5 15.5523 11.9477 16 12.5 16Z"
          fill="currentColor"
        />
      </svg>
    </div>

    <div class="floating-bar-item">
      <input
        type="color"
        class="color-picker"
        default="#42b28a"
        @input="onColorChange"
        v-model="currentColor"
      />
    </div>

    <div class="floating-bar-item" @click="toggleDropdown">
      <svg
        aria-hidden="true"
        width="30"
        height="30"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          shape-rendering="auto"
          d="M3.125 5H16.875V6.25H3.125V5Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M3.125 8.125H16.875V10.625H3.125V8.125Z"
          fill="currentColor"
        />
        <path
          shape-rendering="auto"
          d="M16.875 12.5H3.125V15.625H16.875V12.5Z"
          fill="currentColor"
        />
      </svg>
    </div>

    <div
      class="dropdown"
      v-show="showDropdown"
      :style="{
        'background-color': !$store.getters.getTheme
          ? '#dddddd'
          : 'rgb(45, 45, 45)',
      }"
    >
      <div
        class="dropdown-item"
        :class="{
          'bg-light': currentWidthSelected === 1 && $store.getters.getTheme,
          'bg-dark': currentWidthSelected === 1 && !$store.getters.getTheme,
        }"
        @click="setWidth(1)"
      >
        <div
          :style="{
            height: '1px',
            width: '80px',
          }"
          :class="{
            'bg-white': $store.getters.getTheme,
            'bg-black': !$store.getters.getTheme,
          }"
        />
      </div>
      <div
        class="dropdown-item"
        :class="{
          'bg-light': currentWidthSelected === 2 && $store.getters.getTheme,
          'bg-dark': currentWidthSelected === 2 && !$store.getters.getTheme,
        }"
        @click="setWidth(2)"
      >
        <div
          :style="{
            height: '2px',
            width: '80px',
          }"
          :class="{
            'bg-white': $store.getters.getTheme,
            'bg-black': !$store.getters.getTheme,
          }"
        />
      </div>
      <div
        class="dropdown-item"
        :class="{
          'bg-light': currentWidthSelected == 3 && $store.getters.getTheme,
          'bg-dark': currentWidthSelected === 3 && !$store.getters.getTheme,
        }"
        @click="setWidth(3)"
      >
        <div
          :style="{
            height: '3px',
            width: '80px',
          }"
          :class="{
            'bg-white': $store.getters.getTheme,
            'bg-black': !$store.getters.getTheme,
          }"
        />
      </div>
      <div
        class="dropdown-item"
        :class="{
          'bg-light': currentWidthSelected === 4 && $store.getters.getTheme,
          'bg-dark': currentWidthSelected === 4 && !$store.getters.getTheme,
        }"
        @click="setWidth(4)"
      >
        <div
          :style="{
            height: '4px',
            width: '80px',
          }"
          :class="{
            'bg-white': $store.getters.getTheme,
            'bg-black': !$store.getters.getTheme,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["setColor", "currentColor", "setLineWidth", "currentWidth"],
  data() {
    return {
      positionX: 200,
      positionY: 200,
      isDraggable: false,
      showDropdown: false,
      currentWidthSelected: 1,
    };
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.handleMouseMove);
  },
  mounted() {
    this.currentWidthSelected = this.$props.currentWidth;
    document.addEventListener("mousemove", (event) => {
      if (this.isDraggable) {
        this.positionX = event.clientY - 56;
        this.positionY = event.clientX - 56;
      }
    });
  },

  methods: {
    setWidth(val) {
      this.currentWidthSelected = val;
      this.setLineWidth(val);
    },
    onColorChange(event) {
      const newColor = event.target.value;
      this.setColor(newColor);
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style scope>
.floating-bar {
  width: 150px;
  height: 35px;
  /* background-color: rgb(45, 45, 45); */
  position: absolute;
  z-index: 10000;
  border-radius: 3px;
  display: flex;
  cursor: auto;
}
.floating-bar-item {
  height: 30px;
  width: 30px;
  margin: 2.5px;
}
.floating-bar-item :active {
  cursor: grabbing;
}

.color-picker {
  width: 30px;
  height: 30px;
  border: none; /* Removes the border */
  outline: none; /* Removes the outline when focused */
  padding: 0; /* Removes any padding */
  background: none;
  appearance: none;
}

.dropdown {
  position: absolute;
  top: 36px;
  left: 33px;
  width: 100px;
  height: 150px;
  background-color: rgb(45, 45, 45);
  z-index: 10000;
  border-radius: 5px;
}

.bg-white {
  background: white;
}
.bg-black {
  background-color: black;
}

.dropdown-item {
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-light {
  background-color: rgb(61, 61, 61);
}

.bg-dark {
  background-color: aliceblue;
}
</style>
