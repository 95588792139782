var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "context-menu", style: _vm.position }, [
    _c("div", { class: _vm.contextMenuClass }, [
      _c("button", { on: { click: _vm.handleSettingsClick } }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 28 28",
              width: "28",
              height: "28",
              fill: "currentColor",
            },
          },
          [
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                d: "M18 14a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-1 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                "fill-rule": "evenodd",
                d: "M8.5 5h11l5 9-5 9h-11l-5-9 5-9Zm-3.86 9L9.1 6h9.82l4.45 8-4.45 8H9.1l-4.45-8Z",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("p", { class: _vm.settingClass }, [_vm._v("Settings")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }