<template>
  <div class="container">
    <button class="faq-button" @click="handleShowPopup">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"
        ></path>
      </svg>
      <span class="tooltip">FAQ</span>
    </button>

    <div v-show="showPopup" class="popup">
      <div class="popup-content" ref="popup">
        <h2 class="titleText">
          Hi there <br />
          How can we help?
        </h2>
        <div class="buttons">
          <div class="group group1">
            <div class="left">
              <p class="text1">Send us a message</p>
              <p class="text2">We usually reply in a few minutes</p>
            </div>
            <div class="right">
              <img
                src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722467754/lt8bgmjxpd3377rnyfx2.png"
                alt=""
              />
            </div>
          </div>
          <div class="group group1">
            <div class="left">
              <p class="text1">Tradilent FAQ.</p>
            </div>
            <div class="right">
              <img
                src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722468015/fst7ccwwuxw3qvtmpkaq.png"
                alt=""
              />
            </div>
          </div>
          <div class="group group2">
            <div class="left">
              <p class="text1">Have a recommendation call</p>
              <p class="text3">
                We’d be happy to implement your recommendation, and add features
                you need. Help us improve with your recommendations by jumping
                on a call with us.
              </p>
              <button class="btn" @click="handleCall">
                Setup Recommendation call
              </button>
            </div>
          </div>
          <div class="group group3" @click="OpenTallyForm">
            <div class="left">
              <p class="text1">Leave your feedback</p>
              <p class="text2">
                We believe that you can help us improve with your feedback. Your
                opinion matters.
              </p>
            </div>
            <div class="right" >
              <img
                src="https://res.cloudinary.com/dzd8l9wym/image/upload/v1722468821/rlginjw2mvxcsvgh1zbv.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {
      email: "",
      showPopup: false,
    };
  },
  mounted() {
    this.email = localStorage.getItem("email");
  },
  methods: {
    OpenTallyForm(event) {
      const email = this.email;
      const baseUrl = "https://tally.so/r/woO4YN";
      const url = new URL(baseUrl);
      url.searchParams.append("email", email);
      event.preventDefault();

      window.open(url.href, "_blank");
    },
    handleShowPopup() {
      this.showPopup = !this.showPopup;
      if (this.showPopup) {
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleOutsideClick(event) {
      if (
        !this.$refs.popup.contains(event.target) &&
        !event.target.closest(".faq-button")
      ) {
        this.showPopup = false;
        document.removeEventListener("click", this.handleOutsideClick);
      }
    },
    handleCall(e) {
      const baseUrl = "https://calendly.com/tradilent_co/30min?month=2024-08";
      const url = new URL(baseUrl);

      e.preventDefault();
      window.open(url.href, "_blank");
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.container {
  position: fixed;
  bottom: 100px;
}

.popup {
  position: absolute;
  height: fit-content;
  width: 360px;
  bottom: 30px;
  right: 46px;
  /* z-index: 15; */
  background: linear-gradient(to bottom, #9e69f5, #fff);
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: end;
  cursor: cell;
  /* z-index: 9999; */
}

.popup-content {
  height: 90%;
  width: 90%;
  /* background-color: red; */
}

.titleText {
  color: white;
  text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  /* -webkit-text-stroke: 2px black;
    color: white; */
}

.buttons {
  margin-top: 20px;
}

.group {
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin: 0;
  padding: 0;
  line-height: 5px;
  border-radius: 8px;
  padding: 10px;
  margin: 15px 0px;
  /* cursor: pointer; */
}

.group1 {
  display: flex;
  justify-content: space-between;
}
.group3 {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.text1 {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.text2 {
  font-size: 11px;
  color: #646866;
  line-height: 15px;
}

.text3 {
  font-size: 11px;
  color: #646866;
  line-height: 15px;
  padding-right: 30px;
}

.right {
  display: flex;
  /* justify-content: start; */
  align-items: center;
  cursor: pointer;
}

.faq-button {
  width: 20px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ac86eb 0%, #9e69f5 74%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.151);
  position: relative;
}

.faq-button svg {
  height: 1em;
  fill: white;
}

.faq-button:hover svg {
  animation: jello-vertical 0.7s both;
}

.btn {
  background-color: transparent;
  border: 1px solid black;
  color: black;
  width: 95%;
  height: 40px;
  font-size: 13px;
  border-radius: 8px;
  cursor: pointer;
}

.btn:hover {
  background-color: black;
  border: 1px solid black;
  color: white;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(0.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, 0.75, 1);
  }

  50% {
    transform: scale3d(0.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, 0.95, 1);
  }

  75% {
    transform: scale3d(0.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.tooltip {
  position: absolute;
  top: -20px;
  opacity: 0;
  background-color: #ffe53b;
  background-image: linear-gradient(147deg, #ac86eb 0%, #9e69f5 74%);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ff2525;
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  bottom: -15%;
  transition-duration: 0.3s;
}

.faq-button:hover .tooltip {
  top: -40px;
  opacity: 1;
  transition-duration: 0.3s;
}
</style>
