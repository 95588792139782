var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "tradilent-vue-ux-wrapper",
          style: _vm.style,
          attrs: { id: `tvjs-ux-wrapper-${_vm.ux.uuid}` },
        },
        [
          _c(_vm.ux.component, {
            tag: "component",
            attrs: {
              colors: _vm.colors,
              ux: _vm.ux,
              updater: _vm.updater,
              wrapper: _vm.wrapper,
            },
            on: { "custom-event": _vm.on_custom_event },
          }),
          _vm._v(" "),
          _vm.ux.show_pin
            ? _c("div", {
                staticClass: "tvjs-ux-wrapper-pin",
                style: _vm.pin_style,
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.ux.win_header !== false
            ? _c("div", { staticClass: "tvjs-ux-wrapper-head" }, [
                _c(
                  "div",
                  {
                    staticClass: "tvjs-ux-wrapper-close",
                    style: _vm.btn_style,
                    on: { click: _vm.close },
                  },
                  [_vm._v("\n      ×\n    ")]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }