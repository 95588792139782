var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _vm._v(_vm._s(_vm.$props.title)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("blockquote", { staticClass: "blockquote mb-0" }, [
        _c("p", [_vm._v(_vm._s(_vm.$props.message))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }