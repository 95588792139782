<template>
    <div class="page">
        <div class="header">
            <span>
                <img :src="logo" alt="logo" class="logo">
            </span>
            <span class="middle">
                <span>Blogs</span>
                <span>Charts</span>
            </span>
            <span class="last">
                <button class="BtnCSS" @click="openTradilent">
                    <p>Charts</p>
                </button>
                <button class="BtnCSS" @click="copyLink">
                    <p>Copy Link</p>
                </button>
            </span>
        </div>
        <div class="main">
            <p class="name">{{ video.name }}</p>
            <video :src="video.videoUrl" autoplay controls muted class="video" />
        </div>

        <div class="previousVideos" v-if="videos.length > 0">
            <div class="headerVideo">
                <p class="title">My Videos</p>
                <p class="more" @click="openUserVideos">View more</p>
            </div>
            <div class="videosContainer">
                <div class="videoes" v-for="(video, index) in videos" :key="index">
                    <button class="play" @click="playVideo(video)">
                        <i class="fas fa-play"></i>
                    </button>
                    <button class="options" @click="toggleOptions(index)">
                        <div>
                            <i class="fas fa-ellipsis-h"></i>
                        </div>
                    </button>
                    <div v-if="openOptionsIndex === index" class="options-menu">
                        <ul>
                            <li @click.stop="downloadVideo(video)">Download</li>
                            <li @click.stop="editVideo(video)">Edit</li>
                            <li @click.stop="copyLinkforVideo(video)">Copy Link</li>
                            <li @click.stop="DeleteVideo(video)">Delete</li>
                        </ul>
                        <!-- Add this block to handle editing -->
                    </div>
                    <div v-if="editingIndex === index" class="edit-container">
                        <input v-model="editName" :placeholder="video.name" />
                        <button @click="saveEdit(index)">Save</button>
                    </div>
                    <img :src="frames[index]" alt="Video Frame" />
                    <p class="names">{{ video.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Icons from "../stuff/icons.json";
import { SERVER_URL, FRONTEND_URL } from '../constant.js'

export default {
    name: "CopiedLink",
    components: {
    },
    data() {
        return {
            id: this.$route.query.id,
            video: null,
            logo: Icons["logo.png"],
            videos: [],
            frames: [],
            openOptionsIndex: null,
            editingIndex: null, // Track which video is being edited
            editName: '', // Store the edited name
        };
    },
    async mounted() {
        try {
            const response = await axios.get(`${SERVER_URL}/api/video?id=${this.id}`);
            this.video = response.data.data.video;

            const res = await axios.post(`${SERVER_URL}/api/getLastFiveVideos`, {
                "email": localStorage.getItem('email')
            });
            this.videos = res.data.data.videos;
        } catch (error) {
            console.error("Error fetching video:", error);
        }

        this.videos.forEach((video, index) => {
            this.captureFrameAtTime(video.videoUrl, 1, index);
        });
    },
    methods: {
        async DeleteVideo(video) {
            console.log(video)
            const res = await axios.post(`${SERVER_URL}/api/deleteVideo`, {
                videoId: video._id,
                email: localStorage.getItem('email')
            })

            console.log(res)
        },
        openUserVideos() {
            window.open(`${FRONTEND_URL}/user`)
        },
        openTradilent() {
            console.log("first")
            window.open('https://charts.tradilent.co', '_blank');
        },
        copyLink() {
            navigator.clipboard.writeText(window.location.href).then(() => {
                this.$toast('link copied', {
                    toastClassName: "my-custom-toast-class",
                    bodyClassName: ["custom-class-1", "custom-class-2"]
                })
            }).catch(err => {
                console.error("Failed to copy link:", err);
            });
        },
        launchTradingVue() {
            window.open("/", "_blank");
        },
        playVideo(video) {
            this.$router.push({ query: { ...this.$route.query, id: video._id } });
            window.location.reload();
        },
        toggleOptions(index) {
            this.openOptionsIndex = this.openOptionsIndex === index ? null : index;
        },
        async downloadVideo(video) {
            console.log('Download:', video);
            try {
                const response = await fetch(video.videoUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = `${video.name || 'video'}.mp4`; // Default name to 'video.mp4'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url); // Clean up the URL object
            } catch (error) {
                console.error('Failed to download video:', error);
            }
        },
        editVideo(video) {
            this.editingIndex = this.editingIndex === this.videos.indexOf(video) ? null : this.videos.indexOf(video);
            this.editName = video.name; // Set the initial value of the input
        },
        async saveEdit(index) {
            console.log(this.editingIndex + " " + index);
            if (this.editingIndex === index) {
                try {
                    console.log('New name:', this.editName);
                    const res = await axios.post(`${SERVER_URL}/api/editVideoName`, {
                        id: this.videos[index]._id,
                        name: this.editName
                    });
                    console.log(res);

                    if (res.status === 200) { // Check for a successful response
                        window.location.reload(); // Reload the window
                    }
                } catch (error) {
                    console.error('Error saving video name:', error);
                }
            }
        },
        copyLinkforVideo(video) {
            const link = `${window.location.origin}${this.$router.resolve({ query: { ...this.$route.query, id: video._id } }).href}`;
            navigator.clipboard.writeText(link).then(() => {
                this.$toast('link copied', {
                    toastClassName: "my-custom-toast-class",
                    bodyClassName: ["custom-class-1", "custom-class-2"]
                })
            });
        },
        captureFrameAtTime(videoSrc, timeInSeconds, index) {
            const video = document.createElement("video");
            video.src = videoSrc;
            video.crossOrigin = "anonymous";
            video.preload = "auto";
            video.muted = true;

            video.addEventListener("loadeddata", () => {
                video.currentTime = timeInSeconds;
            });

            video.addEventListener("seeked", () => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const ctx = canvas.getContext("2d");
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const frameSrc = canvas.toDataURL("image/png");
                this.$set(this.frames, index, frameSrc);
            });
        }
    }
};

</script>

<style scoped>
.headerVideo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
}

.more {
    color: white;
    cursor: pointer;
}

.edit-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: absolute;
}

.edit-container input {
    margin-right: 10px;
}

.name {
    color: white;
    font-size: 24px;
}

.videos {
    display: flex;
}

.title {
    font-size: 24px;
    color: white;
}

.previousVideos {
    /* height: 100vh; */
    width: 90vw;
    /* display: flex; */
    margin: 200px auto 100px auto;
}

.video {
    width: 100%;
    border-radius: 8px;
}

.main {
    /* display: flex; */
    /* height: 70%; */
    width: 70%;
    margin: 50px auto;
}

.last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.BtnCSS {
    outline: none;
    border-radius: 8px;
    background-clip: border-box;
    position: relative;
    background: rgba(140, 69, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 3px 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 0 15px rgba(140, 69, 255);
    /* Inner shadow */
    cursor: pointer;
    color: white;
    gap: 10px;
}

.middle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 50px;
    border: 1px solid rgba(256, 256, 256, 0.15);
    border-radius: 30px;
    gap: 30px;
    color: rgba(256, 256, 256, 0.6);
}

.logo {
    width: 50px;
    height: 50px;
}

.header {
    height: 68px;
    width: 90%;
    background-color: transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px auto;
    border-bottom: 1px solid rgba(256, 256, 256, 0.15);
}

* {
    margin: 0;
    padding: 0;
}

.page {
    background-color: black;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
}

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.fa-play {
    font-size: 50px;
    color: #fff;
}

.options {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    visibility: hidden;
    padding: 2px 5px;
}

.videoes:hover .options {
    visibility: visible;
}

.options-menu {
    position: absolute;
    top: 30px;
    right: 5px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.options-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.options-menu li {
    padding: 10px 20px;
    cursor: pointer;
}

.options-menu li:hover {
    background-color: #f0f0f0;
}

.names {
    font-size: 20px;
    font-weight: bold;
    color: white;
    position: absolute;
    bottom: 0px;
}

.videosContainer {
    display: flex;
    flex-wrap: wrap;
}

.videoes {
    height: 300px;
    width: 200px;
    margin: 10px;
    position: relative;
    background-color: black;
    box-sizing: border-box;
    box-shadow: 0px -15px 60px 5px rgba(0, 0, 0, 0.5);
    border: 8px;
}

@media (max-width: 900px) {
    .videoes {
        height: 300px;
        width: 250px;
    }
}

@media (max-width: 500px) {
    .videoes {
        height: 300px;
        width: 450px;
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>