<template>
  <div class="card">
    <div class="card-header">{{ $props.title }}</div>
    <div class="card-body">
      <blockquote class="blockquote mb-0">
        <p>{{ $props.message }}</p>
      </blockquote>
    </div>
  </div>
</template>

<script>
// import "bootstrap/dist/css/bootstrap.min.css";
export default {
  props: ["title", "message"],
};
</script>

<style scoped>
</style>
