<template>
    <div>
        <h3 class="text">{{ user.fullName }} <br> total video : {{ user.videos.length }}</h3>
        <div class="videosContainer">
            <div class="videoes" v-for="(video, index) in videos" :key="index">
                <button class="play" @click="playVideo(video)">
                    <i class="fas fa-play"></i>
                </button>
                <button class="options" @click="toggleOptions(index)">
                    <div>
                        <i class="fas fa-ellipsis-h"></i>
                    </div>
                </button>
                <div v-if="openOptionsIndex === index" class="options-menu">
                    <ul>
                        <li @click.stop="downloadVideo(video)">Download</li>
                        <li @click.stop="editVideo(video)">Edit</li>
                        <li @click.stop="copyLinkforVideo(video)">Copy Link</li>
                        <li @click.stop="DeleteVideo(video)">Delete</li>
                    </ul>
                </div>
                <div v-if="editingIndex === index" class="edit-container">
                    <input v-model="editName" :placeholder="video.name" />
                    <button @click="saveEdit(index)">Save</button>
                </div>
                <img :src="frames[index]" alt="Video Frame" />
                <p class="names">{{ video.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { FRONTEND_URL, SERVER_URL } from '../constant.js'

export default {
    data() {
        return {
            videos: [],
            frames: [],
            openOptionsIndex: null,
            editingIndex: null,
            editName: '',
            user: {},
        };
    },
    async mounted() {
        try {
            const user = await axios.post(`${SERVER_URL}/api/getUser`, {
                email: localStorage.getItem('email')
            })
            this.user = user.data.user
            console.log(this.user)
            const res = await axios.post(`${SERVER_URL}/api/getAllVideos`, {
                email: localStorage.getItem('email')
            });
            this.videos = res.data.data.videos;
            this.videos.forEach((video, index) => {
                this.captureFrameAtTime(video.videoUrl, 1, index);
            });
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    },
    methods: {
        async DeleteVideo(video) {
            console.log(video)
            const res = await axios.post(`${SERVER_URL}/api/deleteVideo`, {
                videoId: video._id,
                email: localStorage.getItem('email')
            })

            console.log(res)
        },
        playVideo(video) {
            window.open(`${FRONTEND_URL}/video?id=${video._id}`)
            window.location.reload();
        },
        toggleOptions(index) {
            this.openOptionsIndex = this.openOptionsIndex === index ? null : index;
        },
        async downloadVideo(video) {
            try {
                const response = await fetch(video.videoUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = `${video.name || 'video'}.mp4`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Failed to download video:', error);
            }
        },
        editVideo(video) {
            this.editingIndex = this.videos.indexOf(video);
            this.editName = video.name;
        },
        async saveEdit(index) {
            if (this.editingIndex === index) {
                try {
                    const res = await axios.post(`${SERVER_URL}/api/editVideoName`, {
                        id: this.videos[index]._id,
                        name: this.editName
                    });

                    if (res.status === 200) {
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Error saving video name:', error);
                }
            }
        },
        copyLinkforVideo(video) {
            const link = `${FRONTEND_URL}/video?id=${video._id}`;
            navigator.clipboard.writeText(link).then(() => {
                this.$toast('link copied', {
                    toastClassName: "my-custom-toast-class",
                    bodyClassName: ["custom-class-1", "custom-class-2"]
                });
            }).catch(err => {
                console.error("Failed to copy link:", err);
            });
        },
        captureFrameAtTime(videoSrc, timeInSeconds, index) {
            const video = document.createElement("video");
            video.src = videoSrc;
            video.crossOrigin = "anonymous";
            video.preload = "auto";
            video.muted = true;

            video.addEventListener("loadeddata", () => {
                video.currentTime = timeInSeconds;
            });

            video.addEventListener("seeked", () => {
                const canvas = document.createElement("canvas");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                const ctx = canvas.getContext("2d");
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const frameSrc = canvas.toDataURL("image/png");
                this.$set(this.frames, index, frameSrc);
            });
        }
    }
};
</script>

<style scoped>
.text {
    color: white;
    text-align: center;
}

.edit-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: absolute;
}

.edit-container input {
    margin-right: 10px;
}

.names {
    font-size: 20px;
    font-weight: bold;
    color: white;
    position: absolute;
    bottom: 0px;
}

.videoes {
    height: 400px;
    width: 300px;
    margin: 10px;
    position: relative;
    background-color: black;
    box-shadow: 0px -15px 60px 5px rgba(0, 0, 0, 0.5);
}

.videosContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 90vh;
    /* Set a max-height */
    overflow-y: auto;
    /* Enable scrolling */
}

.options {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    visibility: hidden;
    padding: 2px 5px;
}

.videoes:hover .options {
    visibility: visible;
}

.options-menu {
    position: absolute;
    top: 30px;
    right: 5px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.options-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.options-menu li {
    padding: 10px 20px;
    cursor: pointer;
}

.options-menu li:hover {
    background-color: #f0f0f0;
}

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.fa-play {
    font-size: 50px;
    color: #fff;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
