// Usuful stuff for creating tools. Include as mixin

import Keys from "../stuff/keys.js";
import Utils from "../stuff/utils.js";

export default {
  methods: {
    init_tool() {
      // Collision functions (float, float) => bool,
      this.collisions = [];
      this.pins = [];
      this.other = [];

      // Mouse move event listener
      this.mouse.on("mousemove", (e) => {
        // Check if mouse is colliding with any pin
        if (this.collisions.some((f) => f(this.mouse.x, this.mouse.y))) {
          this.show_pins = true;
        } else {
          this.show_pins = false;
        }
        // Update drag if in progress

        // for lock feature this line should not work
        const locked = JSON.parse(localStorage.getItem('isLocked'))
        if(locked != true){
          if (this.drag) this.drag_update();
        }
      });

      // Mouse down event listener
      this.mouse.on("mousedown", (e) => {
        if (Utils.default_prevented(e)) return;
        if (this.collisions.some((f) => f(this.mouse.x, this.mouse.y))) {
          if (!this.selected) {
            this.$emit("object-selected");
          }
          if (this.floatingBarVisible != undefined)
            this.floatingBarVisible = true;
          this.start_drag();
          e.preventDefault();
          this.pins.forEach((x) => x.mousedown(e, true));
        } else {
          if (this.floatingBarVisible != undefined)
            this.floatingBarVisible = false;
        }
      });

      // Mouse up event listener
      this.mouse.on("mouseup", (e) => {
        this.drag = null;
        this.$emit("scroll-lock", false);
      });

      // Initialize keyboard event listeners for delete actions
      this.keys = new Keys(this);
      this.keys.on("Delete", this.remove_tool);
      this.keys.on("Backspace", this.remove_tool);

      this.show_pins = false;
      this.drag = null;
    },

    /**
     * Render the pins if the tool is selected or pins should be shown.
     * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
     */
    render_pins(ctx) {
      if (this.selected) this.floatingBarVisible = true;
      if (this.selected || this.show_pins) {
        this.pins.forEach((x) => x.draw(ctx));
        this.other.forEach((f) => f(ctx));
      }
    },

    /**
     * Set the state of the tool.
     * @param {string} name - The name of the state to set.
     */
    set_state(name) {
      this.$emit("change-settings", {
        $state: name,
      });
    },

    /**
     * Watcher for UUID changes to reinitialize pins and collisions.
     * @param {Object} n - The new properties object.
     * @param {Object} p - The previous properties object.
     */
    watch_uuid(n, p) {
      // If layer $uuid is changed, then re-init
      // pins & collisions
      if (n.$uuid !== p.$uuid) {
        for (var p of this.pins) p.re_init();
        this.collisions = [];
        this.show_pins = false;
        this.drag = null;
      }
    },
    pre_draw() {
      // Delete all collision functions before
      // the draw() call and let primitives set
      // them again
      this.collisions = [];
    },

    /**
     * Remove the tool if it is selected.
     */
    remove_tool() {
      // console.log("selected is ", this.selected);
      // if (this.selected)
      this.$emit("remove-tool");
    },

    /**
     * Start the drag operation and lock scrolling.
     */
    start_drag() {
      this.$emit("scroll-lock", true);
      let cursor = this.$props.cursor;
      this.drag = { t: cursor.t, y$: cursor.y$ };
      this.pins.forEach((x) => x.rec_position());
    },

    /**
     * Update the drag operation with the current cursor position.
     */
    drag_update() {
      let dt = this.$props.cursor.t - this.drag.t;
      let dy = this.$props.cursor.y$ - this.drag.y$;
      this.pins.forEach((x) => x.update_from([x.t1 + dt, x.y$1 + dy], true));
    },
  },
  computed: {
    /**
     * Computed property to check if the tool is selected.
     * @returns {boolean} - True if the tool is selected.
     */
    selected() {
      return this.$props.settings.$selected;
    },

    /**
     * Computed property to get the current state of the tool.
     * @returns {string} - The current state of the tool.
     */
    state() {
      return this.$props.settings.$state;
    },
  },
};
