<template>
  <div class="tfSelector">
    <select v-model="selected" :class="selectorClass" @change="onSelectChange">
      <!-- Regular options -->
      <option v-for="(tf, i) in displayedTimeframes" :key="i" :value="i">
        {{ tf.text }}
      </option>
    </select>
  </div>
</template>

<script>
import availableTF from "../../../data/timeframes.json";

export default {
  name: "TfSelector",
  data() {
    return {
      selected: localStorage.getItem("tf") || 6, // Default to 1 min alias ( 1 )
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },
  computed: {
    timeframes() {
      let assetType = this.$store.getters.getAssetType;
      console.log(assetType);
      return availableTF[assetType]; // this stores the detailed Timeframe name
    },
    selectorClass() {
      return this.isDark ? "select-dark" : "select";
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
    displayedTimeframes() {
      // Create a new array that includes the custom option if CustomTFMode is true
      let displayed = [...this.timeframes];
      return displayed;
    },
  },
  mounted() {
    this.initalSet();
  },
  methods: {
    onSelectChange() {
      if (!JSON.parse(localStorage.getItem("isLogin"))) {
        this.$store.commit("updateJoinUsPopup", true);
        return;
      }
      this.emitSelection();
    },
    initalSet() {
      if (localStorage.getItem("tf") != null) return;
      this.$store.commit("updateTimeFrame", 6);
    },
    emitSelection() {
      if (this.selected !== 0) {
        this.$store.commit("updateTimeFrame", this.selected);
        this.$store.commit("updateCustomTFMode", false);

        let storedVault = localStorage.getItem("customTFVault");
        storedVault = JSON.parse(storedVault);

        let CustomTFRegistry = {
          CustomTFMode: false,
          CustomTFSelected: storedVault.CustomTFSelected,
          CustomTFInterval: storedVault.CustomTFInterval,
        };
        localStorage.setItem("customTFVault", JSON.stringify(CustomTFRegistry));
      } else {
        this.openCustomTimeframePopup();
      }
    },
    openCustomTimeframePopup() {
      this.$store.commit("updateCustomTFSelectorOpen", true);
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
};
</script>
<style scoped>
.tfSelector {
  background: transparent;
  border-radius: 3px;
  border: none;
}

.select-dark {
  background: transparent;
  border: none;
  outline: none;
  color: #ccc;
}

.select {
  background: transparent;
  border: none;
  outline: none;
}

option {
  background: white;
}

.customBtn {
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 6px;
  font-size: small;
  color: black;
}

.plus_txt {
  font-size: larger;
}
</style>
