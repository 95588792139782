var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-overlay", on: { click: _vm.closeModal } },
    [
      _c(
        "div",
        {
          class: _vm.contentClass,
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("h2", [_vm._v("Chart Settings")]),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "close-button", on: { click: _vm.closeModal } },
              [_vm._v("×")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "main-div" }, [
            _c("div", { staticClass: "sidebar" }, [
              _c(
                "div",
                {
                  staticClass: "symbol",
                  on: {
                    click: function ($event) {
                      _vm.showMainContent = "symbol"
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 28 28",
                        width: "28",
                        height: "28",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("Symbol")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "main-content" },
              [
                _vm.showMainContent === "symbol"
                  ? _c("CandlesSetting", {
                      attrs: { closeModal: _vm.closeModal },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }