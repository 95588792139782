var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", { staticClass: "text" }, [
      _vm._v(_vm._s(_vm.user.fullName) + " "),
      _c("br"),
      _vm._v(" total video : " + _vm._s(_vm.user.videos.length)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "videosContainer" },
      _vm._l(_vm.videos, function (video, index) {
        return _c("div", { key: index, staticClass: "videoes" }, [
          _c(
            "button",
            {
              staticClass: "play",
              on: {
                click: function ($event) {
                  return _vm.playVideo(video)
                },
              },
            },
            [_c("i", { staticClass: "fas fa-play" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "options",
              on: {
                click: function ($event) {
                  return _vm.toggleOptions(index)
                },
              },
            },
            [_vm._m(0, true)]
          ),
          _vm._v(" "),
          _vm.openOptionsIndex === index
            ? _c("div", { staticClass: "options-menu" }, [
                _c("ul", [
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.downloadVideo(video)
                        },
                      },
                    },
                    [_vm._v("Download")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editVideo(video)
                        },
                      },
                    },
                    [_vm._v("Edit")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.copyLinkforVideo(video)
                        },
                      },
                    },
                    [_vm._v("Copy Link")]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.DeleteVideo(video)
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.editingIndex === index
            ? _c("div", { staticClass: "edit-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.editName,
                      expression: "editName",
                    },
                  ],
                  attrs: { placeholder: video.name },
                  domProps: { value: _vm.editName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.editName = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.saveEdit(index)
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.frames[index], alt: "Video Frame" } }),
          _vm._v(" "),
          _c("p", { staticClass: "names" }, [_vm._v(_vm._s(video.name))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fas fa-ellipsis-h" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }