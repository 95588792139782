<template>
  <div class="context-menu" :style="position">
    <div :class="contextMenuClass">
      <button @click="handleSettingsClick">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 28"
          width="28"
          height="28"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 14a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-1 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          ></path>
          <path
            fill-rule="evenodd"
            d="M8.5 5h11l5 9-5 9h-11l-5-9 5-9Zm-3.86 9L9.1 6h9.82l4.45 8-4.45 8H9.1l-4.45-8Z"
          ></path>
        </svg>
        <p :class="settingClass">Settings</p>
      </button>
      <!-- Add more menu items if needed -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ContextMenu",
  props: ["position"],
  data() {
    return {
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },

  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    contextMenuClass() {
      return this.isDark ? "context-menu-dark" : "context-menu";
    },
    settingClass() {
      return this.isDark ? "setting-dark" : "setting";
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
  methods: {
    handleSettingsClick() {
      this.$emit("settings-click");
    },
  },
};
</script>

<style>
.context-menu {
  width: 200px;
  height: 40px;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 200; /* Ensure it's above other content */
}
.context-menu-dark {
  width: 200px;
  height: 40px;
  position: absolute;
  background-color: #1e1e1e;
  border: 1px solid #1e1e1e;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 200; /* Ensure it's above other content */
}
.context-menu button {
  display: flex;
  gap: 10px;
  justify-content: start;
  margin-left: 4px;
  align-items: center;
  background: none;
  width: 200px;
  height: 40px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.context-menu-dark button {
  display: flex;
  gap: 10px;
  justify-content: start;
  margin-left: 4px;
  align-items: center;
  background: none;
  width: 200px;
  height: 40px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: white;
}
.setting {
  color: black;
}
.setting-dark {
  color: white;
}
</style>
