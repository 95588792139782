var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.floatingBarVisible
    ? _c(_vm.floatComponent, {
        tag: "component",
        attrs: {
          "set-color": _vm.setColor,
          "current-color": _vm.lineColor,
          "set-line-width": _vm.setLineWidth,
          "current-width": _vm.lineWidth,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }