var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.candleSettingClass }, [
    _c("h2", { class: _vm.titleClass }, [_vm._v("Candles")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "settings-container" },
      _vm._l(_vm.settings, function (setting, index) {
        return _c("div", { key: index, class: _vm.settingItemClass }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: setting.isChecked,
                expression: "setting.isChecked",
              },
            ],
            staticClass: "checkbox",
            attrs: { type: "checkbox", id: setting.name },
            domProps: {
              checked: Array.isArray(setting.isChecked)
                ? _vm._i(setting.isChecked, null) > -1
                : setting.isChecked,
            },
            on: {
              change: function ($event) {
                var $$a = setting.isChecked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(setting, "isChecked", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        setting,
                        "isChecked",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(setting, "isChecked", $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "setting-label", attrs: { for: setting.name } },
            [_vm._v(_vm._s(setting.name))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "color-pickers" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: setting.colorUp,
                  expression: "setting.colorUp",
                },
              ],
              staticClass: "color-picker",
              attrs: { type: "color", disabled: !setting.isChecked },
              domProps: { value: setting.colorUp },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(setting, "colorUp", $event.target.value)
                  },
                  function ($event) {
                    return _vm.updateColor(
                      setting.name,
                      "colorUp",
                      $event.target.value
                    )
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: setting.colorDown,
                  expression: "setting.colorDown",
                },
              ],
              staticClass: "color-picker",
              attrs: { type: "color", disabled: !setting.isChecked },
              domProps: { value: setting.colorDown },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(setting, "colorDown", $event.target.value)
                  },
                  function ($event) {
                    return _vm.updateColor(
                      setting.name,
                      "colorDown",
                      $event.target.value
                    )
                  },
                ],
              },
            }),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "button-container" }, [
      _c(
        "button",
        { staticClass: "save-button", on: { click: _vm.handleClick } },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }