<template>
  <div :class="candleSettingClass">
    <h2 :class="titleClass">Candles</h2>
    <div class="settings-container">
      <div
        v-for="(setting, index) in settings"
        :key="index"
        :class="settingItemClass"
      >
        <input
          type="checkbox"
          :id="setting.name"
          v-model="setting.isChecked"
          class="checkbox"
        />
        <label :for="setting.name" class="setting-label">{{
          setting.name
        }}</label>
        <div class="color-pickers">
          <input
            type="color"
            v-model="setting.colorUp"
            class="color-picker"
            :disabled="!setting.isChecked"
            @input="updateColor(setting.name, 'colorUp', $event.target.value)"
          />
          <input
            type="color"
            v-model="setting.colorDown"
            class="color-picker"
            :disabled="!setting.isChecked"
            @input="updateColor(setting.name, 'colorDown', $event.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="button-container">
      <button @click="handleClick" class="save-button">Save</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CandlesSetting",
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      settings: [
        {
          name: "Candle",
          isChecked: false,
          colorUp: "#00FF00",
          colorDown: "#FF0000",
        },
        {
          name: "Wick",
          isChecked: false,
          colorUp: "#0000FF",
          colorDown: "#FF00FF",
        },
      ],
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,
    };
  },

  computed: {
    getTheme() {
      return this.$store.getters.getTheme;
    },
    candleSettingClass() {
      return this.isDark ? "candle-settings-dark" : "candle-settings";
    },
    titleClass() {
      return this.isDark ? "title-dark" : "title";
    },
    settingItemClass() {
      return this.isDark ? "setting-item-dark" : "setting-item";
    },
  },
  watch: {
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
  methods: {
    handleClick() {
      this.handleChangeCandle();
      this.handleChangeWick();
      this.$props.closeModal();
    },

    updateColor(settingName, colorType, colorValue) {
      const setting = this.settings.find((s) => s.name === settingName);
      if (setting) {
        setting[colorType] = colorValue;
        this.$bus.$emit(`${settingName.toLowerCase()}colors`, setting);
      }
    },

    handleChangeWick() {
      console.log(this.settings[1]);
      this.$bus.$emit("wickcolors", this.settings[1]);
    },
    handleChangeCandle() {
      this.$bus.$emit("candlecolors", this.settings[0]);
    },
  },
};
</script>

<style scoped>
.candles-settings {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  height: 400px;
  margin: auto;
  position: relative; /* Required for absolute positioning of button */
}
.candles-settings-dark {
  padding: 15px;
  background-color: #1e1e1e;
  color: #f9f9f9;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  height: 400px;
  margin: auto;
  position: relative; /* Required for absolute positioning of button */
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}
.title-dark {
  font-size: 20px;
  font-weight: bold;
  color: #f9f9f9;
  margin-bottom: 15px;
  text-align: center;
}
.settings-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.setting-item {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.setting-item-dark {
  display: flex;
  align-items: center;
  background: #1e1e1e;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px white;
}

.checkbox {
  margin-right: 8px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.setting-label {
  flex: 1;
  font-size: 14px;
  color: #555;
}

.color-pickers {
  display: flex;
  gap: 8px;
}

.color-picker {
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
}

.button-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.save-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #0056b3;
}
</style>
