var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageClass }, [
    _c("div", { staticClass: "mainComponent" }, [
      _c("p", { staticClass: "companyName" }, [_vm._v("Complete Signup")]),
      _vm._v(" "),
      _c("p", { staticClass: "register" }, [_vm._v(_vm._s(_vm.email))]),
      _vm._v(" "),
      _c("div", { class: _vm.formFrameClass }, [
        _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "nameSection" }, [
            _c("div", { staticClass: "name" }, [
              _c("p", { class: _vm.nameTextClass }, [
                _vm._v("First Name"),
                _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.firstName,
                    expression: "firstName",
                  },
                ],
                staticClass: "nameInput",
                attrs: { type: "text", placeholder: "Enter your first name" },
                domProps: { value: _vm.firstName },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.firstName = $event.target.value
                    },
                    _vm.updateFirstName,
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [
              _c("p", { class: _vm.nameTextClass }, [
                _vm._v("Last Name"),
                _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.lastName,
                    expression: "lastName",
                  },
                ],
                staticClass: "nameInput",
                attrs: { type: "text", placeholder: "Enter your last name" },
                domProps: { value: _vm.lastName },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.lastName = $event.target.value
                    },
                    _vm.updateLastName,
                  ],
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "userInfo" }, [
            _c("p", { class: _vm.usernameClass }, [
              _vm._v("A Username that you would like to known with"),
              _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              staticClass: "usernameInput",
              attrs: { type: "text", placeholder: "Enter your username" },
              domProps: { value: _vm.username },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.username = $event.target.value
                  },
                  _vm.updateUsername,
                ],
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "application",
              attrs: { disabled: !_vm.canSignup },
              on: { click: _vm.signup },
            },
            [_vm._v("Complete application ▶")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }