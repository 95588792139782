<script>
// The Fibonacci tool for drawing Fibonacci retracement levels on a chart

import Overlay from "../../mixins/overlay.js";
import Tool from "../../mixins/tool.js";
import Icons from "../../stuff/icons.json";
import Pin from "../primitives/pin.js";
import Seg from "../primitives/seg.js";
import Data from "../../../data/data.json";
export default {
  name: "RulerTool", // Name of the component
  mixins: [Overlay, Tool], // Mixin dependencies
  // props: ["newIsLocked"],
  // watch: {
  //   newIsLocked() {
  //     this.isLocked = this.newIsLocked;
  //     this.isLockTool(this.isLocked);
  //   },
  // },
  methods: {
    // Meta information about the tool
    meta_info() {
      return { author: "chandan-atiuttam", version: "2.0.1" };
    },
    // Tool descriptor
    tool() {
      return {
        // Group under which the tool will be categorized
        group: "Ruler",
        // Icon representing the tool
        icon: Icons["ruler.svg"],
        // Type of the tool
        type: "Retracement",
        // Hint for the tool
        hint: "Ruler Retracement",
        // Default data for the tool
        data: [],
        // Default settings for the tool
        settings: {
          lineColor: "#000000",
          backgroundColors: ["#F5051D80", "#F5051D80"],
        },
        // Modifications for different modes of the tool
        mods: {
          ShiftMode: {
            settings: {
              price: false,
              time: true,
              shiftMode: true,
            },
            hidden: true,
          },
        },
      };
    },
    // Called after the overlay is mounted
    init() {
      // this.isLocked = this.newIsLocked;
      // this.isLockTool(this.isLocked);
      this.init_tool(); // Initialize the tool with mixin functionalities

      // First pin is placed at the mouse position
      this.pins.push(
        new Pin(this, "p1", {
          hidden: this.shift,
        })
      );
      // Second pin follows the mouse until it clicks
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
          hidden: this.shift,
        })
      );

      this.pins[1].on("settled", () => {
        // Finish drawing the tool
        this.set_state("finished");
        this.$emit("drawing-mode-off");
        // Deselect the tool in shiftMode
        if (this.shift)
          this._$emit("custom-event", {
            event: "object-selected",
            args: [],
          });

        // Remove the drawing
        this.remove_tool();
      });
    },
    // Draw method for rendering the Fibonacci retracement levels
    draw(ctx) {
      if (!this.p1 || !this.p2) return;

      const layout = this.$props.layout;
      const xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5);

      ctx.lineWidth = this.line_width;
      ctx.strokeStyle = this.line_color;

      let x1 = layout.t2screen(this.p1[0]);
      let y1 = layout.$2screen(this.p1[1]);
      let x2 = layout.t2screen(this.p2[0]);
      let y2 = layout.$2screen(this.p2[1]);

      let priceRange = Math.abs(this.p2[1] - this.p1[1]);
      let fibLevels = [0, 1];
      let fibPercentages = Math.abs((this.p2[1] - this.p1[1]) / 100);
      let bars = this.p2[0] - this.p1[0];
      // Update textColors and backgroundColors based on the position of pin2 relative to pin1
      let textColors =
        this.p2[1] < this.p1[1]
          ? ["#FF0000", "#FF0000"]
          : ["#0000FF", "#0000FF"];
      let backgroundColors =
        this.p2[1] < this.p1[1]
          ? ["#FF000080", "#FF000080"]
          : ["#0000FF80", "#0000FF80"];

      let previousY = y1;
      fibLevels.forEach((level, index) => {
        // this will get the actual price from the price / side bar
        let fibPrice =
          this.p1[1] + level * priceRange * (this.p2[1] > this.p1[1] ? 1 : -1);
        let y = layout.$2screen(fibPrice);

        // Fill the background color between the previousY and the current y
        if (index < backgroundColors.length) {
          this.horizontal(ctx, x1, previousY, x2, y, backgroundColors[index]);
        }

        ctx.beginPath();
        ctx.moveTo(x1, y);
        ctx.lineTo(x2, y);
        ctx.stroke();

        previousY = y;
      });

      // Draw a single box with the price values
      this.drawPriceBox(
        ctx,
        x1,
        y1,
        x2,
        y2,
        fibPercentages,
        priceRange,
        bars,
        textColors[0]
      );

      // Render pins
      this.render_pins(ctx);
    },

    // Draw horizontal lines and arrows
    horizontal(ctx, x1, yTop, x2, yBottom, backgroundColor) {
      const layout = this.$props.layout;
      const xdir = Math.sign(this.p2[0] - this.p1[0]);
      const ym =
        (layout.$2screen(this.p1[1]) + layout.$2screen(this.p2[1])) / 2;

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(x1, yTop, x2 - x1, yBottom - yTop);

      ctx.beginPath();

      ctx.moveTo(x2 - 5 * xdir, ym - 4);
      ctx.lineTo(x2, ym);
      ctx.lineTo(x2 - 5 * xdir, ym + 4);
    },

    // Draw a single box with the combined price values
    drawPriceBox(ctx, x1, y1, x2, y2, percentage, priceRange, bars, textColor) {
      if (!this.p1 || !this.p2) return;

      const layout = this.$props.layout;
      const price1 = this.p1[1];
      const price2 = this.p2[1];

      const boxX = Math.min(x1, x2) - 75; // Position box slightly left of the midpoint
      const boxY = (y1 + y2) / 2 - 12; // Center the box vertically between y1 and y2

      ctx.font = "12px Arial";
      ctx.fillStyle = textColor;
      ctx.textAlign = "left";
      ctx.fillRect(boxX, boxY, 200, 50); // Background box for the text
      ctx.fillStyle = "#FFFFFF"; // Text color inside the box
      ctx.fillText(
        `Bars:${bars} Dist:${bars} ${priceRange.toFixed(
          2
        )} (${percentage.toFixed(2)}%)`,
        boxX + 5,
        boxY + 16
      );
    },

    // Define which tools this component should be used for
    use_for() {
      return ["RulerTool"];
    },

    // Define the colors for the data
    data_colors() {
      return [this.color];
    },
  },
  // Define internal settings and constants
  computed: {
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    line_color() {
      return this.sett.lineColor || "#000000"; // Default line color
    },
    color() {
      return this.sett.color || this.$props.colors.cross;
    },
    back_color() {
      return this.sett.backColor || "#9b9ba316";
    },
    value_back() {
      return this.sett.valueBack || "#9b9ba316";
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text;
    },
    prec() {
      return this.sett.precision || 2;
    },
    new_font() {
      return "12px " + this.$props.font.split("px").pop();
    },
    price() {
      return "price" in this.sett ? this.sett.price : true;
    },
    time() {
      return "time" in this.sett ? this.sett.time : false;
    },
    shift() {
      return this.sett.shiftMode;
    },
  },
  data() {
    return {};
  },
};
</script>
