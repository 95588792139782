const events = new Map();

export default {
  events,
  $on(eventName, fn) {
    if (!events.has(eventName)) {
      events.set(eventName, []);
    }

    if (!events.get(eventName).includes(fn)) events.get(eventName).push(fn);
  },
  $off(eventName, fn) {
    throw {
      message: "Not implemented",
    };
  },
  $emit(eventName, data) {
    if (events.has(eventName)) {
      events.get(eventName).forEach((fn) => {
        fn(data);
      });
    }
  },
};
