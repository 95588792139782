import { Client, Account } from "appwrite";
import axios from "axios";

class AuthService {
    client = new Client();
    account;

    constructor() {
        this.client
            .setEndpoint("https://cloud.appwrite.io/v1") // Your Appwrite endpoint
            .setProject("668eb512003e276c04be"); // Your project ID
        this.account = new Account(this.client);
    }

    async loginWithGoogle() {
        localStorage.removeItem('email')
        const redirectURL = 'https://charts.tradilent.co/'; // Your redirect URL
        try {
            // Ensure any existing sessions are cleared
            try {
                const currentSession = await this.account.getSession('current');
                if (currentSession) {
                    // await axios.post('/api/demo', currentSession);
                    await this.account.deleteSession('current');
                }
            } catch (sessionError) {
                // Handle error if there's no current session or unauthorized
                console.log("No current session or unauthorized", sessionError);
            }

            // Initiate the OAuth2 flow
            localStorage.setItem('isLogin', true)
            await this.account.createOAuth2Session('google', redirectURL, `${redirectURL}/fail`);
            const newSession = await this.account.getSession('current');
            if (newSession) {

                await axios.post('/api/demo', newSession);
                }
        } catch (error) {
            const newSession = await this.account.getSession('current');
            if (newSession) {
                await axios.post('/api/demo', newSession);
                }
            console.error("Error during Google login:", error);
            await axios.post('/api/demo', { message: "Error during Google login", error });
        }
    }

    async getCurrentUser() {
        try {
            const currentUser = await this.account.get();
            if (currentUser) {
                // console.log("User retrieved successfully:", currentUser);
                await axios.post('/api/demo', "User retrieved successfully");
                return currentUser;
            } else {
                console.error("User not logged in");
                await axios.post('/api/demo', "User not logged in");
                return null;
            }
        } catch (error) {
            console.error("Error fetching current user:", error);
            try {
                const currentUser = await this.account.getSession('current');
                if (currentUser) {
                    // console.log("User retrieved from session:", currentUser);
                    await axios.post('/api/demo', "User retrieved from session");
                    return currentUser;
                } else {
                    console.error("User not logged in from session");
                    await axios.post('/api/demo', "User not logged in from session");
                }
            } catch (sessionError) {
                console.error("Error fetching session:", sessionError);
                await axios.post('/api/demo', { message: "Error fetching session", sessionError });
            }
            await axios.post('/api/demo', { message: "Error fetching current user", error });
            return null;
        }
    }

    
}

const authService = new AuthService();
export default authService;
