<template>
  <!-- if showbar is true  -->
  <div v-if="showBar" :class="barClass" :style="{ width: width + 'px' }">
    <div class="resizeHandle" @mousedown="onMouseDown"></div>
    <div class="toolBar">
      <div class="toolbarItem" @click="handleInfoBar" title="Info">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44" height="44">
          <path fill="currentColor"
            d="M13 11h18a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13c0-1.1.9-2 2-2Zm18-1H13a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Zm-2 11H15v1h14v-1Zm-14-5h14v1H15v-1Zm14 10H15v1h14v-1Z">
          </path>
        </svg>
      </div>
      <div class="toolbarItem" @click="getScreenShot" title="Screenshot">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
          <g fill="none" fill-rule="evenodd" stroke="currentColor">
            <path d="M13.111 18.5H10.5a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-8.389z">
            </path>
            <path d="M18.5 20v1.5a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1H8"></path>
          </g>
        </svg>
      </div>
      <div class="toolbarItem" @click="handleDownload" title="Download">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
          <path stroke="currentColor" d="M6.5 16v4.5a1 1 0 001 1h14a1 1 0 001-1V16M14.5 5V17m-4-3.5l4 4l4-4"></path>
        </svg>
      </div>
      <div class="toolbarItem" @click="copyLink" title="Copy Link">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none">
          <path stroke="currentColor"
            d="M19 15l2.5-2.5c1-1 1.5-3.5-.5-5.5s-4.5-1.5-5.5-.5L13 9M10 12l-2.5 2.5c-1 1-1.5 3.5.5 5.5s4.5 1.5 5.5.5L16 18M17 11l-5 5">
          </path>
        </svg>
      </div>
      <div class="toolbarItem" title="screen recorder" @click="openRecOptions">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
          <path
            d="M18.8903 37.7806C8.45747 37.7806 0 29.323 0 18.8903C0 8.45747 8.45747 0 18.8903 0C29.323 0 37.7806 8.45747 37.7806 18.8903C37.7806 29.323 29.323 37.7806 18.8903 37.7806ZM18.8903 34.0026C27.2366 34.0026 34.0026 27.2366 34.0026 18.8903C34.0026 10.544 27.2366 3.77806 18.8903 3.77806C10.544 3.77806 3.77806 10.544 3.77806 18.8903C3.77806 27.2366 10.544 34.0026 18.8903 34.0026ZM18.8903 28.3355C13.6739 28.3355 9.44515 24.1067 9.44515 18.8903C9.44515 13.6739 13.6739 9.44515 18.8903 9.44515C24.1067 9.44515 28.3355 13.6739 28.3355 18.8903C28.3355 24.1067 24.1067 28.3355 18.8903 28.3355Z"
            fill="#CCCCCC" />
        </svg>
        <p>rec</p>
        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="15" height="4" viewBox="0 0 10 8">
          <path d="M0.780273 0.365234L4.57409 3.41383L8.90987 0.365234" stroke="black" stroke-opacity="0.76"
            stroke-width="0.5081" />
        </svg>
      </div>
      <div class="toolbarItem2" v-if="openRec">
        <ScreenRecorder/>
      </div>
      <FeedBack title="Feedback"></FeedBack>
    </div>
    <watchlist />
  </div>
  <!-- if showbar is false -->
  <div v-else class="noRightToolBar"></div>
</template>

<script>
import Data from "../../data/data.json";
import FeedBack from "./FeedBack.vue";
import Watchlist from "./Watchlist.vue";
import axios from "axios";
import html2canvas from "html2canvas";
import { SERVER_URL } from "../constant.js";
import ScreenRecorder from "./ScreenRecorder.vue";
export default {
  name: "RightToolBar",
  components: { FeedBack, Watchlist, ScreenRecorder },
  props: ["tradilentVueRef"],
  data() {
    return {
      width: 0,
      isResizing: false,
      startX: 0,
      startWidth: 0,
      isInfoOpen: false,
      showBar:
        JSON.parse(localStorage.getItem("showRightBar")) || this.getShowBar,

      // For bottom bar
      bottomBarHeight: 0,
      isResizingBottombar: false,
      startY: 0,
      startHeight: 0,
      bottomBarInfo: null,

      // For watchlist
      selectedSymbol: "AAPL",
      watchlist: Data.Stocks,

      // For categories
      isFuturesOpen: false,
      futures: Data.futures,
      isForexOpen: false,
      forex: Data.forex,
      isCryptoOpen: false,
      crypto: Data.crypto,

      //for theme
      isDark: JSON.parse(localStorage.getItem("themeIsDark")) || this.getTheme,

      //for recording
      openRec: false
    };
  },
  computed: {
    getWidth() {
      return this.$store.getters.rightToolBarWidth;
    },
    getIsInfoOpen() {
      return this.$store.getters.isInfoOpen;
    },
    getBottomBarHeight() {
      return this.$store.getters.getBottomBarHeight;
    },
    getShowBar() {
      return this.$store.getters.getShowBar;
    },
    getTheme() {
      return this.$store.getters.getTheme;
    },
    barClass() {
      return this.isDark ? "bar-dark" : "bar";
    },
    headerBtnClass() {
      return this.isDark ? "headerBtn-dark" : "headerBtn";
    },
    bottomBarClass() {
      return this.isDark ? "bottomBar-dark" : "bottomBar";
    },
  },
  methods: {
    openRecOptions(){
      this.openRec = !this.openRec
    },
    onMouseDown(event) {
      this.isResizing = true;
      this.startX = event.clientX;
      this.startWidth = this.width;
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
      event.preventDefault(); // Prevent text selection
    },
    onMouseMove(event) {
      if (this.isResizing) {
        const delta = this.startX - event.clientX;
        this.width = this.startWidth + delta;
        if (this.width < 56) {
          // Minimum width
          this.width = 56;
        } else if (this.width < 256) {
          this.$store.commit("toggleInfoOpen", false);
          this.width = 56;
        } else if (this.width > 256 && this.width < window.innerWidth * 0.6) {
          this.$store.commit("toggleInfoOpen", true);
        } else if (this.width > window.innerWidth * 0.6) {
          // Maximum width is 60% of innerWidth
          this.width = window.innerWidth * 0.6;
        }
        this.$store.commit("updateRightToolBarWidth", this.width);
      }
    },
    onMouseUp() {
      if (this.isResizing) {
        this.isResizing = false;
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
      }
    },
    onMouseDownBottomBar(event) {
      this.isResizingBottombar = true;
      this.startY = event.clientY;
      this.startHeight = this.bottomBarHeight;
      document.addEventListener("mousemove", this.onMouseMoveBottomBar);
      document.addEventListener("mouseup", this.onMouseUpBottomBar);
      event.preventDefault(); // Prevent text selection
    },
    onMouseMoveBottomBar(event) {
      if (this.isResizingBottombar) {
        const delta = this.startY - event.clientY;
        this.bottomBarHeight = this.startHeight + delta;
        if (this.bottomBarHeight < 35) {
          // Minimum height
          this.bottomBarHeight = 35;
        }
        this.$store.commit("updateBottomHeight", this.bottomBarHeight);
      }
    },
    onMouseUpBottomBar() {
      if (this.isResizingBottombar) {
        this.isResizingBottombar = false;
        document.removeEventListener("mousemove", this.onMouseMoveBottomBar);
        document.removeEventListener("mouseup", this.onMouseUpBottomBar);
      }
    },
    handleInfoBar() {
      if (this.$store.getters.isInfoOpen) {
        this.$store.commit("toggleInfoOpen", false);
        this.width = 56;
        this.$store.commit("updateRightToolBarWidth", this.width);
      } else {
        this.$store.commit("toggleInfoOpen", true);
        this.width = 356;
        this.$store.commit("updateRightToolBarWidth", this.width);
      }
    },
    selectSymbol(symbol) {
      this.selectedSymbol = symbol;
      this.InfoFromSymbol(symbol);
    },
    ToggleFutures() {
      this.isFuturesOpen = !this.isFuturesOpen;
    },
    ToggleForex() {
      this.isForexOpen = !this.isForexOpen;
    },
    ToggleCrypto() {
      this.isCryptoOpen = !this.isCryptoOpen;
    },
    InfoFromSymbol(name) {
      Data.watchlist.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      });
      Data.futures.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      });
      Data.forex.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      });
      Data.crypto.map((list) => {
        if (list.symbol == name) {
          this.bottomBarInfo = list;
        }
      });
      console.log(this.bottomBarInfo);
    },
    async getScreenShot() {
      await this.$bus.$emit("removeScreenShotComponent");
      console.log(this.tradilentVueRef);

      if (this.tradilentVueRef) {
        const canvas = await html2canvas(this.tradilentVueRef);
        console.log(canvas);
        canvas.toBlob((blob) => {
          navigator.clipboard
            .write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ])
            .then(() => {
              console.log("Image copied to clipboard");
            })
            .catch((error) => {
              console.error("Error copying image to clipboard:", error);
            });
        });
      } else {
        console.error("tradilentVueRef  is not set");
      }
    },
    async captureScreen() {
      await this.$bus.$emit("removeScreenShotComponent");
      if (this.tradilentVueRef) {
        const canvas = await html2canvas(this.tradilentVueRef);
        this.capturedImage = canvas.toDataURL("image/png");
        console.log(this.capturedImage);
        return this.capturedImage;
      } else {
        console.error("tradilentVueRef is not set");
      }
    },
    async handleDownload() {
      if (this.capturedImage) {
        this.downloadImage();
      } else {
        await this.captureScreen();
        this.downloadImage();
      }
    },
    downloadImage() {
      const link = document.createElement("a");
      link.href = this.capturedImage;
      link.download = "captured-image.png";
      link.click();
      console.log("Image downloaded successfully");
    },
    async copyLink() {
      if (!this.capturedImage) {
        await this.captureScreen();
      }
      const url = `${SERVER_URL}/api/screenshot`;
      const data = {
        base64Data: this.capturedImage,
      };
      await axios
        .post(url, data)
        .then((res) => {
          navigator.clipboard.writeText(
            `${document.location.href}${res.data.data.data._id}`
          );
          this.$toast("Link Copied", {
            toastClassName: "my-custom-toast-class",
            bodyClassName: ["custom-class-1", "custom-class-2"],
          });
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
  },
  mounted() {
    this.width = this.getWidth;
    this.bottomBarHeight = this.getBottomBarHeight;
  },
  watch: {
    getWidth(newWidth) {
      this.width = newWidth;
    },
    getBottomBarHeight(newHeight) {
      this.bottomBarHeight = newHeight;
    },
    getShowBar(bool) {
      this.showBar = bool;
    },
    getTheme(newTheme) {
      this.isDark = newTheme;
    },
  },
};
</script>

<style scoped>
.watchlist {
  width: calc(100% - 56px);
  margin-right: 56px;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 15px;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
}

.title:hover {
  cursor: pointer;
  background-color: #76878319;
}

.header-icons {
  display: flex;
  gap: 10px;
}

.icon {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tdSym {
  display: flex;
  align-items: center;
  gap: 2px;
}

.tdImg {
  background-color: #e0e3eb;
  border-radius: 100%;
  padding: 5px;
  font-weight: bold;
  color: white;
}

tbody {
  width: 100%;
}

th {
  font-weight: bold;
  color: #888;
}

tr:hover {
  background-color: #f5f5f5;
}

.selected {
  border-left: 4px solid blue;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

.categories {
  display: flex;
  flex-direction: column;
}

.category {
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.category:hover {
  color: black;
}

.bar {
  border-top: 1px solid rgba(55, 50, 50, 0.273);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-top: 42px;
  display: flex;
  background-color: white;
  z-index: 1001;
}

.bar-dark {
  border-top: 1px solid rgba(55, 50, 50, 0.273);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin-top: 42px;
  display: flex;
  background-color: #1e1e1e;
  z-index: 1001;
}

.toolBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid rgba(55, 50, 50, 0.273);
  z-index: 10;
}

.toolbarItem {
  margin-top: 15px;
  width: 45px;
  height: 45px;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toolbarItem2 {
  margin-top: 15px;
  width: 45px;
  height: 45px;
  border-radius: 15%;
  /* background-color: rgba(0, 0, 0, 0.3); */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}


.toolbarItem:hover {
  background-color: #76878319;
  cursor: pointer;
}

.resizeHandle {
  position: absolute;
  top: 0;
  left: -1px;
  width: 3px;
  height: 100%;
  cursor: ew-resize;
  z-index: 12;
  background-color: #ccc;
}

.infoBar {
  height: calc(100% - 70px);
  overflow-y: scroll;
  width: 200px;
  margin-top: 10px;
}

.header {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerBtn {
  padding: 1px 10px;
  border-radius: 10%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerBtn-dark {
  padding: 1px 10px;
  border-radius: 10%;
  border: none;
  background-color: transparent;
  outline: none;
  height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}

.bottomBarHeaderRight {
  display: flex;
  align-items: center;
}

.headerBtn:hover {
  background-color: #76878319;
}

.headerBtn-dark:hover {
  background-color: #76878319;
}

.toggleBtn {
  height: 10px;
  width: 10px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.leftImg {
  height: 30%;
}

.leftImg:hover {
  height: 30%;
  animation: downArrow 1s ease-in-out forwards;
}

.bottomBar {
  position: absolute;
  bottom: 35px;
  height: 35px;
  width: calc(100% - 56px);
  background-color: #fff;
  margin-right: 56px;
}

.bottomBar-dark {
  position: absolute;
  bottom: 35px;
  height: 35px;
  width: calc(100% - 56px);
  background-color: #1e1e1e;
  margin-right: 56px;
}

.resizeHandleBottom {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 3px;
  cursor: ns-resize;
  z-index: 5;
  background-color: #ccc;
}

.bottomBarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.noRightToolBar {
  display: none;
}

/* Existing styles */

/* Tooltip styles */
.toolbarItem {
  position: relative;
  margin-top: 15px;
  width: 45px;
  height: 45px;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbarItem:hover {
  background-color: #76878319;
  cursor: pointer;
}

/* Tooltip text */
.toolbarItem::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}

.toolbarItem:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Adjust tooltip position */
.toolbarItem::after {
  bottom: 110%;
  left: 50%;
}

@keyframes downArrow {
  0% {
    padding-top: 0px;
  }

  50% {
    padding-top: 5px;
  }

  100% {
    padding-top: 10px;
  }
}
</style>
