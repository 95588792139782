var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "alert-page",
    },
    [
      _c("div", { staticClass: "alert-dialog-box" }, [
        _c("div", { staticClass: "header" }, [
          _c("span", [
            _vm._v(
              "Create alert on " + _vm._s(_vm.$store.getters.getSelectedSymbol)
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "cross", on: { click: _vm.initialState } },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 18 18",
                    width: "18",
                    height: "18",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      stroke: "currentColor",
                      "stroke-width": "1.2",
                      d: "m1.5 1.5 15 15m0-15-15 15",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "top" }, [
              _c("div", { staticClass: "legend" }, [_vm._v("Condition")]),
              _vm._v(" "),
              _c("div", { staticClass: "fields" }, [
                _c("div", { staticClass: "alert-item" }, [
                  _c("div", { staticClass: "design bg-dark" }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$store.getters.getSelectedSymbol)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "alert-item" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.status,
                          expression: "status",
                        },
                      ],
                      attrs: { name: "status" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.status = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "Crossing" } }, [
                        _vm._v("Crossing"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Crossing Up" } }, [
                        _vm._v("Crossing Up"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Crossing Down" } }, [
                        _vm._v("Crossing Down"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Greater Than" } }, [
                        _vm._v("Greater Than"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Less Than" } }, [
                        _vm._v("Less Than"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "alert-item" }, [
                  _c("div", { staticClass: "price-box" }, [
                    _c("div", { staticClass: "price-box-item bg-dark" }, [
                      _vm._v("Price"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-box-item" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.price,
                            expression: "price",
                          },
                        ],
                        staticClass: "price",
                        attrs: { type: "number", min: "0" },
                        domProps: { value: _vm.price },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.price = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "middle" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "legend" }, [_vm._v("Trigger")]),
                _vm._v(" "),
                _c("div", { staticClass: "fields" }, [
                  _c("div", { staticClass: "alert-item" }, [
                    _c(
                      "span",
                      {
                        staticClass: "m1",
                        class: {
                          "bg-dark": _vm.trigger === "once",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTrigger("once")
                          },
                        },
                      },
                      [_vm._v("Only Once")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "m1",
                        class: {
                          "bg-dark": _vm.trigger === "every time",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTrigger("every time")
                          },
                        },
                      },
                      [_vm._v("Every Time")]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "legend" }, [_vm._v("Alert")]),
                _vm._v(" "),
                _c("div", { staticClass: "fields" }, [
                  _c("div", { staticClass: "alert alert-item" }, [
                    _c("input", {
                      attrs: { type: "text", "v-model": _vm.alertTitle },
                      domProps: { value: _vm.alertTitle },
                      on: { change: _vm.meth },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "legend" }, [_vm._v("Message")]),
                _vm._v(" "),
                _c("div", { staticClass: "fields" }, [
                  _c("div", { staticClass: "alert alert-item" }, [
                    _c("textarea", {
                      attrs: { "v-model": _vm.customAlertMessage },
                      domProps: { value: _vm.alertMessage },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "btn" }, [
            _c(
              "button",
              { staticClass: "btn1", on: { click: _vm.initialState } },
              [_vm._v("Cancel")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn" }, [
            _c(
              "button",
              { staticClass: "btn2", on: { click: _vm.createAlert } },
              [_vm._v("Create")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("toast"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tabs" }, [
      _c("span", { staticClass: "tab-item active-tab" }, [
        _vm._v(" Settings "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "tab-item" }, [_vm._v(" Notifications ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row cal-row" }, [
      _c("div", { staticClass: "legend" }, [_vm._v("Expiration")]),
      _vm._v(" "),
      _c("div", { staticClass: "fields" }, [
        _c("div", { staticClass: "alert-item" }, [_vm._v("Calender")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }